<template>
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_525_45952)">
<path d="M19.9942 10.3948C19.9817 10.1946 19.8547 10.0182 19.6662 9.94419L13.6876 7.59775C13.5653 7.54977 13.4294 7.54977 13.3071 7.59775L7.3285 9.94419C7.12992 10.0221 6.99927 10.2137 6.99927 10.4271C6.99927 10.6404 7.12988 10.832 7.3285 10.9099L13.3078 13.2567C13.3687 13.2805 13.433 13.2925 13.4973 13.2925C13.5616 13.2925 13.6259 13.2805 13.6868 13.2567L18.8239 11.2405C18.8252 11.24 18.8266 11.2409 18.8266 11.2423V13.3943C18.8266 13.7112 19.0701 13.9848 19.3866 13.9987C19.7227 14.0135 19.9998 13.7455 19.9998 13.4127V10.465C19.9998 10.4378 19.9977 10.4147 19.9942 10.3948V10.3948Z" fill="#595959"/>
<path d="M14.1155 14.3486C13.9175 14.4262 13.7096 14.4655 13.4973 14.4655C13.285 14.4655 13.0772 14.4262 12.8794 14.3487L9.40919 12.9867C9.4079 12.9862 9.40649 12.9872 9.40649 12.9886V15.5432C9.40649 16.5991 10.2624 17.4549 11.3182 17.4549H15.6803C16.7361 17.4549 17.592 16.599 17.592 15.5432V12.9871C17.592 12.9857 17.5906 12.9847 17.5893 12.9852L14.1155 14.3486Z" fill="#595959"/>
<path d="M9.83518 5.48302C9.83542 5.48419 9.83663 5.48494 9.83776 5.48455C10.7138 5.18512 11.5283 4.71468 12.237 4.09161C12.238 4.09079 12.2379 4.08931 12.237 4.08852C11.2664 3.31076 10.1006 2.76731 8.8256 2.54496C8.82388 2.54464 8.82262 2.54648 8.82356 2.54793C9.11451 2.99013 9.36819 3.60346 9.58287 4.38604C9.67681 4.72825 9.76096 5.09549 9.83518 5.48302V5.48302Z" fill="#595959"/>
<path d="M5.21332 5.48264C5.21446 5.48303 5.21571 5.48229 5.2159 5.48111C5.29001 5.09436 5.37404 4.72783 5.46778 4.38628C5.68247 3.60367 5.93614 2.99038 6.22709 2.54817C6.22803 2.54673 6.22677 2.54489 6.22505 2.5452C4.94862 2.76783 3.78148 3.31225 2.8103 4.0915C2.81773 4.09737 2.82536 4.10273 2.83255 4.10902C3.53539 4.72184 4.34518 5.18552 5.21332 5.48264V5.48264Z" fill="#595959"/>
<path d="M7.52552 5.86758C7.92037 5.86758 8.31103 5.83524 8.69438 5.77209C8.69548 5.77189 8.69622 5.77084 8.69602 5.76974C8.62603 5.39632 8.54633 5.04348 8.45717 4.71605C8.04496 3.20228 7.59763 2.87196 7.52576 2.86914C7.52556 2.86914 7.52544 2.86914 7.52529 2.86914C7.45345 2.87192 7.00609 3.20228 6.59388 4.71605C6.50479 5.04312 6.42518 5.39562 6.35526 5.76861C6.35506 5.7697 6.3558 5.7708 6.3569 5.77095C6.74095 5.8347 7.13169 5.86758 7.52552 5.86758V5.86758Z" fill="#595959"/>
<path d="M8.98079 8.03413C8.95318 7.65688 8.91791 7.28874 8.8745 6.93347C8.87438 6.93238 8.87333 6.93159 8.87223 6.93179C8.4305 7.00374 7.98047 7.04078 7.52568 7.04078C7.07198 7.04078 6.62184 7.00331 6.17928 6.93069C6.17819 6.9305 6.17713 6.93128 6.17701 6.93238C6.08242 7.70666 6.0245 8.53972 6.00659 9.4025C6.00655 9.4036 6.00745 9.40454 6.00859 9.40454H6.16743C6.16802 9.40454 6.16861 9.40426 6.16896 9.40376C6.35248 9.16204 6.60225 8.96914 6.90042 8.85214L8.9795 8.03616C8.98036 8.03581 8.98087 8.03503 8.98079 8.03413V8.03413Z" fill="#595959"/>
<path d="M8.23181 14.1284C7.99788 14.105 7.76215 14.0928 7.52541 14.0928C7.13158 14.0928 6.74091 14.1257 6.3569 14.1894C6.3558 14.1896 6.35506 14.1907 6.35526 14.1918C6.42518 14.5646 6.50476 14.9169 6.5938 15.2438C7.00609 16.7579 7.45353 17.088 7.52525 17.0907H7.52564C7.57683 17.0888 7.8194 16.9197 8.10394 16.2728C8.19458 16.0668 8.24022 15.843 8.23455 15.618C8.23392 15.5938 8.23365 14.1304 8.23365 14.1304C8.23361 14.1293 8.23283 14.1285 8.23181 14.1284V14.1284Z" fill="#595959"/>
<path d="M6.0329 11.2408C6.03188 11.2389 6.02918 11.2397 6.0293 11.2418C6.05793 11.8611 6.10743 12.4604 6.17681 13.0281C6.17692 13.0291 6.17798 13.0299 6.17907 13.0297C6.62163 12.9571 7.07174 12.9197 7.5254 12.9197C7.76183 12.9197 7.99736 12.9301 8.23145 12.9503C8.23258 12.9504 8.23356 12.9495 8.23356 12.9483V12.5266C8.23356 12.5258 8.23305 12.5251 8.23231 12.5248L6.9001 12.0019C6.52011 11.8528 6.21853 11.5806 6.0329 11.2408V11.2408Z" fill="#595959"/>
<path d="M10.022 6.66062C10.0605 6.96095 10.0934 7.26988 10.1211 7.58514C10.1212 7.58647 10.1225 7.5873 10.1238 7.58683L12.8796 6.50525C13.1677 6.39216 13.4776 6.36029 13.7912 6.41304C13.9066 6.43244 14.0188 6.4672 14.1278 6.50994L14.2469 6.55671C14.2487 6.55738 14.2503 6.55558 14.2494 6.55394C13.9406 5.95019 13.5528 5.39333 13.0997 4.89689C13.099 4.89607 13.0977 4.89603 13.0969 4.89677C12.2003 5.70688 11.153 6.30347 10.0233 6.65854C10.0224 6.65882 10.0219 6.65968 10.022 6.66062V6.66062Z" fill="#595959"/>
<path d="M5.21609 14.4794C5.21585 14.4782 5.21464 14.4775 5.21351 14.4779C4.3458 14.7749 3.53635 15.2382 2.83378 15.8506C2.82632 15.8571 2.81846 15.8628 2.81079 15.8688C3.78194 16.6479 4.94888 17.1922 6.22516 17.4148C6.22688 17.4151 6.22814 17.4133 6.2272 17.4118C5.93625 16.9696 5.68258 16.3563 5.46789 15.5737C5.37419 15.2323 5.29015 14.866 5.21609 14.4794V14.4794Z" fill="#595959"/>
<path d="M5.02938 13.302C4.91992 12.4493 4.85321 11.5302 4.83346 10.5796C4.83342 10.5785 4.83256 10.5776 4.83151 10.5776H0.00395224C0.00277908 10.5776 0.00187965 10.5787 0.00195786 10.5798C0.138318 12.3058 0.857935 13.8701 1.96313 15.0755C1.96399 15.0765 1.96544 15.0764 1.96614 15.0753C1.99332 15.036 2.02519 14.999 2.06293 14.9662C2.93318 14.2077 3.94429 13.6455 5.02801 13.3041C5.02895 13.3038 5.0295 13.303 5.02938 13.302V13.302Z" fill="#595959"/>
<path d="M4.83072 9.40448C4.83177 9.40448 4.83267 9.40362 4.83267 9.40256C4.85184 8.44417 4.91871 7.51757 5.02898 6.65838C5.0291 6.65745 5.02855 6.65659 5.02765 6.65627C3.94338 6.31477 2.93184 5.75223 2.06128 4.99316C2.02393 4.96059 1.99233 4.92402 1.96531 4.88511C1.96461 4.8841 1.96312 4.88398 1.9623 4.88492C0.852609 6.09542 0.131663 7.66777 -0.000239402 9.40233C-0.000317613 9.40346 0.00058181 9.40448 0.00175497 9.40448H4.83072Z" fill="#595959"/>
</g>
<defs>
<clipPath id="clip0_525_45952">
<rect width="20" height="20" fill="white"/>
</clipPath>
</defs>
</svg>
</template>
