import {
  createSender,
  createSimpleSender,
} from '@resource/resource'

import {
  prefixAPI,
} from '@resource/api'

const MODEL = 'auth'

// SIGN-IN, SIGN_OUT

const AUTHENTICATE_CREATE_LINK = prefixAPI('/login/', MODEL)
const LOGOUT_LINK = prefixAPI('/logout/', MODEL)

export const authenticateCreate = createSender(AUTHENTICATE_CREATE_LINK)
export const logoutCreate = createSimpleSender(LOGOUT_LINK)

// SIGN-UP
const REGISTER_CREATE_LINK = prefixAPI('/registration/', MODEL)
const REGISTER_SEND_CODE_LINK = prefixAPI('/registration/send-verification-code/', MODEL)
const REGISTER_CHECK_CODE_LINK = prefixAPI('/registration/check-verification-code/', MODEL)

export const registerCreate = createSender(REGISTER_CREATE_LINK)
export const registerSendCode = createSender(REGISTER_SEND_CODE_LINK)
export const registerCheckCode = createSender(REGISTER_CHECK_CODE_LINK)

// PASSWORD
const MODEL_PASSWORD = `${MODEL}/password`

const PASSWORD_RESET_LINK = prefixAPI('/send-mail/', MODEL_PASSWORD)
const PASSWORD_CONFIRM_LINK = prefixAPI('/set/', MODEL_PASSWORD)
const PASSWORD_CHANGE_LINK = prefixAPI('/change/', MODEL_PASSWORD)

export const passwordResetCreate = createSender(PASSWORD_RESET_LINK)
export const passwordConfirmCreate = createSender(PASSWORD_CONFIRM_LINK)
export const passwordChange = createSender(PASSWORD_CHANGE_LINK)
