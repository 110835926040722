<script>
import { ref, defineComponent } from 'vue'

export default defineComponent({
  name: 'UiTabs',
  props: {
    active: {
      default: () => 0,
    },
  },
  setup(props, { slots }) {
    const activeItem = ref(props.active)

    const deactivated = () => { 
      activeItem.value = null
    }

    const activate = (item) => {
      activeItem.value = item
    }
    return () => slots.default({
      activeItem: activeItem.value,
      activate: activate,
      deactivated: deactivated,
    })
  },
})
</script>
