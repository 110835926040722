<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.523438 15.4312L1.58515 11.5715C0.932402 10.438 0.589965 9.15414 0.591962 7.84805C0.584119 3.73072 3.92818 0.386719 8.0384 0.386719C10.0326 0.386719 11.9055 1.16005 13.316 2.57051C14.7265 3.98098 15.4997 5.8538 15.4997 7.84805C15.4997 11.958 12.156 15.3024 8.04583 15.3024C6.79956 15.304 5.57331 14.9909 4.48216 14.3925L0.523438 15.4312ZM4.65677 13.0501L4.88422 13.1867C5.83837 13.7516 6.92819 14.0503 8.03881 14.0511C11.451 14.0511 14.2341 11.268 14.2341 7.85585C14.2341 6.20251 13.5897 4.64067 12.4219 3.47267C11.2533 2.30549 9.69124 1.65318 8.03799 1.65318C4.61797 1.65318 1.83489 4.43636 1.83489 7.84846C1.83489 9.01646 2.161 10.1615 2.78309 11.1469L2.92715 11.3824L2.29764 13.6725L4.65595 13.0505L4.65677 13.0501Z" fill="white"/>
<path d="M0.78125 15.1734L1.80499 11.4429C1.17373 10.3488 0.841698 9.10947 0.841931 7.84824C0.841931 3.8827 4.07248 0.652344 8.03822 0.652344C9.96433 0.652344 11.7691 1.40311 13.1338 2.76024C13.8044 3.42897 14.3359 4.22257 14.6976 5.09563C15.0593 5.96869 15.2443 6.90406 15.2419 7.84824C15.2419 11.8142 12.0118 15.0445 8.04565 15.0445C6.84028 15.0445 5.6572 14.741 4.60292 14.165L0.78125 15.1734Z" fill="url(#paint0_linear_3_68863)"/>
<path d="M0.523438 15.4312L1.58515 11.5715C0.932402 10.438 0.589965 9.15414 0.591962 7.84805C0.584119 3.73072 3.92818 0.386719 8.0384 0.386719C10.0326 0.386719 11.9055 1.16005 13.316 2.57051C14.7265 3.98098 15.4997 5.8538 15.4997 7.84805C15.4997 11.958 12.156 15.3024 8.04583 15.3024C6.79956 15.304 5.57331 14.9909 4.48216 14.3925L0.523438 15.4312ZM4.65677 13.0501L4.88422 13.1867C5.83837 13.7516 6.92819 14.0503 8.03881 14.0511C11.451 14.0511 14.2341 11.268 14.2341 7.85585C14.2341 6.20251 13.5897 4.64067 12.4219 3.47267C11.2533 2.30549 9.69124 1.65318 8.03799 1.65318C4.61797 1.65318 1.83489 4.43636 1.83489 7.84846C1.83489 9.01646 2.161 10.1615 2.78309 11.1469L2.92715 11.3824L2.29764 13.6725L4.65595 13.0505L4.65677 13.0501Z" fill="url(#paint1_linear_3_68863)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M6.17963 4.72459C6.04341 4.41362 5.8915 4.40623 5.76271 4.40623C5.64878 4.39844 5.52742 4.39844 5.39862 4.39844C5.27726 4.39844 5.07251 4.44398 4.89831 4.63351C4.7237 4.82305 4.24609 5.27064 4.24609 6.18839C4.24609 7.10572 4.91359 7.9931 5.0044 8.11413C5.09522 8.23557 6.29357 10.1769 8.18913 10.9277C9.76643 11.5492 10.0851 11.4282 10.4265 11.39C10.7675 11.3523 11.5258 10.9428 11.6851 10.5026C11.8366 10.0706 11.8366 9.69157 11.7912 9.61567C11.7458 9.53977 11.6166 9.49423 11.4345 9.39577C11.2451 9.30469 10.3353 8.85013 10.1606 8.789C9.98645 8.72828 9.86508 8.69792 9.74372 8.88008C9.62236 9.06962 9.26612 9.48685 9.15218 9.60787C9.04609 9.72931 8.93257 9.74449 8.75053 9.65341C8.56065 9.56274 7.96209 9.36541 7.24878 8.72828C6.69563 8.23557 6.32411 7.62141 6.21018 7.43926C6.10409 7.24972 6.19491 7.15126 6.29357 7.06018C6.37695 6.9769 6.48345 6.84028 6.57427 6.73403C6.66508 6.62777 6.69522 6.54449 6.76374 6.42305C6.82442 6.30203 6.79388 6.18798 6.74847 6.09731C6.70306 6.01362 6.3464 5.09628 6.17963 4.72459Z" fill="white"/>
<defs>
<linearGradient id="paint0_linear_3_68863" x1="8.01015" y1="15.1725" x2="8.01015" y2="0.649472" gradientUnits="userSpaceOnUse">
<stop stop-color="#20B038"/>
<stop offset="1" stop-color="#60D66A"/>
</linearGradient>
<linearGradient id="paint1_linear_3_68863" x1="8.01033" y1="15.4329" x2="8.01033" y2="0.38877" gradientUnits="userSpaceOnUse">
<stop stop-color="#F9F9F9"/>
<stop offset="1" stop-color="white"/>
</linearGradient>
</defs>
</svg>  