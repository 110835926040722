import { App } from 'vue'

import SchedulerBody from './Elements/Body.vue'
import NavArr from './Elements/NavArr.vue'
import NavBtns from './Elements/NavBtns.vue'
import SchedulerBtn from './Elements/Btn.vue'
import TZSelect from './Elements/TZSelect.vue'
// import LessonInfoModal from './Elements/LessonInfoModal.vue'

import SchedulerView from './SchedulerView.vue'
import MeetingCalendar from './MeetingCalendar.vue'

const register = (app: App<Element>): void => {
  app
    .component('SchedulerBody', SchedulerBody)
    .component('NavArr', NavArr)
    .component('NavBtns', NavBtns)
    .component('SchedulerBtn', SchedulerBtn)
    .component('TZSelect', TZSelect)
    // .component('LessonInfoModal', LessonInfoModal)
    
    .component('SchedulerView', SchedulerView)
    .component('MeetingCalendar', MeetingCalendar)
}

export default {
  register,
}
