/* eslint-disable */
import useGlobal from '@/composables/useGlobal'
import { getCookie } from '@utils/cookies'

export default function () {
  const { $t } = useGlobal()

  const getLessonDateTime = (dateTime, isFree) => {
    const date = new Date(dateTime)
    const [h, min] = date.toLocaleTimeString('uk-UA').split(':')
    const [day, month, year] = date.toLocaleDateString('uk-UA').split('.')

    const prefix = $t('с')
    const postfix = $t('до')

    const timeAfter = new Date(date.setMinutes(date.getMinutes() + (isFree ? 30: 60)))
    const [hAfter, minAfter] = timeAfter.toLocaleTimeString('uk-UA').split(':')
    const string = `${day}.${month}.${year} ${prefix} ${h}:${min} ${postfix} ${hAfter}:${minAfter}`

    return string
  }


  const getTimeZone = () => getCookie('EXPERTS_TIMEZONE_COOKIE_NAME').replace(/"/, '').replace(/"/, '')

  return {
    getLessonDateTime,
    getTimeZone,
  }
}
