<template lang="pug">
include /mixins.pug

+b.scheduler__nav-arr-wrapper
  +e.nav-arr.--prev(@click.prevent="emit('scheduler:nav', 'prev')" :class="{ 'is-disabled': isDisabled }")
    i-slider-left
  +e.nav-arr.--next(@click.prevent="emit('scheduler:nav', 'next')")
    i-slider-right

</template>

<script setup>
import { defineProps, defineEmits } from 'vue'

const emit = defineEmits(['scheduler:nav'])

const props = defineProps({
  isDisabled: Boolean,
})

</script>