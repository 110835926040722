import { App } from 'vue'

import { Form, Field, ErrorMessage, defineRule } from '.'
import {
  required,
  email,
  confirmed,
  min,
  max,
  alphaValidator,
  isWhatsApp,
  onlyEnglish,
  isYouTubeLink,
  minValueValidator,
} from './rules'

export default {
  install(app: App<Element>): any {
    app.component('VeeForm', Form)
    app.component('VeeField', Field)
    app.component('VeeError', ErrorMessage)

    defineRule('required', required)
    defineRule('email', email)
    defineRule('confirmed', confirmed)
    defineRule('min', min)
    defineRule('max', max)
    defineRule('alpha', alphaValidator)
    defineRule('isWhatsApp', isWhatsApp)
    defineRule('onlyEnglish', onlyEnglish)
    defineRule('isYouTubeLink', isYouTubeLink)
    defineRule('minValue', minValueValidator)
  }
}
