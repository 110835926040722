import { App } from 'vue'
import ProfileBalance from './ProfileBalance.vue'
import InfoLabel from './InfoLabel.vue'
import AvatarInfo from './AvatarInfo.vue'
import DeleteBtn from './DeleteBtn.vue'

const register = (app: App<Element>): void => {
  app
    .component('ProfileBalance', ProfileBalance)
    .component('InfoLabel', InfoLabel)
    .component('AvatarInfo', AvatarInfo)
    .component('DeleteBtn', DeleteBtn)
}

export default {
  register,
}
