import { App } from 'vue'
import Share from './Share.vue'

const register = (app: App<Element>): void => {
  app
    .component('Share', Share)
}

export default {
  register,
}
