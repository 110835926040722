<template lang="pug">
include /mixins.pug
div
  vue-easy-lightbox(
    :visible="visible"
    :imgs="activeSource"
    :index="index"
    :move-disabled="true"
    @hide="handleHide"
    ref="lightbox"
  )
  slot(
    :showImg="showImg"
  )

</template>

<script setup>
import { defineProps, ref } from 'vue'

const props = defineProps({
  source: {},
})
const activeSource = ref(props.source)
const index = ref(0)
const visible = ref(false)

const showImg = (imgs, imgIdx) => {
  if (imgs) {
    activeSource.value = imgs
  }
  index.value = imgIdx
  visible.value = true
}

const handleHide = () => {
  visible.value = false
}

showImg
handleHide

</script>
