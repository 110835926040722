import { App } from 'vue'
// import SignIn from './SignIn.vue'
// import SignUp from './SignUp.vue'
// import ForgotPassword from './ForgotPassword.vue'
import ConfirmPassword from './ConfirmPassword.vue'
// import ChangePassword from './ChangePassword.vue'
// import FeedbackForm from './Feedback.vue'
import FeedbackForm from './FeedbackView.vue'
import AuthForm from './AuthForm.vue'

const register = (app: App<Element>): void => {
  app
    // .component('SignIn', SignIn)
    // .component('SignUp', SignUp)
    // .component('ForgotPassword', ForgotPassword)
    .component('ConfirmPassword', ConfirmPassword)
    // .component('ChangePassword', ChangePassword)
    .component('FeedbackForm', FeedbackForm)
    .component('AuthForm', AuthForm)
  }

export default {
  register,
}
