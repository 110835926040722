<script>
import { defineComponent } from 'vue'
import { getId } from '@utils/helpers.js'
import { $vfm } from 'vue-final-modal'

export default defineComponent({
  name: 'ModalTrigger',
  props: {
    component: {
      type: String,
      default: () => null,
    },
    info: {
      type: Object,
      default: () => null,
    },
    videourl: {
      type: String,
    },
    classes: {
      type: Array,
    },
    clickToClose: {
      default: false,
    },
  },
  setup(props, { slots, attrs }) {
    const preview = () => {
      if (props.videourl) {
        return `https://img.youtube.com/vi/${getId(props.videourl)}/0.jpg`
      }
    }

    const modalOpener = () => $vfm.show({
      component: 'UiModalsContainer', 
    }, {
      ...props,
      ...attrs,
    })

    return () => slots.default({
      open: () => {
        modalOpener()
      },
      videoPreview: preview(),
    })
  },
})
</script>
