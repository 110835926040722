<template lang="pug">
include /mixins.pug

+b.info-block
  +e.title
    +b.P.ds-caption.--size_2md.--size_4md-sm.--color_main-dark.--medium {{ $t('Мой баланс') }}
  +e.content
    +b.g-row.--appearance_spaced.--space_1xl
      +b.g-cell.g-cols--12.--5-sm.--3-lg
        cost-form(
          :is-can-not-update="isCanNotUpdate"
        )
      +b.g-cell.g-cols--12.--7-sm.--4-lg
        withdraw-form(
          v-if="!isLoad"
          :initial="fundsInfo"
        )
      +b.g-cell.g-cols--12.--5-lg
        requisites-form(
          v-if="!isLoad"
          :initial="requisites"
          :is-can-not-update="isCanNotUpdate"
        )

</template>

<script setup>
import { defineProps, ref } from 'vue'
import { requisiteResource } from '@services/teacher.service'

const props = defineProps({
  isCanNotUpdate: Boolean,
})

const isLoad = ref(false)
const requisites = ref({})
const fundsInfo = ref({})

const getRequisites = async () => {
  isLoad.value = true
  try { 
    const {
      data: {
        item: {
          balance,
          country,
          fullName,
          iban,
          isCanWithdrawal,
          payPal,
          swift,
        }
      }
    } = (await requisiteResource.execute({}))
    fundsInfo.value = {
      balance,
      isCanWithdrawal,
    }
    requisites.value = {
      country,
      fullName,
      iban,
      payPal,
      swift,
    }
    isLoad.value = false
  } catch (e) {
    isLoad.value = false
    console.error(e)
  }
}

getRequisites()

</script>
