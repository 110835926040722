<template lang="pug">
include /mixins.pug

VeeForm(v-slot="{ errors }", @submit="request")
  +b.g-row.--appearance_spaced.ec-space_mt--5.--0-sm
    VeeError(name="nonFieldErrors" v-slot="{ message }"): +b.simple-errors {{ message }}
    +b.g-cell.g-cols--12
      DController(
        :class="'d-control-descriptor--bg_white'"
        :name="'cost'"
        :errors='errors'
        :static-label=`$t("Стоимость урока 1 час")`
        :type="'number'"
        v-model="formdata.cost"
        rules="required|minValue:1"
        v-access="'[0-9]'"
        min="1"
      )
    +b.g-cell.g-cols--12.ec-space_mt--2
      +b.BUTTON.link-button.--style_main.--color_pink.--hover_blue.--full-size(
        :disabled='isLoad || isCanNotUpdate'
        type='submit'
      )
        +b.P.ds-caption.--color_main-white.--size_sm.--size_md-lg.--semibold  {{ $t('Сохранить настройки') }}

</template>

<script setup>
/* eslint-disable */
import { defineProps, ref, reactive } from 'vue'
import {
  balanceResource,
  balanceUpdate,
} from '@services/teacher.service'
import { useModalOpener } from '@/composables/useModalOpener'
import { useSubmit } from '@/components/Forms/FormMixin'
import useGlobal from '@/composables/useGlobal'

const props = defineProps({
  isCanNotUpdate: Boolean,
})

const { $t } = useGlobal()

const isLoad = ref(false)
let formdata = reactive({})

const request = (sendData, control) => {
  isLoad.value = true
  useSubmit(send(sendData), control)
}

const send = async sendData => {
  try { 
    await balanceUpdate.execute({}, sendData)
    useModalOpener({
      component: 'UiElements/Modals/UiMessageModal',
      message: {
        title: $t('Ваши данные успешно обновлены!'),
        icon: 'i-checked',
      },
    })
    isLoad.value = false
  } catch (e) {
    isLoad.value = false
    return Promise.reject(e)
  }
}

const getCost = async () => {
  try { 
    const { data: { item: { cost } } } = (await balanceResource.execute({}))
    formdata.cost = cost
    isLoad.value = false
  } catch (e) {
    isLoad.value = false
  }
}

getCost()

</script>
