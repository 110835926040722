import {
  createReceiver,
  createSender,
  createUpdater,
  createRemover,
} from '@resource/resource'

import {
  prefixAPI,
} from '@resource/api'

const MODEL = 'teacher'

// INFO
const INFO_URL = prefixAPI('/info/{id}/', MODEL)
export const infoResource = createReceiver(INFO_URL)

// LANGUAGES
const LANGUAGES_LIST_URL = prefixAPI('/languages/', MODEL)
export const languagesResource = createReceiver(LANGUAGES_LIST_URL)

const LANGUAGES_UPDATE_URL = prefixAPI('/languages/update/', MODEL)
export const languagesUpdate = createSender(LANGUAGES_UPDATE_URL)

// SPECIALIZATIONS
const SPECIALIZATIONS_LIST_URL = prefixAPI('/specializations/', MODEL)
export const specializationsResource = createReceiver(SPECIALIZATIONS_LIST_URL)

const SPECIALIZATIONS_UPDATE_URL = prefixAPI('/specializations/update/', MODEL)
export const specializationsUpdate = createSender(SPECIALIZATIONS_UPDATE_URL)

// SLOT
const MODEL_SLOT = `${MODEL}/slot`

const FUTURE_LIST_URL = prefixAPI('/future/{?limit,offset}', MODEL_SLOT)
export const futureMeetingResource = createReceiver(FUTURE_LIST_URL)

const PAST_LIST_URL = prefixAPI('/past/{?limit,offset}', MODEL_SLOT)
export const pastMeetingResource = createReceiver(PAST_LIST_URL)

// SCHEDULER
const MODEL_SCHEDULER = `${MODEL}/scheduler`

const SCHEDULER_LIST_URL = prefixAPI('/retrieve/', MODEL_SCHEDULER)
export const schedulerConfigList = createReceiver(SCHEDULER_LIST_URL)

const SCHEDULER_UPDATE_URL = prefixAPI('/update/{id}/', MODEL_SCHEDULER)
export const schedulerConfigUpdate = createUpdater(SCHEDULER_UPDATE_URL)

// DESCRIPTION
const MODEL_DESCRIPTION = `${MODEL}/description`

const DESCRIPTION_UPDATE_URL = prefixAPI('/update/', MODEL_DESCRIPTION)
export const descriptionUpdate = createUpdater(DESCRIPTION_UPDATE_URL)

// SUMMARY
const MODEL_SUMMARY = `${MODEL}/summary`

const SUMMARY_UPDATE_URL = prefixAPI('/update/{id}/', MODEL_SUMMARY)
export const summaryUpdate = createUpdater(SUMMARY_UPDATE_URL)

const SUMMARY_CREATE_URL = prefixAPI('/create/', MODEL_SUMMARY)
export const summaryCreate = createSender(SUMMARY_CREATE_URL)

const SUMMARY_DELETE_URL = prefixAPI('/delete/{id}/', MODEL_SUMMARY)
export const summaryDelete = createRemover(SUMMARY_DELETE_URL)

// CERTIFICATE
const MODEL_CERTIFICATE = `${MODEL}/certificate`

const CERTIFICATE_UPDATE_URL = prefixAPI('/update/{id}/', MODEL_CERTIFICATE)
export const certificateUpdate = createUpdater(CERTIFICATE_UPDATE_URL)

const CERTIFICATE_CREATE_URL = prefixAPI('/create/', MODEL_CERTIFICATE)
export const certificateCreate = createSender(CERTIFICATE_CREATE_URL)

const CERTIFICATE_DELETE_URL = prefixAPI('/delete/{id}/', MODEL_CERTIFICATE)
export const certificateDelete = createRemover(CERTIFICATE_DELETE_URL)

// VIDEO
const MODEL_VIDEO = `${MODEL}/video`

const VIDEO_URL = prefixAPI('/retrieve/', MODEL_VIDEO)
export const videoResource = createReceiver(VIDEO_URL)

const VIDEO_UPDATE_URL = prefixAPI('/update/', MODEL_VIDEO)
export const videoUpdate = createUpdater(VIDEO_UPDATE_URL)

// TUTORIAL
const MODEL_TUTORIAL = `${MODEL}/tutorial`

const TUTORIAL_LIST_URL = prefixAPI('/list/', MODEL_TUTORIAL)
export const tutorialResource = createReceiver(TUTORIAL_LIST_URL)

const TUTORIAL_UPDATE_URL = prefixAPI('/update/{id}/', MODEL_TUTORIAL)
export const tutorialUpdate = createUpdater(TUTORIAL_UPDATE_URL)

const TUTORIAL_CREATE_URL = prefixAPI('/create/', MODEL_TUTORIAL)
export const tutorialCreate = createSender(TUTORIAL_CREATE_URL)

const TUTORIAL_DELETE_URL = prefixAPI('/delete/{id}/', MODEL_TUTORIAL)
export const tutorialDelete = createRemover(TUTORIAL_DELETE_URL)

// PROFILE
const MODEL_PROFILE = `${MODEL}/profile`

const PROFILE_URL = prefixAPI('/retrieve/', MODEL_PROFILE)
export const profileResource = createReceiver(PROFILE_URL)

const PROFILE_UPDATE_URL = prefixAPI('/update/', MODEL_PROFILE)
export const profileUpdate = createUpdater(PROFILE_UPDATE_URL)

const PROFILE_CHOICES_URL = prefixAPI('/retrieve-select/', MODEL_PROFILE)
export const profileChoicesResource = createReceiver(PROFILE_CHOICES_URL)

const MODEL_CATEGORY = 'category'

const CHILD_CHOICES_URL = prefixAPI('/childs/{parent}/', MODEL_CATEGORY)
export const childsChoicesResource = createReceiver(CHILD_CHOICES_URL)

// PROFILE TIME ZONE
const MODEL_TIME_ZONE = `${MODEL_PROFILE}/time-zone`

const TIME_ZONE_URL = prefixAPI('/retrieve/', MODEL_TIME_ZONE)
export const timeZoneResource = createReceiver(TIME_ZONE_URL)

const TIME_ZONE_UPDATE_URL = prefixAPI('/update/', MODEL_TIME_ZONE)
export const timeZoneUpdate = createUpdater(TIME_ZONE_UPDATE_URL)

// BALANCE
const MODEL_BALANCE = `${MODEL}/balance`

const BALANCE_URL = prefixAPI('/retrieve/', MODEL_BALANCE)
export const balanceResource = createReceiver(BALANCE_URL)

const BALANCE_UPDATE_URL = prefixAPI('/update/', MODEL_BALANCE)
export const balanceUpdate = createUpdater(BALANCE_UPDATE_URL)

const REQUISITE_URL = prefixAPI('/requisite/retrieve/', MODEL_BALANCE)
export const requisiteResource = createReceiver(REQUISITE_URL)

const REQUISITE_UPDATE_URL = prefixAPI('/requisite/update/', MODEL_BALANCE)
export const requisiteUpdate = createUpdater(REQUISITE_UPDATE_URL)
