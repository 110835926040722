<template lang="pug">
include /mixins.pug

+b.d-control-input.--appearance_bordered.--tel-wrap
  MazPhoneNumberInput(
    :key="defaultCountry"
    v-model="phoneValue"
    color="info"
    id="phone"
    :disabled="$attrs.disabled"
    :only-countries="onlyCountries"
    :no-example="true"
    :translations="trans"
    v-model:default-country-code="defaultCountry"
    @update="setNumber"
  )
 
</template>

<script setup>
/* eslint-disable */
import { ref, defineEmits, watch, computed, defineAsyncComponent, onMounted } from 'vue'
import { countriesResource, countryDetectResource } from '@services/countries.service'
import useGlobal from '@/composables/useGlobal'

const MazPhoneNumberInput = computed(() => defineAsyncComponent(() => import('maz-ui/components/MazPhoneNumberInput')))

const { $t } = useGlobal()

const props = defineProps({
  setValue: {},
  defaultCountry: {},
})

const emit = defineEmits(['update:country', 'update:tel', 'update:is-valid'])

const phoneValue = ref()
const defaultCountry = ref('RU')
const onlyCountries = ref([])
const isInit = ref(false)


const trans = {
  countrySelector: {
    placeholder: '',
    error: $t('Choose country'),
  },
  phoneInput: {
    placeholder: '',
    example: $t('Example:'),
  },
}

if (props.setValue) {
  phoneValue.value = props.setValue
}

const getCountries = async() => {
  try {
    const { data: { item: { countries } } } = (await countriesResource.execute({}))
    onlyCountries.value = countries
    if (!countries.includes(defaultCountry.value)) {
      defaultCountry.value = 'RU'
    }
  } catch (e) {
    console.error(e, 'error')
  }
}
getCountries()

const setNumber = val => {
  if (val?.uri) {
    emit('update:tel', val.e164)
    emit('update:country', val)    
    emit('update:is-valid', val.isValid)
  } else {
    if (!isInit.value) {
      emit('update:is-valid', true)
      isInit.value = true
    } else {
      emit('update:is-valid', val.isValid)
    }
  }
}

const updateDisplayNumber = () => {
  if (defaultCountry.value === 'RU' && phoneValue.value) {
    // Предположим, что номер хранится в e164 формате
    const localNumber = phoneValue.value.replace(/^(\+7|8)/, ''); // Убираем +7 или 8 в начале
    phoneValue.value = localNumber; // Устанавливаем локальный номер для отображения
  }
}

watch(phoneValue, updateDisplayNumber);

// UPDATE DEFAULT COUNTRY_CODE
onMounted(async () => {
  try {
    const { data } = await countryDetectResource.execute({});
    defaultCountry.value = data.item.country;
  } catch (error) {
    console.error('Error fetching country code:', error);
  }
});
</script>
  