<template lang="pug">
include /mixins.pug

+b.MAIN.main(v-cloak)
  

  horizontal-bar(
    :filter-url-attr="globalFilters"
    :filter-attrs="filterAttrs.top"
    :is-load='isAttrsLoad'
    :reset-trigger="resetTrigger"
    @update:filters="startFilter"
    @reset:filters="resetFilters"
    @trigger:show="triggerShow = $event"
  )

  slot(name='advantages')

  +b.top-of-content(ref='topOfContent')

  +b.SECTION.ds-section.--default.--bg_beige.--space_sm-till-xl
    +e.element
      +b.g-container.--variant_default
        +b.H1.ds-caption.--size_2md.--size_lg-md.--semibold.--color_main-dark.ec-space_mb--8.--10-xl(v-if="list.seo_h1_title") {{ list.seo_h1_title }}&nbsp;
        +b.H1.ds-caption.--size_2md.--size_lg-md.--semibold.--color_main-dark.ec-space_mb--8.--10-xl(v-else-if="list.pagination.total") {{ $t('Найдено') }} {{ list.pagination.total }}&nbsp;
          span(v-declension:teachers="list.pagination.total" :key="list.pagination.total")
        +b.H1.ds-caption.--size_2md.--size_lg-md.--semibold.--color_main-dark.ec-space_mb--8.--10-xl(v-else) {{ $t('Ничего не найдено...') }}

        +b.g-row.--space_1xl-xl
          +b.g-cell.g-cols.--8-xl.--9-mac.relative
            +b.preloader--full-block.--white(v-if='isLoad')
            slot(
              name='content'
              v-if="!list.data"
            )

            renderer(
              :result='list.data',
              v-if='list.data'
            )

            ui-limit-pagination(
              :pagination='list.pagination'
              @input='handlePaginate'
              v-if="total > limit"
            )
            +b.is-disable-xl
              slot(name='subcategories')

            slot(name='crumbs')

            slot(name='seo')

          +b.g-cell.g-cols--12.--4-xl.--3-mac
            +b.blackout(
              v-if="triggerShow"
              @click.prevent="triggerShow = false"
            )
            vertical-bar(
              :class="{'is-active': triggerShow}"
              :filter-url-attr="globalFilters"
              :filter-attrs="filterAttrs.rght"
              :reset-trigger="resetTrigger"
              @trigger:close="triggerShow = $event"
              @reset:filters="resetFilters"
              @update:filters="startFilter"
              :is-load='isAttrsLoad'
            )

            +b.is-visible-xl
              slot(name='subcategories')

            slot(name='advice')

  //- slot(name='crumbs')

  slot(name='other')

</template>

<script setup>
/* eslint-disable */
import { defineProps, ref, reactive, watch } from 'vue'
import {
  filesListUrl,
  attributesResource,
} from '@services/filters.service'
import { reduceFiltersData, checkFilter, prettifyUrl, friendlyUrlParser } from '@utils/filters'

const props = defineProps({
  // base: String,
  category: String,
  specialization: String,
  seo_h1_title: String,
  total: {},
  limit: {},
  offset: {},
})
console.log(props)
const triggerShow = ref(null)
const resetTrigger = ref(false)
const topOfContent = ref(null)
const isLoad = ref(false)
const isAttrsLoad = ref(false)
const globalFilters = reactive({})
const list = ref({
  data: '',
  seo_h1_title: props.seo_h1_title,
  pagination: {
    total: props.total,
    limit: props.limit,
    offset: props.offset,
  }
})
let baseUrl = `${props.category}`
if (props.specialization) {
  baseUrl = `${props.category}/${props.specialization}`
}

let params = reactive({
  category: props.category,
  label: props.specialization,
  filters: {},
})

let filterAttrs = ref({
  rght: {},
  top: {},
})

window.onpopstate = async () => {
  window.location.reload()
}

watch(() => triggerShow.value, () => {
  const { body } = document
  body.classList.toggle('is-overflow')
})

const getList = async params => {
  if (!isLoad.value || isLoad.value == null) {
    isLoad.value = true
  }

  try {
    const res = (await filesListUrl.execute(params))
    topOfContent.value.scrollIntoView({ block: 'start', behavior: 'smooth' })
    list.value = { ...res }
    isLoad.value = false
    prettifyUrl(params, baseUrl)
    getUrlFilters()
  } catch (e) {
    isLoad.value = false
    console.error(e, 'Error')
  }
}

const handlePaginate = page => {
  getList(
    {
      ...params,
      ...page,
    },
    checkFilter(params.filters)
  )
}

const startFilter = filters => {
  params.filters = {
    ...params.filters,
    ...filters,
  }
  const reducedData = reduceFiltersData(params.filters)
  getData(reducedData)
}

const getData = data => {
  const newParams = {
    ...params,
    filters: data,
  }
  getList(newParams, checkFilter(data))
}

const getUrlFilters = () => {
  params.filters = friendlyUrlParser()
  globalFilters.value = friendlyUrlParser()
}

const getAttributes = async() => {
  if (!isAttrsLoad.value || isAttrsLoad.value == null) {
    isAttrsLoad.value = true
  }
  const { data: { item } } = (await attributesResource.execute({ category: baseUrl }))
  filterAttrs.value = { ...item }
  isAttrsLoad.value = false
}

const resetFilters = () => {
  globalFilters.value = {}
  params.filters = {}  
  resetTrigger.value = !resetTrigger.value
  startFilter({})
}

getAttributes()
getUrlFilters()
console.log()
</script>
