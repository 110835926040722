<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_515_40329)">
<path d="M15.3096 13.8674C15.3096 15.7031 13.8215 17.1912 11.9858 17.1912C10.1502 17.1912 8.66211 15.7031 8.66211 13.8674C8.66211 12.0318 10.1502 10.5437 11.9858 10.5437C13.8215 10.5437 15.3096 12.0318 15.3096 13.8674Z" fill="#FDB500"/>
<path d="M11.9681 17.1914C9.19135 17.201 6.96167 19.4933 6.96167 22.27V23.2969C6.96167 23.6853 7.27661 24 7.66479 24H16.3068C16.6952 24 17.0099 23.6853 17.0099 23.2969V22.2155C17.0099 19.4348 14.751 17.1817 11.9681 17.1914Z" fill="#FDB500"/>
<path d="M6.57547 8.01384L7.81765 6.56346C8.15915 6.16503 7.95828 5.5443 7.44705 5.42217L5.58944 4.97869L4.59408 3.34905C4.33059 2.91765 3.67819 2.88414 3.39401 3.34905L2.39846 4.97869L0.54086 5.42217C0.0301786 5.54412 -0.171787 6.16448 0.170071 6.56346L1.41226 8.01384L1.25973 9.91722C1.21853 10.433 1.73745 10.8276 2.23056 10.6225L3.99405 9.88939L5.75717 10.6225C6.24203 10.824 6.76992 10.4411 6.72799 9.91722L6.57547 8.01384Z" fill="#FDB500"/>
<path d="M22.5882 8.01384L23.8304 6.56346C24.1718 6.16503 23.971 5.5443 23.4597 5.42217L21.6021 4.97869L20.6068 3.34905C20.3433 2.91765 19.6909 2.88414 19.4067 3.34905L18.4112 4.97869L16.5536 5.42217C16.0429 5.54412 15.8409 6.16448 16.1828 6.56346L17.425 8.01384L17.2724 9.91722C17.2312 10.433 17.7501 10.8276 18.2433 10.6225L20.0067 9.88939L21.7699 10.6225C22.2547 10.824 22.7826 10.4411 22.7407 9.91722L22.5882 8.01384Z" fill="#FDB500"/>
<path d="M14.5818 5.00064L15.824 3.55026C16.1655 3.15182 15.9646 2.53109 15.4534 2.40896L13.5958 1.96548L12.6004 0.336026C12.3369 -0.095554 11.6845 -0.129062 11.4004 0.335843L10.4046 1.96548L8.54703 2.40896C8.03653 2.53091 7.83457 3.15127 8.17643 3.55026L9.41861 5.00064L9.26609 6.90402C9.2247 7.42001 9.74381 7.81442 10.2369 7.60953L12.0004 6.87619L13.7635 7.60953C14.2484 7.81076 14.7763 7.42789 14.7343 6.90402L14.5818 5.00064Z" fill="#FDB500"/>
</g>
<defs>
<clipPath id="clip0_515_40329">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>
</template>
