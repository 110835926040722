/* eslint-disable */
import { createFriendlyUrl } from '@utils/filters'
import { prefixLanguage } from '@utils/urls'

export function getId(url) {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
  const match = url.match(regExp)
  const codeLength = 11
  if (match && match[2].length === codeLength) {
    return match[2]
  }
  return new Error('error parsed link')
}

export function getCompleteUrl(prefix, params) {
  const filtersUrl = createFriendlyUrl(params)
  const url = prefixLanguage(`${prefix}${filtersUrl}`)
  return url
}
