import {
  baseResource,
  createReceiver,
  createSender,
} from '@resource/resource'

import {
  friendlyUrlGenerator,
} from '@resource/vue-friendly/main.js'

import {
  prefixAPI,
} from '@resource/api'

const MODEL = 'slot'

const order = {
  path: [
    'prefix',
    'category',
    'label',
    'postfix',
    'filters',
  ],
  query: [
    'pagination_offset',
  ],
}

const FILTERS_LIST_URL_PREFIX = prefixAPI('', MODEL)
const FILTERS_LIST_URL_POSTFIX = '/list/'
export const filesListUrl =
  friendlyUrlGenerator([FILTERS_LIST_URL_PREFIX, FILTERS_LIST_URL_POSTFIX], baseResource, order)

// STUDENT SCHEDULER
const MODEL_STUDENT = `${MODEL}/student`

const STUDENT_FILTERS_LIST_URL_PREFIX = prefixAPI('', MODEL_STUDENT)

export const studentFilesListUrl =
  friendlyUrlGenerator([STUDENT_FILTERS_LIST_URL_PREFIX, FILTERS_LIST_URL_POSTFIX], baseResource, order)

// LESSON
const MODEL_LESSON = `${MODEL}/lesson`

const LESSON_DETAIL_LINK = prefixAPI('/detail/', MODEL_LESSON)
export const lessonDetail = createSender(LESSON_DETAIL_LINK)
const LESSON_DETAIL_STUDENT_LINK = prefixAPI('/detail/student/', MODEL_LESSON)
export const lessonStudentDetail = createSender(LESSON_DETAIL_STUDENT_LINK)

const LESSON_TURN_LINK = prefixAPI('/turn/', MODEL_LESSON)
export const lessonTurn = createSender(LESSON_TURN_LINK)

const LESSON_FILTERS_LIST_URL_PREFIX = prefixAPI('', MODEL_LESSON)

export const lessonFilesListUrl =
  friendlyUrlGenerator([LESSON_FILTERS_LIST_URL_PREFIX, FILTERS_LIST_URL_POSTFIX], baseResource, order)

// TIME-ZONE
const MODEL_TIME_ZONE = 'time-zone'

const TIME_ZONES_LIST = prefixAPI('/list/', MODEL_TIME_ZONE)

export const timeZonesResource = createReceiver(TIME_ZONES_LIST)
