<script>
import { ref, defineComponent } from 'vue'

export default defineComponent({
  name: 'UiAccordion',
  props: {
    active: {
      default: () => false,
    },
    init: {
      default: () => false,
    },
  },
  setup(props, { slots }) {
    const activeState = ref(props.active)
    const icon = ref('i-arrow-down')
    if (props.init) { 
      activeState.value = true
    } else {
      activeState.value = false
    }

    function setToggleActive() {
      activeState.value = !activeState.value
      icon.value = activeState.value ? 'i-arrow-up' : 'i-arrow-down'
    }

    return () => slots.default({
      activeState: activeState.value,
      toggleActive: setToggleActive,
      icon: icon.value,
      collapse: () => {
        activeState.value = false
        icon.value = activeState.value ? 'i-arrow-up' : 'i-arrow-down'
      },
    })
  },
})
</script>
