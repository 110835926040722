<template lang="pug">
include /mixins.pug

div
  div(v-bind="getRootProps()")
    +b.INPUT(
      v-bind="getInputProps()"
      type="file"
    )

  slot(:open="() => rest.open()")

  +b.P.simple-errors.ec-space_mb--2.ec-space_mt--2(v-if="sizeError") {{ $t('Maxs file size:') }} {{ maxSize }} Mb

</template>

<script setup>
/* eslint-disable */
import { useDropzone } from "vue3-dropzone"
import { ref, reactive, defineEmits, defineProps } from 'vue'
import { toBase64 } from '@utils/transformers'

const props = defineProps({
  accept: {
    type: String,
    default: '',
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  maxSize: {
    type: Number,
    default: 3,
  }
})

const emit = defineEmits(['file:single-add'])
const sizeError = ref(false)
// get bytes
const maxSizeKb = props.maxSize * 1e+6

function onDrop(acceptFiles, rejectReasons) {
  // console.log(acceptFiles, 'acceptFiles')
  const first = acceptFiles[0]
  sizeError.value = false
  if (first.size > maxSizeKb) {
    sizeError.value = true
  } else {
    toBase64(first).then(res => {
      emit('file:single-add', res)
    })
  }

  if (rejectReasons.length) {
    console.log(rejectReasons, 'rejectReasons')
  }
}

const options = reactive({
  multiple: props.multiple,
  onDrop,
  accept: props.accept,
})

const { getRootProps, getInputProps, ...rest } = useDropzone(options)

</script>
