import { App } from 'vue'
import Forms from './Forms'
import UiElements from './UiElements'
import Icons from './i'
import Catalog from './Catalog'
import Profiles from './Profiles'
import Schedulers from './Schedulers'
import Common from './Common'
import Steps from './Steps'

const installComponents = (app: App<Element>): void => {
  UiElements.register(app)
  Icons.register(app)
  Catalog.register(app)
  Forms.register(app)
  Common.register(app)
  Schedulers.register(app)
  Profiles.register(app)
  Steps.register(app)
}

export default {
  installComponents,
}
