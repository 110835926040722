/* eslint-disable */
import useGlobal from '@/composables/useGlobal'

export default function () {
  const { $t } = useGlobal()

  const getDateLabel = date => {
    const months = {
      '01':	$t('Jan.'),
      '02':	$t('Feb.'),
      '03':	$t('Mar.'),
      '04':	$t('Apr.'),
      '05': $t('May'),
      '06':	$t('Jun.'),
      '07':	$t('Jul.'),
      '08':	$t('Aug.'),
      '09':	$t('Sep.'),
      '10': $t('Oct.'),
      '11': $t('Nov.'),
      '12': $t('Dec.'),
    }
    const now = $t('Сегодня')
  
    const [d, m, y] = new Date(date).toLocaleDateString('uk-UA').split('.')
    const [h, min] = new Date(date).toLocaleTimeString('uk-UA').split(':')
    const [today, todayMonth, todayYear] = new Date().toLocaleDateString('uk-UA').split('.')

    let string = ''
  
    if (d === today && m === todayMonth &&  y === todayYear) {
      string = `${now}, ${h}:${min}`
    } else {
      string = `${d} ${months[m]}, ${h}:${min}`
    }
  
    return string
  }

  const prepareData = data => {
    Object.keys(data).forEach(key => {
      if (Array.isArray(data[key]) && data[key].length) {
        data[key] = data[key].map(el => {
          if(el?.slug) {
            return el.slug
          } else {
            if (el?.language?.slug && el?.levelPossession?.slug) {
              el = {
                id: el?.id || null,
                language: el.language.slug,
                levelPossession: el.levelPossession.slug,
              }
            } else {
              el = {
                isDelete: true,
              }
            }
            return el
          }  
        })
      } else if (data[key]?.slug) {
        data[key] = data[key].slug
      }
    })
  
    return data
  }

  const prepareUpdatedData = (data, initialValues) => {
    let deletedItems = initialValues.filter(o => !data.languages.some(i => {
      if (i.id === o.id) {
        return i
      }
      return false
    }))
    deletedItems = deletedItems.map(el => {
      const obj = {
        id: el.id,
        isDelete: true,
      }
      return obj
    })
    data.languages.push(...deletedItems)
    return data
  }

  return {
    getDateLabel,
    prepareData,
    prepareUpdatedData,
  }
}
