import { App } from 'vue'

import AccountSettings from './AccountSettings.vue'
import Balance from './BalanceView.vue'
import CostForm from './Balance/CostForm.vue'
import WithdrawForm from './Balance/WithdrawForm.vue'
import RequisitesForm from './Balance/RequisitesForm.vue'
import FutureMeetings from './FutureMeetings.vue'
import PastMeetings from './PastMeetings.vue'
import Languages from './Languages.vue'
import Tutorials from './Tutorials.vue'
import Specializations from './Specializations.vue'
import SchedulerConfig from './SchedulerConfig.vue'
import VideoView from './VideoView.vue'
import TeacherView from './View.vue'

const register = (app: App<Element>): void => {
  app
    .component('AccountSettings', AccountSettings)
    .component('Balance', Balance)
    .component('CostForm', CostForm)
    .component('WithdrawForm', WithdrawForm)
    .component('RequisitesForm', RequisitesForm)
    .component('FutureMeetings', FutureMeetings)
    .component('PastMeetings', PastMeetings)
    .component('Languages', Languages)
    .component('Specializations', Specializations)
    .component('Tutorials', Tutorials)
    .component('SchedulerConfig', SchedulerConfig)
    .component('VideoView', VideoView)
    .component('TeacherView', TeacherView)
  }

export default {
  register,
}
