import { App } from 'vue'

import i18n from './i18n'
import Validation from './validation/plugin'
import StarRating from './vue-star-rating'
import VueFinalModal from './vue-final-modal'
import VueSlider from './vue-slider-component'
import Tippy from './vue-tippy'
import VueSocialSharing from './vue-social-sharing'
import VueClipboard from './vue-clipboard'
import VueEasyLightbox from './vue-easy-lightbox'
// import VueRecaptcha from './vue-recaptcha'

/* eslint-disable */
export default {
  install(app: App<Element>) {
    app
      .use(i18n)
      .use(Validation)
      .use(StarRating)
      .use(VueFinalModal)
      .use(VueSlider)
      .use(Tippy)
      .use(VueSocialSharing)
      .use(VueClipboard)
      .use(VueEasyLightbox)
      // .use(VueRecaptcha)
  }
}
