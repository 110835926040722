<template lang="pug">
include /mixins.pug

+b.avatar-info
  +b.P.ds-caption.--size_md.--size_3md-md.--color_main-dark.--bold.--appearance_center {{ $t('Изображение профиля') }}
  +e.wrapper
    ui-cropper(
      @crop-success="cropSuccess($event), cropperToggle()"
      @close="cropperToggle()"
      :options="cropperOptions"
      v-if="showCropper"
    )
    +b.PICTURE.ds-avatar.--size_3xl.--appearance_rounded
      +e.IMG.image(
        :src="avatar || '/static/app/default.png'"
        alt='avatar'
      )
  +b.g-row.--appearance_spaced.--justify_center
    +b.g-cell.g-cols--12.--4-sm.--6-xl
      +b.BUTTON.link-button.--style_main.--color_pink.--hover_blue.--full-size.--min-full-size(@click.prevent="cropperToggle()" :disabled="isCanNotUpdate")
        +b.ds-caption.--color_main-white.--size_sm.--size_md-lg.--semibold {{ $t('Изменить') }}
    +b.g-cell.g-cols--12.--4-sm.--6-xl
      +b.BUTTON.link-button.--style_main.--color_blue.--hover_pink.--full-size.--min-full-size(
        @click.prevent="cropDelete()"
        :disabled="isCanNotUpdate"
      )
        +b.ds-caption.--color_main-white.--size_sm.--size_md-lg.--semibold {{ $t('Удалить') }}

</template>

<script setup>
/* eslint-disable */
import { ref, defineProps, defineEmits, watch } from 'vue'

const emit = defineEmits(['avatar:update'])

const props = defineProps({
  avatar: String,
  isCanNotUpdate: Boolean,
})

const avatar = ref('')
const showCropper = ref(false)

watch(() => props.avatar, () => {
  avatar.value = props.avatar || ''
})

const cropperOptions = {
  width: 250,
  height: 250,
  field: 'avatar',
}
const cropSuccess = data => {
  // console.log(data, 'data')
  avatar.value = data
  emit('avatar:update', data)
}

const cropDelete = () => {
  avatar.value = ''
  emit('avatar:update', null)
}

const cropperToggle = () => {
  // console.log('cropperToggle')
  showCropper.value = !showCropper.value
}

</script>
