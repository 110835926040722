<template lang="pug">
include /mixins.pug

+b.balans-view.--teacher
  +e.icon
    i-money-bag(class="svg-image__element")
  +e.item
    +e.P.count {{ balance }} $
    +b.P.ds-caption.--color_main-gray.--regular {{ $t('Баланс вашего кошелька') }}

</template>

<script setup>
/* eslint-disable */
import { defineProps } from 'vue'

const props = defineProps({
  balance: String,
})
// // rounded balance
// const preparedBalance= val => {
//   const newVal = Math.round(Number(val))
//   return newVal
// }

</script>
