import {
  baseResource,
  createSender,
} from '@resource/resource'

import {
  friendlyUrlGenerator,
} from '@resource/vue-friendly/main.js'

import {
  prefixAPI,
} from '@resource/api'

const MODEL = 'package'

const order = {
  path: [
    'prefix',
    'category',
    'label',
    'postfix',
    'filters',
  ],
  query: [
    'pagination_offset',
  ],
}

const PACKAGES_LIST_URL_PREFIX = prefixAPI('', MODEL)
const PACKAGES_LIST_URL_POSTFIX = '/list/'
export const packagesListUrl =
  friendlyUrlGenerator([PACKAGES_LIST_URL_PREFIX, PACKAGES_LIST_URL_POSTFIX], baseResource, order)

const PACKAGE_BUY_LINK = prefixAPI('/buy/', MODEL)
export const packageBuyResource = createSender(PACKAGE_BUY_LINK)

const PACKAGE_VALIDATE_BALANCE_LINK = prefixAPI('/buy/validate-balance/', MODEL)
export const packageValidateResource = createSender(PACKAGE_VALIDATE_BALANCE_LINK)
