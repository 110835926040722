/* eslint-disable */
import { parseTemplate } from 'url-template'
const filterUrl = parseTemplate('/{;filters*}/')
const zero = 0

export function friendlyUrlParser() {
  let path = window.location.pathname
  const zero = 0
  const parsedUrl = {}
  if (path.indexOf('filters') > zero) {
    path = path.split('/page')[0]
    const hashes = path.split('filters/')[1].split(';')
    hashes.map(hash => {
      let [key, val] = hash.split('=')

      parsedUrl[key] = []
      if (val.indexOf(',') > zero) {
        val = val.split(',')
        val.forEach(v => {
          if (v.indexOf('/') > zero) {
            parsedUrl[key].push(decodeURIComponent(v.split('/')[zero]))
          } else {
            parsedUrl[key].push(decodeURIComponent(v))
          }
        })
      } else if (val.indexOf('/') > zero) {
        parsedUrl[key].push(decodeURIComponent(val.split('/')[zero]))
      } else {
        parsedUrl[key].push(decodeURIComponent(val))
      }
    })
  }
  return parsedUrl
}

export function reduceFiltersData(data) {
  const reducedData = Object.keys(data).reduce((acc, key) => {
    if (data[key]) {
      if (Array.isArray(data[key]) &&
        data[key].length) {
        acc[key] = data[key].map(option => {
          if (option.slug) {
            return option.slug
          }
          if (option.id) {
            return option.id
          }
          return option
        })
      } else if ('string' === typeof data[key]) {
        acc[key] = data[key]
      } else {
        acc[key] = data[key].slug
        if (data[key].id) {
          acc[key] = data[key].id
        }
      }
    }
    return acc
  }, {})
  return reducedData
}

export const checkFilter = data => {
  return Object.keys(data).every(key => !data[key])
}

export function createFriendlyUrl(data) {
  let url = filterUrl.expand({ filters: data })
  url = url.replace(/;/, '')
    .replace(/%2C/g, ',')
    .replace(/\//, '')
  if ('/' === url) {
    url = ''
  }
  return url
}

export function prettifyUrl(params, base) {
  // console.log(params, 'params')
  const keys = Object.keys(params.filters)

  for (let x = 0; x <= keys.length; x++) {
    if (params.filters[keys[x]] !== undefined) {
      if (zero === params.filters[keys[x]].length) {
        delete params.filters[keys[x]]
      }
    }
  }
  let url = filterUrl.expand(params)
  url = url.replace(/;/, '')
  url = url.replace(/%2C/g, ',')
  const length = 2
  let fullUrl
  const shift = 1
  const lang = window.language
  const page = Math
    .floor(params.offset / params.limit) + shift

  if (url.length > length) {
    fullUrl = `/${lang}/${base}/filters${url}`
  } else {
    fullUrl = `/${lang}/${base}/`
  }

  if (page > shift) {
    fullUrl = `${fullUrl}page/${page}/`
  }

  window.history.pushState({ url: fullUrl }, '', `${fullUrl}${window.location.search}`)
}

export const prepareSetData = (data, options, formdata) => {
  Object.keys(data).forEach(key => {
    const item = data[key]
    if (Array.isArray(item) && item.length) {
      item.forEach(val => {
        if ('cost_from' === key || 'cost_to' === key) {
          formdata[key] = val
        } else {
          Object.keys(options).forEach(opt => {
            options[opt].forEach(optVal => {
              if (optVal.slug === val) {
                if ('nativeLanguage' === key) {
                  if (!formdata[key].includes(optVal)) {
                    formdata[key].push(optVal)
                  } else {
                    formdata[key] = []
                    formdata[key].push(optVal)
                  }
                } else {
                  formdata[key] = optVal
                }
              }
            })
          })
        }
      })
    }
  })
  return formdata
}
