/* eslint-disable */
import dictionary from './dictionary'

export const declension = {
  beforeMount: (el: any, binding: any) => {
    const lang = window.language as string
    const countVal = binding.value as number
    const lastNumberOfAmount = Number(String(countVal).split('')[String(countVal).split('').length - 1])

    const handleCase = (amount: number, lastNumber: number) => {
      const currentDict = dictionary[binding.arg as keyof typeof dictionary]
      const [first, second, third] = currentDict[lang as keyof typeof currentDict]
      if (1 === amount) return first
      if (1 < amount && 5 > amount) return second
      if (10 <= amount && 20 >= amount) return third
      if (20 < amount && 1 === lastNumber) return first
      if (20 < amount && 1 < lastNumber && 5 > lastNumber) return second
      if (20 < amount && (5 <= lastNumber || 9 >= lastNumber || 0 === lastNumber)) return third
      return third
    }
    
    el.innerHTML = handleCase(countVal, lastNumberOfAmount)
  },
}
