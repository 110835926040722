<template lang="pug">
include /mixins.pug

div
  profile-balance(
    :balance="initial?.balance"
  )
  +b.teacher-profile__balance-form
    +e.info-wrap
      +e.UL.info
        +e.LI.info-item
          +b.P.ds-caption.--size_sm.--color_main-red.--regular {{ infoLAbel }}
        +e.LI.info-item
          +b.P.ds-caption.--size_sm.--color_main-red.--regular {{ $t('Вы не можете запросить новый вывод средств, пока предыдущая заявка на вывод находиться в обработке') }}
    VeeForm(v-slot="{ errors }", @submit="request")
      +b.g-row.--appearance_spaced
        +b.g-cell.g-cols--12.ec-space_mt--2
          DController(
            :class="'d-control-descriptor--h_default'"
            :component="'Multiselect'"
            :name="'method'"
            :options="outputOptions"
            v-model="method"
            :static-label=`$t("Способ вывода")`
            :placeholder=`$t("Выбор")`
            track-by="slug"
            :labelBy="'label'"
            rules="required"
          )
        +b.g-cell.g-cols--12
          VeeError(name="nonFieldErrors" v-slot="{ message }"): +b.simple-errors {{ message }}
        +b.g-cell.g-cols--12.ec-space_mt--2
          +b.BUTTON.link-button.--style_main.--color_blue.--hover_pink.--full-size(
            :disabled='isLoad || isBlocked'
            type='submit'
          )
            +b.P.ds-caption.--color_main-white.--size_sm.--size_md-lg.--semibold  {{ $t('Вывести средства') }}

</template>

<script setup>
/* eslint-disable */
import { ref, defineProps } from 'vue'
import {
  methodResource,
  withdrawalOfFunds,
} from '@services/withdrawal.service'
import useMethods from '../../Common/utils'
import { useModalOpener } from '@/composables/useModalOpener'
import { useSubmit } from '@/components/Forms/FormMixin'
import useGlobal from '@/composables/useGlobal'

const { $t } = useGlobal()
const { prepareData } = useMethods()

const props = defineProps({
  initial: Object,
})

const isLoad = ref(false)
const isBlocked = ref(!props.initial?.isCanWithdrawal)
const outputOptions = ref([])
const prefix = $t('Мин. сумма вывода:')
const infoLAbel = `${prefix} $ ${window.minWithdrawal}`

const method = ref([])

const request = (sendData, control) => {
  isLoad.value = true
  useSubmit(send(sendData), control)
}

const send = async data => {
  isLoad.value = false
  const sendData = prepareData(data)
  try { 
    await withdrawalOfFunds.execute({}, sendData)
    isBlocked.value = true
    useModalOpener({
      component: 'UiElements/Modals/UiMessageModal',
      message: {
        title: $t('Ваша заявка на вывод средств взята в обработку!'),
        icon: 'i-checked',
      },
    })
    isLoad.value = false
  } catch (e) {
    isLoad.value = false
    return Promise.reject(e)
  }
}

const getMethods = async() => {
  try {
    const { data: { items } } = (await methodResource.execute({}))
    outputOptions.value = items
  } catch (e) {
    console.error(e, 'error')
  }
}
getMethods()

</script>
