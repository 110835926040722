<template>
<svg width="30" height="30" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_3_43889)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.98145 15.9994L12.7642 6.50189H8.15338L10.5509 -0.000732422H6.42432L3.23633 8.44846H7.06035L4.98145 15.9994Z" fill="#FADB14"/>
</g>
<defs>
<clipPath id="clip0_3_43889">
<rect width="16" height="16" fill="white"/>
</clipPath>
</defs>
</svg>
</template>
