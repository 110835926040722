<template>
<svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_2_9970)">
<path d="M42.0652 8.7647H38.7896C39.2217 8.08091 39.5068 7.31337 39.5881 6.50161C39.8479 3.90734 38.4816 1.58175 36.1556 0.550709C34.0175 -0.397015 31.6149 -0.00959327 29.8847 1.55961L25.7486 5.30814C24.9431 4.42713 23.7851 3.87341 22.5 3.87341C21.2126 3.87341 20.0528 4.42888 19.2471 5.31262L15.1057 1.55917C13.3727 -0.0100326 10.971 -0.39596 8.83397 0.551237C6.50856 1.58237 5.14204 3.90875 5.40255 6.50293C5.48402 7.31407 5.76896 8.08127 6.20095 8.7647H2.93476C1.31388 8.7647 0 10.0787 0 11.6995V16.1016C0 16.912 0.656982 17.5691 1.46742 17.5691H43.5327C44.343 17.5691 45.0001 16.9121 45.0001 16.1016V11.6995C45 10.0787 43.6861 8.7647 42.0652 8.7647ZM18.0978 8.27559V8.7647H11.1946C9.37072 8.7647 7.93529 7.06437 8.39083 5.16541C8.59148 4.32913 9.19512 3.61625 9.97585 3.25537C11.0479 2.75984 12.2355 2.91892 13.136 3.73393L18.0991 8.23235C18.0988 8.24685 18.0978 8.26109 18.0978 8.27559ZM36.6762 6.11199C36.5669 7.63715 35.1892 8.76488 33.6602 8.76488H26.9022V8.27577C26.9022 8.25845 26.9011 8.24131 26.9009 8.224C28.0252 7.20482 30.4643 4.9942 31.7871 3.7951C32.539 3.11369 33.5909 2.79561 34.5633 3.08565C35.9588 3.5019 36.7783 4.6886 36.6762 6.11199Z" fill="white"/>
<path d="M2.93509 20.5041V42.0259C2.93509 43.6467 4.24897 44.9606 5.86984 44.9606H19.5655V20.5041H2.93509Z" fill="white"/>
<path d="M25.4351 20.5041V44.9606H39.1307C40.7516 44.9606 42.0655 43.6467 42.0655 42.0259V20.5041H25.4351Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_2_9970">
<rect width="45" height="45" fill="white"/>
</clipPath>
</defs>
</svg>
</template>
