<template lang="pug">
include /mixins.pug

div(ref='topOfContent')
  +b.info-block(v-if="list.items.length")
    +e.title
      +b.P.ds-caption.--size_2md.--size_4md-sm.--color_main-dark.--medium {{ $t('Мои будущие встречи') }}
    +e.content.relative
      +b.preloader.--white.--full-block(v-if='isLoad')
      +b.meeting-card.--variant_new(
        v-for="{ firstName, lastName, avatar, dateSlot, whatsappLink, age, levelsStudent, linkZoom, target } in list.items"
      )
        +b.inline.--ai_center.ec-space_mb--5
          +e.icon.svg-image.icons.--w_md.--center.--cursor_auto
            i-clock(class="svg-image__element")
          +b.P.ds-caption.--size_md.--size_3md-md.--color_main-blue.-regular {{ getDateLabel(dateSlot) }}
        +e.wrapper
          +e.elem.--variant_1
            +e.img-wrapper.ec-space_mb--2
              +b.sized-image
                +e.IMG.img(
                  :src="avatar ? avatar: '/static/app/default.png'"
                )
            +e.elem.--variant_2
              +b.P.ds-caption.--size_sm.--size_2md-md.--color_main-dark.--semibold.--appearance_center {{ firstName }} {{ lastName }}
              +b.P.ds-caption.--size_2xs.--color_main-dark.--regular.--appearance_center-md.meeting-card__age-title(v-if="age") {{ $t('Возраст:') }}
                +b.SPAN.ds-caption.--size_2xs.--color_main-dark.--regular.--appearance_center-md.ec-space_mr.ec-space_ml {{ age }}
          +e.elem.--variant_3
            +b.g-row.ec-space_mb--2(v-for="{ language, levelPossession }, index in levelsStudent" v-if="levelsStudent")
              +b.g-cell.g-cols--12.--narrow-sm
                +b.SPAN.meeting-card__items-title {{ $t('Язык:') }}
                +b.SPAN.meeting-card__items-val {{ language }}
              +b.g-cell.g-cols--12.--narrow-sm
                +b.SPAN.meeting-card__items-title {{ $t('Уровень языка:') }}
                +b.SPAN.meeting-card__items-val {{ levelPossession }}
            +b.g-row.ec-space_mb--2(v-if="linkZoom")
              +b.g-cell.g-cols--12
                +b.SPAN.meeting-card__items-title {{ $t('Ссылка зум на встречу:') }}
                +b.SPAN.meeting-card__items-val
                  +b.A.ds-link.--tr.--styling_default.is-hover(
                    :href="linkZoom"
                  )
                    +b.SPAN.ds-caption.--color_inherit {{ linkZoom }}

            +b.g-row.ec-space_mb--2(v-if="target")
              +b.g-cell.g-cols--12
                +b.SPAN.meeting-card__items-title {{ $t('Цель изучения:') }}
                +b.SPAN.meeting-card__items-val {{ target }}
            +b.g-row.--align_center
              +b.g-cell.g-cols--12.--narrow-sm
                +b.A.link-button.--style_main.--color_pink.--hover_blue.--full-size.ec-space_mt--2(
                  :href="whatsappLink"
                  target="_blank"
                  rel="noopener noreferrer"
                )
                  +b.SPAN.ds-caption.--color_main-white.--size_sm.--size_md-lg.--semibold {{ $t('Связаться с учеником') }}
            +b.ec-space_mt--5
              +b.P.ds-caption--size_sm.--color_main-dark.--regular
                +b.A.ds-link.--tr.--styling_default.is-hover(
                  :href="whatsappLink"
                  target="_blank"
                  rel="noopener noreferrer"
                )
                  +b.SPAN.ds-caption.--color_inherit.--appearance_underline.ec-space_ml--2.ec-space_mr--2 {{ $t('Отменить урок') }}

      ui-limit-pagination(
        :pagination='list.pagination'
        @input='handlePaginate'
        v-if="list.pagination.total > list.pagination.limit"
      )

</template>

<script setup>
/* eslint-disable */
import { ref, onMounted } from 'vue'
import { futureMeetingResource } from '@services/teacher.service'
import useMethods from '../Common/utils'

const { getDateLabel } = useMethods()

const isLoad = ref(null)
const topOfContent = ref(null)
const paginate = ref(false)
const list = ref({
  items: [],
  pagination: {
    total: {},
    limit: {},
    offset: {},
  }
})

onMounted(() => {
  getList({})
})

const getList = async (params) => {
  try {
    if (!isLoad.value || isLoad.value == null) {
      isLoad.value = true
    }
    const { data } = (await futureMeetingResource.execute(params))
    if (paginate.value) {
      topOfContent.value.scrollIntoView({ block: 'start', behavior: 'smooth' })
    }
    list.value = data
    isLoad.value = false
    paginate.value = false
  } catch (e) {
    console.error(e, 'Error')
    isLoad.value = false
    paginate.value = false
  }
}

const handlePaginate = page => {
  paginate.value = true
  getList(page)
}

</script>
