<template lang="pug">
include /mixins.pug
iframe(
  :src="iframeUrl()", 
  width='100%`'
  height='100%'
  allowfullscreen
)

</template>

<script setup>
import { defineProps } from 'vue'
import { getId } from '@utils/helpers.js'

const props = defineProps({
  url: String,
})

const preview = () => `https://img.youtube.com/vi/${getId(props.url)}/0.jpg`

const iframeUrl = () => `//www.youtube.com/embed/${getId(props.url)}?modestbranding=1&enablejsapi=1&rel=0&autoplay=1&mute=1&iv_load_policy=3&origin`

preview
iframeUrl

</script>
