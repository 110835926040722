<template lang="pug">
include /mixins.pug

+b.g-container.--variant_default
  meeting-calendar(
    :uid="uid"
    :first-name="firstName"
    :role="'teacher'"
    :is-can-not-update="isCanNotUpdate"
  )

  balance(
    :is-can-not-update="isCanNotUpdate"
  )

  future-meetings

  past-meetings

  account-settings(
    :is-can-not-update="isCanNotUpdate"
  )

  change-time-zone(
    :is-can-not-update="isCanNotUpdate"
  )

  slot(name='summary')

  slot(name='certification')

  slot(name='info')

  specializations(
    :is-can-not-update="isCanNotUpdate"
  )

  languages(
    :is-can-not-update="isCanNotUpdate"
  )

  video-view(
    :is-can-not-update="isCanNotUpdate"
  )

  tutorials(
    :is-can-not-update="isCanNotUpdate"
  )

</template>

<script setup>
import { defineProps } from 'vue'
import ChangeTimeZone from '@/components/Profiles/Common/ChangeTimeZone.vue'

const props = defineProps({
  uid: Number,
  firstName: String,
  isCanNotUpdate: Boolean,
})
// save position before unload
let cords = ['scrollX','scrollY']
window.addEventListener('unload', e => cords.forEach(cord => localStorage[cord] = window[cord]))
window.scroll(...cords.map(cord => localStorage[cord]))

</script>
