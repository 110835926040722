<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_2_201)">
<path d="M20.9844 18.3281C20.5967 18.3281 20.2812 18.0127 20.2812 17.625C20.2812 17.2373 20.5967 16.9219 20.9844 16.9219H21.6875V15.5156H17.4219V4.63064L12.7912 0H0.5V24H24.5V22.5938H23.7969C23.0346 22.5938 22.3906 21.9283 22.3906 21.1406C22.3906 20.3652 23.0215 19.7344 23.7969 19.7344H24.5V18.3281H20.9844ZM18.9959 16.9219C18.9179 17.142 18.875 17.3785 18.875 17.625C18.875 17.8715 18.9179 18.108 18.9959 18.3281H11.0938C10.706 18.3281 10.3906 18.0127 10.3906 17.625C10.3906 17.2373 10.706 16.9219 11.0938 16.9219H18.9959ZM13.2031 2.40061L15.0213 4.21875H13.2031V2.40061ZM1.90625 1.40625H11.7969V5.625H16.0156V15.5156H11.0938C9.93064 15.5156 8.98438 16.4619 8.98438 17.625C8.98438 18.2693 9.27505 18.8467 9.73175 19.2339C9.26825 19.7355 8.98438 20.4054 8.98438 21.1406C8.98438 21.6574 9.12209 22.1561 9.37986 22.5938H1.90625V1.40625ZM11.7969 22.5938C11.0346 22.5938 10.3906 21.9283 10.3906 21.1406C10.3906 20.3652 11.0215 19.7344 11.7969 19.7344H21.3627C21.1226 20.1484 20.9844 20.6285 20.9844 21.1406C20.9844 21.6574 21.122 22.1561 21.3798 22.5938H11.7969Z" fill="#26364B"/>
<path d="M8.98438 8.48438H14.6094V9.89062H8.98438V8.48438Z" fill="#26364B"/>
<path d="M3.35938 9.89062H7.57812V5.67188H3.35938V9.89062ZM4.76562 7.07812H6.17188V8.48438H4.76562V7.07812Z" fill="#26364B"/>
<path d="M8.98438 12.7031H14.6094V14.1094H8.98438V12.7031Z" fill="#26364B"/>
<path d="M3.35938 15.5156H7.57812V11.2969H3.35938V15.5156ZM4.76562 12.7031H6.17188V14.1094H4.76562V12.7031Z" fill="#26364B"/>
</g>
<defs>
<clipPath id="clip0_2_201">
<rect width="24" height="24" fill="white" transform="translate(0.5)"/>
</clipPath>
</defs>
</svg>