<template lang="pug">
include /mixins.pug
GoogleMap.full-h(
  :api-key="key"
  :center="center"
  :zoom="15"
  style="width: 100% height: 100%"
)
  Marker(
    :options="{ position: center, icon: icon }"
  )

</template>

<script setup>
import { defineProps } from 'vue'
/* eslint-disable */
import { GoogleMap, Marker } from "vue3-google-map"

const props = defineProps({
  center: {},
})

const icon = '/static/app/map-pin.png'
const key = window.gmapKey || ''

</script>
