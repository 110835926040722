import {
  createSender,
} from '@resource/resource'

import {
  prefixAPI,
} from '@resource/api'

const MODEL = 'lesson'

const LESSON_BUY_LINK = prefixAPI('/buy/', MODEL)
export const lessonBuyResource = createSender(LESSON_BUY_LINK)

const LESSON_FREE_BUY_LINK = prefixAPI('/free/buy/', MODEL)
export const lessonFreeBuyResource = createSender(LESSON_FREE_BUY_LINK)

const LESSON_CANCEL_LINK = prefixAPI('/cancel/', MODEL)
export const lessonCancelResource = createSender(LESSON_CANCEL_LINK)

const LESSON_MOVE_LINK = prefixAPI('/move/', MODEL)
export const lessonMoveResource = createSender(LESSON_MOVE_LINK)

const CHECK_SANCTION_LINK = prefixAPI('/cancel/is-sanction/', MODEL)
export const checkSanctionResource = createSender(CHECK_SANCTION_LINK)

const LESSON_BOOK_LINK = prefixAPI('/book/', MODEL)
export const lessonBookResource = createSender(LESSON_BOOK_LINK)

