/* eslint-disable */
export default function () {

  const convertTime = seconds => {
    let minutes = Math.floor(seconds / 60)
    let hours = ''
    if (59 < minutes) {
      hours = Math.floor(minutes / 60)
      hours = (10 <= hours) ? hours : `0${ hours}`
      minutes -= (hours * 60)
      minutes = (10 <= minutes) ? minutes : `0${ minutes}`
    }
    minutes = (10 <= minutes) ? minutes : `0${ minutes}`
    seconds = Math.floor(seconds % 60)
    seconds = (10 <= seconds) ? seconds : `0${ seconds}`
    if ('' !== hours) {
      return `${hours }:${ minutes }:${ seconds}`
    }
    return `00:${ minutes }:${ seconds }`
  }

  return {
    convertTime,
  }
}
