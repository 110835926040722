<template lang="pug">
include /mixins.pug
+b.search
  +e.input-wrapper
    +e.INPUT.input(
      type="text"
      name="search"
      v-model="search"
      :placeholder=`$t("Введите ваш запрос")`
      autocomplete="off"
      @keyup.enter='send'
    )

    +e.reset(v-if="search.length")
      IClose(@click="resetSearch")

    +e.btn(
      type='button'
      :disabled='search.length < 3',
      @click.prevent='send'
    )
      +b.P.ds-caption.--size_sm.--bold.--color_inherit {{ $t('OK1') }}

</template>

<script setup>
/* eslint-disable */
import { ref, defineProps, defineEmits, watch } from 'vue'

const props = defineProps({
  resetTrigger: Boolean,
})

const emit = defineEmits(['update:search'])

const search = ref('')

const resetSearch = () => {
  search.value = ''
  emit('update:search', search.value)
}

const send = () => {
  if (search.value.length >= 3) {
    emit('update:search', search.value)
    search.value = ''
  }
}

watch(() => props.resetTrigger, () => {
  search.value = ''
})

</script>
