<template lang="pug">
include /mixins.pug
AgileSlider(
  ref="carousel"
  :options="settings"
)
  slot

  template(#prevButton)
    component(
      :is='arrowLeft'
    )

  template(#nextButton)
    component(
      :is='arrowRight'
    )

</template>

<script setup>
/* eslint-disable */
import { defineProps } from 'vue'

const props = defineProps({
  settings: Object,
  arrowLeft: String,
  arrowRight: String,
})

</script>
