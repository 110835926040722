import { App } from 'vue'
// import Modals from './Modals'
import Common from './Common'
import TeacherProfile from './TeacherProfile'
import StudentProfile from './StudentProfile'

const register = (app: App<Element>): void => {
  TeacherProfile.register(app)
  StudentProfile.register(app)
  // Modals.register(app)
  Common.register(app)
}

export default {
  register,
}
