<template lang="pug">
include /mixins.pug

div
  +b.info-block.ec-space_mb--10
    +e.title.--bg_blue.--rounded
      +b.g-row.--space_none.--align_center.--justify_between
        +b.g-cell.g-cols--12.--6-md
          +b.P.ds-caption.--size_md.--size_lg-md.--color_white.--semibold.--appearance_center-till-md {{ firstName }}, {{ $t('добрый день!') }}
        +b.g-cell.g-cols--12.--6-md
          +b.scheduler__tz-wrapper
            t-z-select(
              @scheduler:setTZ="getData($event)"
              :no-observe='true'
              :target-ref="`target_${uid}`"
              class="d-control-descriptor--variant_tz-white"
            )

  +b.info-block.ec-space_pb--10
    +b.scheduler
      +b.preloader.--white.--full-block(v-if='isLoad')
      nav-arr(
        class="scheduler__nav-arr-wrapper--xl"
        @scheduler:nav="nav($event)"
        :is-disabled="date.toLocaleDateString('uk-UA') === params.filters.start_date"
        v-if="scheduleWeek.length"
      )

      +e.head.--variant_2
        +b.g-row.--align_center.--align_end-xl.--justify_center.full-w-mac
          +b.g-cell.g-cols--12.--narrow-sm
            +b.scheduler__title-wrap
              +b.P.ds-caption.--size_2md.--size_lg-xl.--line-h_1.--color_main-dark.--medium.--appearance_center-till-lg {{ $t('Календарь встреч') }}
          +b.g-cell.g-cols--12.--narrow-sm
            +b.P.ds-caption.--size_md.--color_main-gray.--medium.--appearance_center-till-lg {{ $t('Время встреч') }}
          +b.g-cell.g-cols--narrow
            nav-btns(
              @scheduler:nav="nav($event)"
              :week-label="getWeekLabel()"
              :is-disabled="date.toLocaleDateString('uk-UA') === params.filters.start_date"
            )

          +b.g-cell.g-cols--12.--auto-mac
            +b.scheduler__legend-wrapper
              +e.legend-item(v-for="{ color, title } in rolesLegend[role]")
                +b.inline.--ai_center
                  +b.scheduler__legend-icon(:class="color")
                  +b.P.ds-caption.--size_2xs.--size_md-md.--color_main-gray.--medium - {{ title }}

      +b.g-container.--fit_xl-mac
        scheduler-body(
          :schedule-week="scheduleWeek"
          :uid="uid"
          :role="role"
          :is-profile="true"
          :is-today="date.toLocaleDateString('uk-UA')"
          @scheduler:toggle-cell="getData({})"
          v-if="scheduleWeek.length"
        )
        +b.scheduler__no-results(v-else)
          +b.P.ds-caption.--size_2md.--size_4md-xl.--line-h_1.--color_main-dark.--medium.--appearance_center(v-if="isLoaded") {{ $t('no meetings yet') }}

      scheduler-btn(
        @scheduler:hours-down="getData($event)"
        v-if="scheduleWeek.length"
      )

    +b.g-container.--fit_xl-xl(v-if="role === 'teacher'")
      scheduler-config(
        :uid="uid"
        @schedule-update:success="getData({})"
        :is-can-not-update="isCanNotUpdate"
      )

</template>

<script setup>
/* eslint-disable */
import { defineProps, ref, reactive, onMounted } from 'vue'
import { filesListUrl, studentFilesListUrl } from '@services/schedulers.service'
import useScheduleMethodth from './utils'

const props = defineProps({
  uid: Number,
  firstName: String,
  role: String,
  isCanNotUpdate: Boolean,
})

const {
  rolesLegend,
  getWeekRange,
  getWeekLabel,
  getNavParams,
} = useScheduleMethodth()

// hours_down=20;start_date=05.05.2022;end_date=11.05.2022;teacher_id=1
const isLoad = ref(null)
const scheduleWeek = ref([])
const date = ref(new Date())
const isLoaded = ref(false)

let params = reactive({
  filters: {
    hours_down: 3,
    start_date: date.value.toLocaleDateString('uk-UA'),
    end_date: new Date(new Date().setDate(date.value.getDate() + 6)).toLocaleDateString('uk-UA'),
  },
})

if ('teacher' === props.role) {
  params.filters = { ...params.filters, teacher_id: props.uid }
}

onMounted(() => {
  getWeekRange(params)
})

const nav = direction => {
  const newParams = getNavParams(direction)
  getData(newParams)
}

const getData = data => {
  params.filters = { ...params.filters, ...data }
  getList(params)
}

const getList = async (params) => {
  try {
    if (!isLoad.value || isLoad.value == null) {
      isLoad.value = true
    }
    const methods = {
      student: studentFilesListUrl,
      teacher: filesListUrl,
    }

    const { data: { item: { days } } } = (await methods[props.role].execute(params))
    isLoaded.value = true
    scheduleWeek.value = days
    getWeekRange(params)
    isLoad.value = false
  } catch (e) {
    console.error(e, 'Error')
    isLoad.value = false
  }
}

</script>
