<template lang="pug">
include /mixins.pug
+b.LABEL.ui-switch
  +e.INPUT.input(
    type="checkbox"
    :checked="checked"
    :id="fieldId"
    @input="(event) => emit('update:checked', event.target.checked)"
  )
  +e.control
  +e.SPAN.label {{ label }}

</template>

<script setup>
import { defineProps, defineEmits } from 'vue'

const props = defineProps({
  label: String,
  fieldId: {
    type: String,
    required: true,
  },
  checked: {
    type: Boolean,
  },
})

const emit = defineEmits(['update:checked'])

props
emit
</script>
