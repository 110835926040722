<template lang="pug">
include /mixins.pug

+b.info-block
  +e.title
    +b.P.ds-caption.--size_2md.--size_4md-sm.--color_main-dark.--medium {{ $t('Настройки аккаунта') }}
  +e.content
    +b.g-row.--appearance_spaced.--space_1xl-xl
      +b.g-cell.g-cols--12.--9-xl
        VeeForm(v-slot="{ errors }", @submit="request")
          VeeError(name="nonFieldErrors" v-slot="{ message }"): +b.simple-errors {{ message }}
          +b.g-row.--space_1xl-md
            +b.g-cell.g-cols--12.--6-sm.ec-space_mt--2
              DController(
                :name="'firstName'"
                v-model="formdata.firstName"
                :errors='errors'
                :static-label=`$t("Имя")`
                :placeholder=`$t("Введите ваше имя")`
                rules="required|alpha|max:30"
              )
            +b.g-cell.g-cols--12.--6-sm.ec-space_mt--2
              DController(
                :name="'lastName'"
                v-model="formdata.lastName"
                :errors='errors'
                :static-label=`$t("Фамилия")`
                :placeholder=`$t("Введите вашу фамилию")`
                rules="required|alpha"
              )
            +b.g-cell.g-cols--12.--6-sm.ec-space_mt--2
              DController(
                :name="'whatsappLink'"
                v-model="formdata.whatsappLink"
                :placeholder=`$t("https://wa.me/number")`
                :errors='errors'
                :static-label=`$t("Ссылка на Whatsapp")`
                rules="isWhatsApp"
              )            
            +b.g-cell.g-cols--12.--6-sm.ec-space_mt--2
              DController(
                :name="'email'"
                v-model="formdata.email"
                :errors='errors'
                :static-label=`$t("E-mail")`
                :placeholder=`$t("Введите вашу почту")`
                :type="'email'"
                rules="required|email"
              )
            +b.g-cell.g-cols--12.--6-sm.ec-space_mt--2
              DController(
                :name="'phoneNumber'"
                v-model="formdata.phoneNumber"
                :errors='errors'
                :static-label=`$t("Телефон")`
                readonly
              )
            +b.g-cell.g-cols--12.--6-sm.ec-space_mt--2
              DController(
                :class="'d-control-descriptor--h_default'"
                :component="'Multiselect'"
                :name="'country'"
                :searchable="true"
                :allow-empty='false'
                :static-label=`$t("Страна")`
                :options="options.countries"
                :placeholder=`$t("Выбор")`
                v-model="formdata.country"
                track-by="slug"
                :labelBy="'label'"
                rules="required"
                @update:model-value="updateAfterSearch($event, 'country')"
              )
                template(#noResult)
            +b.g-cell.g-cols--12.--6-sm.ec-space_mt--2
              DController(
                :class="'d-control-descriptor--h_default'"
                :component="'Multiselect'"
                :name="'studyTarget'"
                :static-label=`$t("Цель изучения языка")`
                :placeholder=`$t("Выбор")`
                :options="options.studyTarget"
                v-model="formdata.studyTarget"
                track-by="slug"
                :labelBy="'label'"
              )
            +b.g-cell.g-cols--12.--6-sm.ec-space_mt--2
              DController(
                :name="'age'"
                v-model="formdata.age"
                :errors='errors'
                :static-label=`$t("Возраст")`
                :placeholder=`$t("Введите ваш возраст")`
                :type="'number'"
                rules="minValue:1"
                v-access="'[0-9]'"
                min="1"
              )
            template(v-for="(item, i) in formdata.languages")
              +b.g-cell.g-cols--12.--6-sm.ec-space_mt--2
                DController(
                  :class="'d-control-descriptor--h_default'"
                  :component="'Multiselect'"
                  :name="`language${i}_${item.orderModel || i}`"
                  :static-label=`$t("Язык")`
                  :placeholder=`$t("Выбор")`
                  :options="item.language ? [...languageList, item.language] : languageList"
                  :not-show-req="true"
                  v-model="item.language"
                  :key="`language${i}_${item.orderModel || i}`",
                  track-by="slug"
                  :labelBy="'label'"
                  :rules="item.levelPossession ? 'required': ''"
                )
              +b.g-cell.g-cols--12.--6-sm.ec-space_mt--2
                DController(
                  :class="'d-control-descriptor--h_default'"
                  :component="'Multiselect'"
                  :name="`levelPossession${i}_${item.orderModel || i}`"
                  :static-label=`$t("Уровень владения")`
                  :placeholder=`$t("Выбор")`
                  :options="options.levelsStudent.levelPossession"
                  :not-show-req="true"
                  v-model="item.levelPossession"
                  :key="`levelPossession${i}_${item.orderModel || i}`",
                  track-by="slug"
                  :labelBy="'label'"
                  :rules="item.language ? 'required': ''"
                )
                +b.form-action-btn.--end.--pink.inline.ec-space_mt--2(
                  @click.prevent="removeFields(i)"
                  v-if="i !== 0"  
                )
                  +b.svg-image.icons.--w_md.--center.--cursor_auto.ec-space_mr--1.ec-space_ml--1
                    i-cancel-ring(class="svg-image__element")
                  +b.P.ds-caption.--size_sm.--color_inherit.--regular {{ $t('Удалить') }}

                +b.form-action-btn.--end.--blue.inline.ec-space_mt--2(
                  @click.prevent="addFields()"
                  v-if="formdata.languages.length === i + 1 && formdata.languages.length < options.levelsStudent.language.length"
                )
                  +b.svg-image.icons.--w_md.--center.--cursor_auto.ec-space_mr--1.ec-space_ml--1
                    i-add-ring(class="svg-image__element")
                  +b.P.ds-caption.--size_sm.--color_inherit.--regular {{ $t('Добавить еще язык') }}

            +b.g-cell.g-cols--12.--6-sm.--narrow-md.ec-space_mt--7
              +b.BUTTON.link-button.--style_main.--color_pink.--hover_blue.--full-size(
                :disabled='isLoad'
                type='submit'
              )
                +b.SPAN.ds-caption.--color_main-white.--size_sm.--size_md-lg.--semibold {{ $t('Сохранить настройки') }}
            +b.g-cell.g-cols--12.--6-sm.--narrow-md.ec-space_mt--7
              +b.BUTTON.link-button.--style_main.--br_pink.--hover_pink.--full-size(
                @click.prevent="useModalOpener({ component: 'Forms/ChangePassword' })"
              )
                +b.SPAN.ds-caption.--color_inherit.--size_sm.--size_md-lg.--semibold {{ $t('Изменить пароль') }}

      +b.g-cell.g-cols--12.--3-xl
        avatar-info(
          :avatar="formdata.avatar"
          @avatar:update="formdata.avatar = $event, request()"
        )

</template>

<script setup>
/* eslint-disable */
import { ref, reactive, onMounted, nextTick, computed } from 'vue'
import {
  profileResource,
  profileUpdate,
  profileChoicesResource,
} from '@services/student.service'
import { clone } from 'ramda'
import { useModalOpener } from '@/composables/useModalOpener'
import { useSubmit } from '@/components/Forms/FormMixin'
import useMethods from '../Common/utils'

const {
  prepareData,
  prepareUpdatedData,
} = useMethods()

const isLoad = ref(false)
const orderModel = ref(10)
const initialLanguages = ref([])

let formdata = reactive({
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  whatsappLink: '',
  country: [],
  studyTarget: [],
  age: '',
  languages: [
    {
      language: null,
      levelPossession: null,
    },
  ],
})

let options = reactive({
  countries: [],
  studyTarget: [],
  levelsStudent: {
    language: [],
    levelPossession: [],    
  },
})

const request = (sendData, control) => {
  isLoad.value = true
  useSubmit(send(sendData), control)
}

const send = async() => {
  const clonedData = clone(formdata)
  let sendData = await prepareData(clonedData)
  if (sendData?.languages) {
    sendData = prepareUpdatedData(sendData, initialLanguages.value)
  }
  try { 
    await profileUpdate.execute({}, sendData)
    window.location.reload()
    isLoad.value = false
  } catch (e) {
    isLoad.value = false
    return Promise.reject(e)
  }
}

const updateAfterSearch = async (val, key) => {
  if (val?.slug) {
    await nextTick()
    formdata[key] = val
  } else {
    formdata[key] = []
  }
}

const addFields = () => {
  formdata.languages.push({ language: null, levelPossession: null, orderModel: orderModel.value++ })
}
const selectedLanguages = computed(() => {
  return (formdata.languages || []).map(el => el?.language?.slug || false)
})

const languageList = computed(() => {
  return options.levelsStudent.language.filter(({ slug }) => !((selectedLanguages.value || []).includes(slug)))
})

const removeFields = async(i) => {
  await nextTick()
  formdata.languages.splice(i, 1)
}

const getChoices = async() => {
  try {
    const { data: { item } } = (await profileChoicesResource.execute({}))
    await nextTick()
    Object.assign(options, { ...item })
  } catch (e) {
    console.error(e, 'Error')
  }
}

const getProfileInfo = async() => {
  try {
    const { data: { item } } = (await profileResource.execute({}))
    await nextTick()
    initialLanguages.value = clone(item.languages)
    if (item.languages.length) {
      Object.assign(formdata, { ...item })
    } else {
      Object.assign(formdata, {
        ...item,
        languages: [
          {
            language: null,
            levelPossession: null,
          },
        ]
      })      
    }
  } catch (e) {
    console.error(e, 'Error')
  }
}

onMounted(async() => {
  await getChoices()
  getProfileInfo()
})

</script>
