import { App } from 'vue'

import SettingsView from './AccountSettings.vue'
import StudentBalanceView from './BalanceView.vue'
import StudentActivePackages from './ActivePackages.vue'
import StudentPastMeetings from './PastMeetings.vue'
import StudentFutureMeetings from './FutureMeetings.vue'
import StudentView from './View.vue'

const register = (app: App<Element>): void => {
  app
    .component('SettingsView', SettingsView)
    .component('StudentView', StudentView)
    .component('StudentPastMeetings', StudentPastMeetings)
    .component('StudentFutureMeetings', StudentFutureMeetings)
    .component('StudentBalanceView', StudentBalanceView)
    .component('StudentActivePackages', StudentActivePackages)
  }

export default {
  register,
}
