import {
  createReceiver,
  createSender,
} from '@resource/resource'

import {
  prefixAPI,
} from '@resource/api'

const MODEL = 'withdrawal'


const METHODS_LIST_URL = prefixAPI('/method/list/', MODEL)
export const methodResource = createReceiver(METHODS_LIST_URL)

const WITHDRAWAL_OF_FUNDS_URL = prefixAPI('/create/', MODEL)
export const withdrawalOfFunds = createSender(WITHDRAWAL_OF_FUNDS_URL)
