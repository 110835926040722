<template lang="pug">
include /mixins.pug

+b.BUTTON.del-btn(
  @click.prevent='deleteRequest'
  v-bind="$attrs"
)
  +b.SPAN.svg-image.icons.--w_md.--center
    i-trash(class="svg-image__element")

</template>

<script setup>
import { defineProps } from 'vue'
import {
  summaryDelete,
  certificateDelete,  
} from '@services/teacher.service'

/* eslint-disable */
const props = defineProps({
  id: Number,
  modelName: String,
})

const deleteRequest = async() => {
  const { id, modelName } = props
  const methods = {
    summary: summaryDelete,
    certificate: certificateDelete,
  }
  try {
    await methods[modelName].execute({ id })
    window.location.reload()
  } catch (e) {
    console.error(e, 'Error')
  }
}

</script>
