<template>
<svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_3_44171)">
<path d="M11.8549 0.985056L6.40791 7.80286C6.35427 7.86497 6.28863 7.91465 6.21526 7.94866C6.1419 7.98267 6.06245 8.00024 5.98211 8.00024C5.90177 8.00024 5.82232 7.98267 5.74896 7.94866C5.67559 7.91465 5.60995 7.86497 5.55631 7.80286L0.143589 0.986931C0.0699743 0.901709 0.0217327 0.796152 0.0047067 0.683044C-0.0123193 0.569935 0.00260001 0.454126 0.0476592 0.349643C0.0927184 0.245159 0.165984 0.156482 0.258583 0.0943509C0.351182 0.03222 0.459143 -0.000699942 0.569391 -0.000422432L11.4309 -0.000423382C11.5407 -1.71261e-05 11.648 0.0332737 11.7399 0.0954533C11.8319 0.157633 11.9047 0.246058 11.9494 0.350112C11.9942 0.454167 12.0091 0.569427 11.9923 0.682057C11.9756 0.794686 11.9278 0.899897 11.8549 0.985056Z" fill="#595959"/>
</g>
<defs>
<clipPath id="clip0_3_44171">
<rect width="12" height="8" fill="white" transform="translate(12 8) rotate(180)"/>
</clipPath>
</defs>
</svg>
</template>
