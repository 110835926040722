/* eslint-disable */
import { ref } from 'vue'
import useGlobal from '@/composables/useGlobal'

export default function () {
  const { $t } = useGlobal()

  const getTimeOptions = () => {
    let timeOptions = [{ label: '00:00', slug: '00:00:00' }]

    const time = new Date()
    time.setMinutes(0)
    time.setHours(0)
    time.setSeconds(0)
    let timeAfter30Mins = new Date(time)

    for(let i = 0; i < 47; i++) {
      timeAfter30Mins = new Date(timeAfter30Mins.setMinutes(timeAfter30Mins.getMinutes() + 30))
      const [h, min] = timeAfter30Mins.toLocaleTimeString('uk-UA').split(':')
      timeOptions.push({ label: `${h}:${min}`, slug: `${h}:${min}:00` })
    }

    return timeOptions
  }

  const weekTitles = day => {
    const week = [
      $t('Понедельник'),
      $t('Вторник'),
      $t('Среда'),
      $t('Четверг'),
      $t('Пятница'),
      $t('Суббота'),
      $t('Воскресенье'),
    ]
    return week[day]
  }

  return {
    getTimeOptions,
    weekTitles,
  }
}
