<template lang="pug">
include /mixins.pug


+b.info-block(v-if="list.items.length")
  +e.title
    +b.P.ds-caption.--size_2md.--size_4md-sm.--color_main-dark.--medium {{ $t('Мои пакеты занятий') }}
  +e.content.relative
    +b.preloader.--white.--full-block(v-if='isLoad')
    +b.g-row
      +b.g-cell.g-cols--12.--6-sm.--12-md.--6-xl(
        v-for="{ firstName, lastName, avatar, id, teacherId, quantityMeetLeft } in list.items"
      )
        +b.meeting-card.--variant_new
          +e.wrapper
            +e.elem.--variant_1
              +e.img-wrapper.ec-space_mb--2
                +b.sized-image
                  +e.IMG.img(
                    :src="avatar ? avatar: '/static/app/default.png'"
                  )
              +e.elem.--variant_4
                +b.ec-space_mb--2
                  +b.P.ds-caption.--size_sm.--size_3md-md.--color_main-dark.--semibold {{ firstName }} {{ lastName }}
                +b.ec-space_mb--2
                  +b.P.ds-caption.--size_sm.--size_3md-md.--color_main-dark.--semibold {{ $t('Кол-во оставшихся занятий:') }}
                    +b.SPAN.ds-caption.--size_sm.--size_3md-md.--color_main-dark.--regular.ec-space_mr--1.ec-space_ml--1 {{ quantityMeetLeft }}
            +e.elem.--variant_3.ec-space_mt--5-till-sm
              +b.ec-space_mb--2.is-visible-sm
                +b.P.ds-caption.--size_sm.--size_3md-md.--color_main-dark.--semibold {{ firstName }} {{ lastName }}
              +b.ec-space_mb--2.is-visible-sm
                +b.P.ds-caption.--size_sm.--size_3md-md.--color_main-dark.--semibold {{ $t('Кол-во оставшихся занятий:') }}
                  +b.SPAN.ds-caption.--size_sm.--size_3md-md.--color_main-dark.--regular.ec-space_mr--1.ec-space_ml--1 {{ quantityMeetLeft }}
              +b.g-row.--align_center.--appearance_spaced
                +b.g-cell.g-cols--12.--narrow-sm
                  +b.BUTTON.link-button.--style_main.--color_pink.--hover_blue.--full-size.ec-space_mt--7-sm(
                    @click.prevent="topUpPackages(teacherId)"
                  )
                    +b.P.ds-caption.--color_main-white.--size_sm.--size_md-lg.--semibold {{ $t('Пополнить') }}
                +b.g-cell.g-cols--12.--narrow-sm
                  modal-trigger(
                    component="Steps/StepOne"
                    :info="{ id: teacherId }"
                    v-slot="{ open }"
                  )
                    +b.BUTTON.link-button.--style_main.--br_pink.--hover_pink.--full-size.ec-space_mt--7-sm(@click.prevent='open()')
                      +b.SPAN.ds-caption.--color_inherit.--size_sm.--size_md-lg.--semibold {{ $t('Забронировать') }}

</template>

<script setup>
/* eslint-disable */
import { ref, onMounted } from 'vue'
import { packagesResource } from '@services/student.service'
import { getCompleteUrl } from '@utils/helpers.js'

const isLoad = ref(null)
const list = ref({
  items: [],
})

onMounted(() => {
  getList({})
})

const getList = async (params) => {
  try {
    if (!isLoad.value || isLoad.value == null) {
      isLoad.value = true
    }
    const { data } = (await packagesResource.execute(params))
    list.value = data
    isLoad.value = false
  } catch (e) {
    console.error(e, 'Error')
    isLoad.value = false
  }
}

const topUpPackages = teacher_id => {
  const url = getCompleteUrl('/packages/filters/', { teacher_id })
  window.location.assign(url)
}

</script>
