import { App } from 'vue'

import TeacherInfo from './Elements/TeacherInfo.vue'

import PackagesController from './PackagesController.vue'
// import FormPayment from './FormPayment.vue'
// import StepOne from './StepOne.vue'
import StepTwo from './StepTwo.vue'

const register = (app: App<Element>): void => {
  app
    .component('TeacherInfo', TeacherInfo)

    .component('PackagesController', PackagesController)
    // .component('FormPayment', FormPayment)
    // .component('StepOne', StepOne)
    .component('StepTwo', StepTwo)
  }

export default {
  register,
}
