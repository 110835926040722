/* eslint-disable */
export const access = {
  beforeMount: (el: any, binding: any) => {
    el.addEventListener('keypress', (e: any) => {
      const string = String.fromCharCode(e.which)
      const regex = new RegExp(binding.value)
      if (!string.match(regex)) {
        e.preventDefault()
      }
    })
  }
}
