<template lang="pug">
include /mixins.pug
my-upload(
  v-model='show'
  @crop-success='cropSuccess'
  @src-file-set="srcFileSet"
  v-bind="options"
  :lang-ext="langDictionary"
  :no-square='true'
  :max-size="maxSize"
  :lang-type="langType"
  :img-format="imageFormat"
  :max-size-display="maxSizeDisplay"
)

</template>

<script setup>
/* eslint-disable */
import myUpload from 'vue-image-crop-upload'
import { ref, defineProps, defineEmits } from 'vue'
import useGlobal from '@/composables/useGlobal'

const { $t } = useGlobal()

const emit = defineEmits(['crop-success', 'close'])

const props = defineProps({
  options: {},
  maxSize: {
    default: 5120,
  },
  maxSizeDisplay: {
    default: 5,
  },
})

const show = ref(true)
const imgDataUrl = ref('')
const imageFormat = ref('.png')
const langType = window.language

const cropSuccess = (imageDataUrl, field) => {
  // console.log(imageDataUrl, field, 'cropSuccess')
  emit('crop-success', imageDataUrl)
}

const srcFileSet = (fileName, fileType) => {
  // console.log(fileName, fileType, 'fileName, fileType')
  const [, imgFormat] = fileType.split('/')
  imageFormat.value = imgFormat
}

const langDictionary = {
  hint: $t('Click or drag the file here to upload'),
  loading: $t('Uploading…'),
  noSupported: $t('Browser is not supported, please use IE10+ or other browsers'),
  success: $t('Upload success'),
  fail: $t('Upload failed'),
  preview: $t('Preview'),
  btn: {
    off: $t('Cancel'),
    close: $t('Close'),
    back: $t('Back'),
    save: $t('Save'),
  },
  error: {
    onlyImg: $t('Image only'),
    outOfSize: $t('Image exceeds size limit: '),
    lowestPx: $t('Image\'s size is too low. Expected at least: '),
  }
}

</script>
