<template lang="pug">
include /mixins.pug

div(ref='topOfContent')
  +b.info-block(v-if="list.items.length")
    +e.title
      +b.P.ds-caption.--size_2md.--size_4md-sm.--color_main-dark.--medium {{ $t('Мои будущие встречи') }}
    +e.content.relative
      +b.preloader.--white.--full-block(v-if='isLoad')
      +b.meeting-card.--variant_new(
        v-for="{ firstName, lastName, avatar, dateSlot, whatsappLink, linkZoom, lessonTopic, id, teacherId, isFreeLesson, teacherLink } in list.items"
      )
        +b.inline.--ai_center.ec-space_mb--5
          +e.icon.svg-image.icons.--w_md.--center.--cursor_auto
            i-clock(class="svg-image__element")
          +b.P.ds-caption.--size_md.--size_3md-md.--color_main-blue.-regular {{ getDateLabel(dateSlot) }}
        +e.wrapper
          +e.elem.--variant_1.relative
            +e.A.link(
              :href="teacherLink"
            )
            +e.img-wrapper.ec-space_mb--2
              +b.sized-image
                +e.IMG.img(
                  :src="avatar ? avatar: '/static/app/default.png'"
                )
            +e.elem.--variant_2
              +e.P.link-title {{ firstName }} {{ lastName }}
          +e.elem.--variant_3
            +b.ec-space_mb--2(v-if="lessonTopic")
              +b.P.ds-caption.--size_sm.--size_3md-md.--color_main-dark.--semibold {{ $t('Тема урока: ') }}
                +b.SPAN.ds-caption.--size_sm.--size_3md-md.--color_main-dark.--regular.ec-space_mr--1.ec-space_ml--1 {{ lessonTopic }}        
            +b.ec-space_mb--2(v-if="linkZoom")
              +b.SPAN.meeting-card__items-title {{ $t('Ссылка зум на встречу:') }}
              +b.SPAN.meeting-card__items-val
                +b.A.ds-link.--tr.--styling_default.is-hover(
                  :href="linkZoom"
                )
                  +b.SPAN.ds-caption.--color_inherit {{ linkZoom }}
            +b.g-row.--align_center
              +b.g-cell.g-cols--12.--narrow-sm
                +b.A.link-button.--style_main.--color_pink.--hover_blue.--full-size.ec-space_mt--4.--7-sm(
                  :href="whatsappLink"
                  target="_blank"
                  rel="noopener noreferrer"
                )
                  +b.SPAN.ds-caption.--color_main-white.--size_sm.--size_md-lg.--semibold {{ $t('Связаться с преподавателем') }}
              +b.g-cell.g-cols--12.--narrow-sm
                +b.BUTTON.link-button.--style_main.--br_pink.--hover_pink.--full-size.ec-space_mt--4.--7-sm(
                  @click.prevent="transferLesson({ id: teacherId, idMeetLesson: id, dateSlot, isFree: isFreeLesson ? 'free': 'paid' })"
                )
                  +b.SPAN.ds-caption.--color_inherit.--size_sm.--size_md-lg.--semibold {{ $t('Перенести встречу') }}
            +b.ec-space_mt--5
              +b.P.ds-caption--size_sm.--color_main-dark.--regular.form-action-btn(@click.prevent="cancelMeeting(id)")
                +b.ds-link.--tr.--styling_default.is-hover
                  +b.SPAN.ds-caption.--color_inherit.--appearance_underline.ec-space_ml--2.ec-space_mr--2 {{ $t('Отменить встречу') }}

      ui-limit-pagination(
        :pagination='list.pagination'
        @input='handlePaginate'
        v-if="list.pagination.total > list.pagination.limit"
      )

</template>

<script setup>
/* eslint-disable */
import { ref, onMounted } from 'vue'
import { futureMeetingResource } from '@services/student.service'
import {
  lessonCancelResource,
  checkSanctionResource,
} from '@services/lesson.service'
import useMethods from '../Common/utils'
import {
  useModalOpener,
  useConfirmWithPromise,
} from '@/composables/useModalOpener'
import useGlobal from '@/composables/useGlobal'

const { $t } = useGlobal()
const { getDateLabel } = useMethods()

const isLoad = ref(null)
const topOfContent = ref(null)
const paginate = ref(false)
const list = ref({
  items: [],
  pagination: {
    total: {},
    limit: {},
    offset: {},
  }
})

onMounted(() => {
  getList({})
})

const getList = async (params) => {
  try {
    if (!isLoad.value || isLoad.value == null) {
      isLoad.value = true
    }
    const { data } = (await futureMeetingResource.execute(params))
    if (paginate.value) {
      topOfContent.value.scrollIntoView({ block: 'start', behavior: 'smooth' })
    }
    list.value = data
    isLoad.value = false
    paginate.value = false
  } catch (e) {
    console.error(e, 'Error')
    isLoad.value = false
    paginate.value = false
  }
}

const checkSanction = async idMeetLesson => {
  const {
    data: {
      item: {
        isCheck,
        messageText,
        sanctionHours,
      },
    },
  } = (await checkSanctionResource.execute({}, { idMeetLesson }))
  return { isCheck, messageText, sanctionHours }
}

const cancelMeeting = async idMeetLesson => {
  try {
    const { isCheck, messageText } = await checkSanction(idMeetLesson)

    let info = {
      title: $t('Отмена урока'),
      text: $t('Вы действительно хотите отменить урок?'),
    }

    if (isCheck) {
      info = {
        ...info, 
        additionalInfo: messageText,
      }
    }

    await useConfirmWithPromise(info)

    await lessonCancelResource.execute({}, { idMeetLesson })

    useModalOpener({
      component: 'UiElements/Modals/UiMessageModal',
      message: {
        title: $t('Урок отменен'),
        icon: 'i-checked',
      },
      reload: true,
    })
  } catch(e) {
    console.error(e)
  }
}

const transferLesson = async ({ id, idMeetLesson, dateSlot, isFree }) => {
  try {
    const { isCheck, sanctionHours } = await checkSanction(idMeetLesson)
    const prefix = $t('Минимальное количество часов до начала встречи, при котором доступна отмена')
    const messageText = `${prefix} - ${sanctionHours}`
    if (isCheck) {
      useModalOpener({
        component: 'UiElements/Modals/UiMessageModal',
        message: {
          title: $t('Возможна только отмена!'),
          text: messageText,
          icon: 'i-emoticon',
        },
      })
    } else {
      useModalOpener({
        component: 'Steps/StepOne',
        info: {
          id,
          idMeetLesson,
          dateSlot,
          isFree,
        },
      })
    }
  } catch (e) {
    console.error(e)
  }
}

const handlePaginate = page => {
  paginate.value = true
  getList(page)
}

</script>
