<template lang="pug">
include /mixins.pug

+b.info-block
  +e.title
    +b.P.ds-caption.--size_2md.--size_4md-sm.--color_main-dark.--medium {{ $t('Мое видео') }}
  +e.content
    +b.g-row.--appearance_spaced.--space_1xl-xl
      +b.g-cell.g-cols--12.--6-lg
        modal-trigger(
          component="UiElements/Modals/Video"
          :videourl="videoInfo.previewVideoLink"
          v-slot="{ open }"
          v-if="videoInfo.previewVideoImage"
        )
          +b.teacher-profile__video-wrapper.video-preview(@click.prevent='open()')
            +b.sized-image
              +e.IMG.img(
                :src="videoInfo.previewVideoImage"
                :alt=`$t("Video preview")`
              )
            +b.video-preview__icon
              i-play

      +b.g-cell.g-cols--12
        modal-trigger(
          component="Profiles/Modals/AddVideo"
          :info="videoInfo"
          v-slot="{ open }"
        )
          +b.BUTTON.link-button.--style_main.--color_blue.--hover_pink(@click.prevent='open()' :disabled="isCanNotUpdate")
            +b.P.ds-caption.--color_main-white.--size_sm.--size_md-lg.--semibold  {{ $t('Добавить/ редактировать') }}

</template>

<script setup>
/* eslint-disable */
import { defineProps, reactive, onMounted } from 'vue'
import { videoResource } from '@services/teacher.service'
import { getId } from '@utils/helpers.js'

const props = defineProps({
  isCanNotUpdate: Boolean,
})

let videoInfo = reactive({
  previewVideoImage: '',
  previewVideoLink: '',
})

onMounted(() => {
  getVideoInfo()
})
 
const setInitial = val => {
  if (val?.previewVideoImage) {
    videoInfo.previewVideoLink = val.previewVideoLink
    videoInfo.previewVideoImage = val.previewVideoImage
  } else if (val?.previewVideoLink) {
    videoInfo.previewVideoLink = val.previewVideoLink
    videoInfo.previewVideoImage = `https://img.youtube.com/vi/${getId(val.previewVideoLink)}/0.jpg`
  }
}

const getVideoInfo = async() => {
  try {
    const { data: { item } } = (await videoResource.execute({}))
    setInitial(item)
  } catch (e) {
    console.error(e, 'Error')
  }
}

</script>
