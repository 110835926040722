<template lang="pug">
include /mixins.pug
+b.share
  template(v-for="network in networks")
    +e.SHARE-NETWORK.link(
      tag='div'
      :url="shareUrl"
      :network='network.network'
      :title="shareTitle"
    )
      +e.icon
        component(
          :is='network.icon'
        )

  +e.link.relative(
    v-clipboard:copy="shareUrl"
    v-clipboard:success="onCopy"
  )
    +e.icon
      i-share-link
    +e.prompt(
      v-if="copy"
    )
      +b.SPAN {{ $t('Copy') }}

</template>

<script setup>
/* eslint-disable */
import { defineProps, ref } from 'vue'
import {
  attributesResource,
} from '@services/blog.services'

const props = defineProps({
  shareUrl: String,
  shareTitle: String,
})

const copy = ref(false)

const networks = ref(null)

const onCopy = () => {
  copy.value = true
  setTimeout(hide, 1500)
}
const hide = () => {
  copy.value = false
}


const getAttributes = async () => {
  const { data: { item } } = (await attributesResource.execute({ url: 'social-networks' }))
  networks.value = { ...item }
}
getAttributes()
</script>
