/* eslint-disable */
import { ref } from 'vue'
import { timeZonesResource } from '@services/schedulers.service'
import useGlobal from '@/composables/useGlobal'

export default function () {
  const { $t } = useGlobal()

  const dStart = ref('')
  const dEnd = ref('')

  const rolesLegend = {
    student: [
      { color: 'is-green', title: $t('будущие уроки') },
      { color: 'is-dark-blue', title: $t('прошлые уроки') },
    ],
    teacher: [
      { color: 'is-blue', title: $t('готов преподавать') },
      { color: 'is-green', title: $t('забронированный урок') },
      { color: 'is-dark-blue', title: $t('проведенный урок') },
    ],
  }

  const statusesLegend = {
    inaccessible: '',
    free: 'is-blue',
    future_lesson: 'is-green',
    past_lesson: 'is-dark-blue',
  }

  const publickStatusesLegend = {
    inaccessible: '',
    free: 'is-blue',
    future_lesson: '',
    past_lesson: '',
  }

  const getWeekDay = (isoString, smallView) => {
    const mac = 1440
    let days = []
    if (window.innerWidth >= mac && !smallView) {
      days = [
        $t('Воскресенье'),
        $t('Понедельник'),
        $t('Вторник'),
        $t('Среда'),
        $t('Четверг'),
        $t('Пятница'),
        $t('Суббота'),
      ]
    } else {
      days = [
        $t('Вс'),
        $t('Пн'),
        $t('Вт'),
        $t('Ср'),
        $t('Чт'),
        $t('Пт'),
        $t('Сб'),
      ]
    }
    const d = new Date(isoString)
    const n = d.getDay()
  
    return days[n]
  }

  const getDateView = isoString => {
    const d = new Date(isoString)
    const n = d.getDate()
    return n
  }

  const reverseDate = value => {
    const [date, month, year] = value.split('.')
    value = `${year}-${month}-${date}`
    return value
  }

  const getWeekRange = params => {
    dStart.value = new Date(reverseDate(params.filters.start_date))
    dEnd.value = new Date(reverseDate(params.filters.end_date))
    getWeekLabel()
  }

  const getWeekLabel = () => {
    const months = {
      '01':	$t('Jan.'),
      '02':	$t('Feb.'),
      '03':	$t('Mar.'),
      '04':	$t('Apr.'),
      '05': $t('May'),
      '06':	$t('Jun.'),
      '07':	$t('Jul.'),
      '08':	$t('Aug.'),
      '09':	$t('Sep.'),
      '10': $t('Oct.'),
      '11': $t('Nov.'),
      '12': $t('Dec.'),
    }
  
    const [dayStart, mStart, yStart] = new Date(dStart.value).toLocaleDateString('uk-UA').split('.')
    const [dayEnd, mEnd, yEnd] = new Date(dEnd.value).toLocaleDateString('uk-UA').split('.')
  
    let string = ''
    let year = ''
  
    if (yStart === yEnd) {
      year = yStart
    } else {
      year = `${yStart}-${yEnd}`
    }
    if (mStart === mEnd) {
      string = `${dayStart} - ${dayEnd} ${months[mStart]} ${year}`
    } else {
      string = `${dayStart} ${months[mStart]} - ${dayEnd} ${months[mEnd]} ${year}`
    }

    return string
  }

  const converttime = (onetime, day, month, year) => {
    let D = new Date(onetime)
    let currDate = ''
    let currMonth = ''
    D.setDate(D.getDate() + day)
    D.setMonth(D.getMonth() + month)
    D.setFullYear(D.getFullYear() + year)
    if (D.getDate() < 10) {
      currDate = "0" + D.getDate()
    } else {
      currDate = D.getDate()
    }
    if (D.getMonth() < 9) {
      currMonth = D.getMonth() + 1
      currMonth = "0" + currMonth
    } else {
      currMonth = D.getMonth() + 1
    }
    const currYear = D.getFullYear()
    const newData = currDate + "." + currMonth + "." + currYear

    return newData
  }

  const getNavParams = direction => {
    let newStart = ''
    let newEnd = ''

    if ('next' === direction) {
      newStart = converttime(dStart.value, 1, 0, 0)
      newEnd = converttime(dStart.value, 7, 0, 0)
    } else if ('prev' === direction) {
      newStart = converttime(dStart.value, -1, 0, 0)
      newEnd = converttime(dStart.value, 5, 0, 0)
    }

    const newParams = {
      start_date: newStart,
      end_date: newEnd,
    }

    return newParams
  }

  const getTimeZones = async() => {
    try {
      const { data: { item: { tz } } } = (await timeZonesResource.execute({}))
      return tz || []
    } catch (e) {
      console.error(e, 'time zones error')
    }
  }

  return {
    rolesLegend,
    statusesLegend,
    publickStatusesLegend,
    getWeekDay,
    getWeekRange,
    getWeekLabel,
    getNavParams,
    getDateView,
    getTimeZones,
  }
}
