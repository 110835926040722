import {
  createReceiver,
  createSender,
  createUpdater,
} from '@resource/resource'

import {
  prefixAPI,
} from '@resource/api'

const MODEL = 'student'

// SLOT
const MODEL_SLOT = `${MODEL}/slot`

const FUTURE_LIST_URL = prefixAPI('/future/{?limit,offset}', MODEL_SLOT)
export const futureMeetingResource = createReceiver(FUTURE_LIST_URL)

const PAST_LIST_URL = prefixAPI('/past/{?limit,offset}', MODEL_SLOT)
export const pastMeetingResource = createReceiver(PAST_LIST_URL)

// PROFILE
const MODEL_PROFILE = `${MODEL}/profile`

const PROFILE_URL = prefixAPI('/retrieve/', MODEL_PROFILE)
export const profileResource = createReceiver(PROFILE_URL)

const PROFILE_UPDATE_URL = prefixAPI('/update/', MODEL_PROFILE)
export const profileUpdate = createUpdater(PROFILE_UPDATE_URL)

const PROFILE_CHOICES_URL = prefixAPI('/retrieve-select/', MODEL_PROFILE)
export const profileChoicesResource = createReceiver(PROFILE_CHOICES_URL)

// PROFILE TIME ZONE
const MODEL_TIME_ZONE = `${MODEL_PROFILE}/time-zone`

const TIME_ZONE_URL = prefixAPI('/retrieve/', MODEL_TIME_ZONE)
export const studentTimeZoneResource = createReceiver(TIME_ZONE_URL)

const TIME_ZONE_UPDATE_URL = prefixAPI('/update/', MODEL_TIME_ZONE)
export const studentTimeZoneUpdate = createUpdater(TIME_ZONE_UPDATE_URL)

// REVIEW
const MODEL_REVIEW = `${MODEL}/review`

const REVIEW_URL = prefixAPI('/create/',  MODEL_REVIEW)
export const reviewCreateResource = createSender(REVIEW_URL)

// PACKAGES
const MODEL_PACKAGES = `${MODEL}/package`

const PACKAGES_LIST_URL = prefixAPI('/list/', MODEL_PACKAGES)
export const packagesResource = createReceiver(PACKAGES_LIST_URL)

// BALANCE
const MODEL_BALANCE = `${MODEL}/balance`

const BALANCE_URL = prefixAPI('/retrieve/', MODEL_BALANCE)
export const balanceResource = createReceiver(BALANCE_URL)

const BALANCE_TOP_UP_URL = prefixAPI('/top-up/',  MODEL_BALANCE)
export const balanceTopUpResource = createSender(BALANCE_TOP_UP_URL)

const BALANCE_GET_COURSE_URL = prefixAPI('/min-top-up/', MODEL_BALANCE)
export const courseResource = createReceiver(BALANCE_GET_COURSE_URL)
