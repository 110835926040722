<template lang="pug">
include /mixins.pug

+b.confirm__element
  +b.P.ds-caption.--size_3md.--size_lg-xl.--color_main-dark.--semibold.--appearance_center {{ $t('Установите новый пароль') }}

  VeeForm(v-slot="{ errors }", @submit="request")
    +b.g-row.--appearance_spaced.ec-space_mt--2.--5-xl
      +b.g-cell.g-cols--12.ec-space_mt--2
        DController(
          :class="'d-control-descriptor--bg_grey'"
          :name="'password'"
          :errors='errors'
          :static-label=`$t("Пароль")`
          :placeholder=`$t("Создайте ваш пароль")`
          :type="'password'"
          rules="required|min:8"
        )
      +b.g-cell.g-cols--12.ec-space_mt--2
        DController(
          :class="'d-control-descriptor--bg_grey'"
          :name="'passwordConfirm'"
          :errors='errors'
          :static-label=`$t("Повторите пароль")`
          :placeholder=`$t("Повторите пароль")`
          :type="'password'"
          rules="required|confirmed:@password"
        )
      +b.g-cell.g-cols--12
        VeeError(name="nonFieldErrors" v-slot="{ message }"): +b.simple-errors {{ message }}
      +b.g-cell.g-cols--12.ec-space_mt--2
        +b.BUTTON.link-button.--style_main.--color_pink.--hover_blue.--full-size(
          :disabled='isLoad'
          type='submit'
        )
          +b.P.ds-caption.--color_main-white.--size_sm.--size_md-lg.--semibold  {{ $t('Сохранить') }}

</template>

<script setup>
import { defineProps, ref } from 'vue'
import { passwordConfirmCreate } from '@services/authenticate.service'
import { useSubmit } from './FormMixin'

/* eslint-disable */
const props = defineProps({
  confirmToken: String,
  id: String,
})

const isLoad = ref(false)

const request = (sendData, control) => {
  isLoad.value = true
  useSubmit(send(sendData), control)
}

const send = async sendData => {
  const prependedData = {
    ...sendData,
    userToken: props.confirmToken,
    idCript: props.id,
  }
  try { 
    const { data: { item: { redirect } } } = (await passwordConfirmCreate.execute({}, prependedData))

    window.location = redirect
    isLoad.value = false
  } catch (e) {
    isLoad.value = false
    return Promise.reject(e)
  }
}

</script>
