<svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_2_124)">
<path d="M11.7715 24.3091H19.2035V24.8263H11.7715V24.3091Z" fill="#FFCF55"/>
<path d="M19.0158 27.4126H11.9277C11.9819 27.5899 12.0501 27.7629 12.132 27.9298H18.8066C18.889 27.7626 18.9588 27.5896 19.0158 27.4126Z" fill="#FFCF55"/>
<path d="M15.7344 29.9907C16.6066 29.9324 17.4276 29.5677 18.0447 28.9641H12.8906C12.9098 28.9823 12.9261 29.002 12.9438 29.0194C13.6931 29.7099 14.7056 30.0624 15.7344 29.9907Z" fill="#FFCF55"/>
<path d="M19.2035 25.8608H11.7715V26.3781H19.1965C19.1986 26.3311 19.2035 25.8608 19.2035 25.8608Z" fill="#FFCF55"/>
<path d="M19.2051 22.8813C19.2101 21.7459 19.758 20.6776 20.6873 19.9922C23.4162 17.9359 24.5977 14.4753 23.6762 11.2361C22.7547 7.99667 19.9138 5.62221 16.4876 5.22773C16.1527 5.19086 15.816 5.17217 15.479 5.17192C13.395 5.16535 11.383 5.9139 9.83425 7.27236C7.9524 8.90811 6.91402 11.2742 7.00007 13.7298C7.08613 16.1855 8.28782 18.4772 10.2798 19.9841C11.2132 20.6715 11.7651 21.7431 11.7731 22.8831V23.2751H19.2051V22.8813ZM16.3082 7.28675C16.288 7.28675 16.2678 7.28574 16.2476 7.28372C15.9959 7.25544 15.7426 7.2413 15.4891 7.2413C15.196 7.2413 14.9583 7.00971 14.9583 6.72408C14.9583 6.43845 15.196 6.20686 15.4891 6.20686C15.7826 6.20787 16.076 6.2253 16.3676 6.25863C16.646 6.29071 16.8508 6.52835 16.8349 6.8011C16.8194 7.07386 16.5884 7.28776 16.3082 7.28928V7.28675ZM17.6385 7.03244C17.751 6.76853 18.0615 6.64351 18.3324 6.75312C20.8029 7.74614 22.5342 9.95694 22.8649 12.5408C22.8996 12.8244 22.692 13.082 22.4009 13.1158C22.1096 13.1499 21.8454 12.9474 21.8104 12.6638C21.5266 10.4502 20.0424 8.55631 17.9252 7.70674C17.6564 7.59714 17.5286 7.29711 17.6385 7.03446V7.03244Z" fill="#FFCF55"/>
<path d="M15.4889 3.10331C15.782 3.10331 16.0197 2.87172 16.0197 2.58609V0.517218C16.0197 0.231586 15.782 0 15.4889 0C15.1957 0 14.958 0.231586 14.958 0.517218V2.58609C14.958 2.87172 15.1957 3.10331 15.4889 3.10331Z" fill="#FFCF55"/>
<path d="M9.18969 4.36874C9.28378 4.53012 9.45952 4.63013 9.65004 4.63038C9.84055 4.63089 10.0166 4.53189 10.1114 4.37076C10.2063 4.20989 10.2055 4.01164 10.1091 3.85152L9.04738 2.05995C8.89989 1.81473 8.57666 1.73139 8.32394 1.87357C8.07147 2.01575 7.98386 2.33018 8.12798 2.57717L9.18969 4.36874Z" fill="#FFCF55"/>
<path d="M5.63901 7.82761L3.80019 6.79318C3.54643 6.65276 3.22397 6.73812 3.07804 6.98436C2.9321 7.23059 3.01764 7.54527 3.26933 7.68922L5.10815 8.72366C5.36192 8.86407 5.68437 8.77871 5.83031 8.53248C5.97624 8.28624 5.8907 7.97157 5.63901 7.82761Z" fill="#FFCF55"/>
<path d="M4.34042 13.9647C4.34042 13.6791 4.10272 13.4475 3.80956 13.4475H1.68613C1.39297 13.4475 1.15527 13.6791 1.15527 13.9647C1.15527 14.2504 1.39297 14.4819 1.68613 14.4819H3.80956C4.10272 14.4819 4.34042 14.2504 4.34042 13.9647Z" fill="#FFCF55"/>
<path d="M5.10737 19.2062L3.26854 20.2406C3.10291 20.3326 3.00052 20.5035 3 20.6892C2.99948 20.8748 3.10135 21.0465 3.26647 21.1389C3.43158 21.2314 3.63506 21.2304 3.7994 21.1367L5.63823 20.1022C5.80386 20.0103 5.90625 19.8393 5.90677 19.6537C5.90729 19.4681 5.80542 19.2964 5.6403 19.2039C5.47518 19.1115 5.27171 19.1125 5.10737 19.2062Z" fill="#FFCF55"/>
<path d="M27.7075 20.2406L25.8687 19.2062C25.7044 19.1125 25.5012 19.1115 25.3358 19.2039C25.1707 19.2964 25.0691 19.4681 25.0693 19.6537C25.0699 19.8393 25.1722 20.0103 25.3379 20.1022L27.1767 21.1367C27.4304 21.2771 27.7529 21.1917 27.8988 20.9455C28.0448 20.6993 27.9595 20.3846 27.7075 20.2406Z" fill="#FFCF55"/>
<path d="M29.291 13.4475H27.1676C26.8744 13.4475 26.6367 13.6791 26.6367 13.9647C26.6367 14.2504 26.8744 14.4819 27.1676 14.4819H29.291C29.5842 14.4819 29.8219 14.2504 29.8219 13.9647C29.8219 13.6791 29.5842 13.4475 29.291 13.4475Z" fill="#FFCF55"/>
<path d="M25.6021 8.79285C25.6955 8.79285 25.787 8.76886 25.8676 8.72365L27.7067 7.68922C27.9583 7.54527 28.0436 7.23059 27.8977 6.98436C27.7518 6.73812 27.4293 6.65276 27.1758 6.79318L25.3367 7.82761C25.1288 7.9448 25.0272 8.18345 25.0894 8.40948C25.1516 8.63551 25.3619 8.79285 25.6021 8.79285Z" fill="#FFCF55"/>
<path d="M21.0624 4.55815C21.3162 4.70109 21.641 4.61624 21.7874 4.36874L22.8491 2.57717C22.9935 2.33018 22.9059 2.01575 22.6532 1.87357C22.4005 1.73139 22.0772 1.81473 21.9297 2.05995L20.868 3.85152C20.7216 4.09902 20.8084 4.41521 21.0624 4.55815Z" fill="#FFCF55"/>
</g>
<defs>
<clipPath id="clip0_2_124">
<rect width="30.7912" height="30" fill="white" transform="translate(0.0917969)"/>
</clipPath>
</defs>
</svg>  