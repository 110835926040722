<script>
import { ref, onMounted, defineComponent } from 'vue'

export default defineComponent({
  name: 'ShowMoreTrigger',
  props: {
    contentHeight: Number,
  },
  setup(props, { slots }) {
    const icon = ref('i-arrow-down')
    const isShow = ref(false)
    const showBtn = ref(true)
    const root = ref(null)

    const showingToggle = () => {
      isShow.value = !isShow.value
      icon.value = isShow.value ? 'i-arrow-up' : 'i-arrow-down'
    }

    const calcHeight = () => {
      const el = root.value
      if (el) {
        const innerHeight = el.scrollHeight
        if (props.contentHeight > innerHeight) {
          showBtn.value = false
        } else {
          showBtn.value = true
        }
      }
    }

    onMounted(() => {
      calcHeight()
    })

    return () => slots.default({
      isShow: isShow.value,
      showBtn: showBtn.value,
      showingToggle: showingToggle,
      icon: icon.value,
      ref: root,
      recalc: () => calcHeight,
    })
  },
})
</script>
