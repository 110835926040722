<template lang="pug">
include /mixins.pug

+b.lesson-info.--variant_1
  +e.img-wrapper
    +b.sized-image
      +e.IMG.img(
        :src="userInfo?.avatar ? userInfo.avatar: '/static/app/default.png'"
      )
  +e.desc.--variant_1
    +b.P.ds-caption.--size_sm.--size_md-sm.--color_main-dark.--semibold.ec-space_mb--0.--2-sm {{ userInfo?.firstName }} {{  userInfo?.lastName}}

    +e.items.--flex.--center(v-if="userInfo?.subcategories.length")
      +e.icon
        i-globus(class="svg-icon")
      +e.item
        +b.P.ds-caption.--size_2xs.--size_sm-sm.--color_main-gray
          template(v-for="{ label }, index in userInfo?.subcategories")
            +b.SPAN {{ label }} {{ userInfo.subcategories.length !== index + 1 ? ', ': ' ' }}

    +e.price(v-if="!props.isFree")
      +b.SPAN.ds-caption.--size_2md.--color_dark-blue.--semibold {{ userInfo?.cost }}
      +b.SPAN.ds-caption.--size_2md.--color_dark-blue.--semibold {{ userInfo?.currency }}
      +b.SPAN.ds-caption.--size_sm.--color_main-dark.--regular.ec-space_mr--1.ec-space_ml--1 /
      +b.SPAN.ds-caption.--size_sm.--color_main-dark.--regular60 {{ $t('мин') }}

</template>

<script setup>
/* eslint-disable */
import { defineProps, ref, defineEmits } from 'vue'
import { infoResource } from '@services/teacher.service'

const props = defineProps({
  id: Number,
  isFree: Boolean,
})

const emit = defineEmits(['init'])

const userInfo = ref(null)

const getTeacherInfo = async() => {
  try {
    const { id } = props
    const { data: { item } } = (await infoResource.execute({ id }))
    userInfo.value = item
    emit('init')
  } catch (e) {
    console.error(e, 'Error')
  }
}

getTeacherInfo()

</script>
