<template lang="pug">
include /mixins.pug
+b.ui-multiswitch-wrapper
  Switcher(
    v-for="option in options"
    :checked="value.includes(option.slug)"
    @update:checked="check(option.slug, $event)"
    :fieldId="option.label"
    :label="option.label"
    :key="option"
  )

</template>

<script setup>
import { defineProps, defineEmits } from 'vue'

const props = defineProps({
  value: {
    type: Array,
    required: true,
  },
  options: {
    type: Array,
    required: true,
  },
  singleMod: {
    type: Boolean,
    default: false,
  }
})

const emit = defineEmits(['update:value'])

const check = (optionSlug, checked) => {
  let updatedValue = [...props.value]
  if (checked) {
    if (props.singleMod) {
      updatedValue = []
    }
    updatedValue.push(optionSlug)
  } else {
    updatedValue.splice(updatedValue.indexOf(optionSlug), 1)
  }
  emit("update:value", updatedValue)
}

props
check

</script>
