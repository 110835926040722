<template>
<svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_3_47139)">
<path d="M41.4369 32.1781C41.3278 32.1257 37.2452 30.1154 36.5198 29.8543C36.2236 29.7479 35.9063 29.6441 35.5689 29.6441C35.0176 29.6441 34.5545 29.9188 34.1938 30.4585C33.7859 31.0647 32.5514 32.5077 32.1699 32.9388C32.1201 32.9957 32.0521 33.0637 32.0114 33.0637C31.9749 33.0637 31.343 32.8036 31.1518 32.7205C26.7723 30.8181 23.4481 26.2434 22.9923 25.4719C22.9272 25.361 22.9245 25.3107 22.9239 25.3107C22.9399 25.2519 23.0872 25.1043 23.1632 25.0281C23.3856 24.8081 23.6265 24.5181 23.8596 24.2376C23.9699 24.1047 24.0805 23.9716 24.189 23.8461C24.5272 23.4527 24.6778 23.1472 24.8523 22.7934L24.9438 22.6096C25.3699 21.7628 25.0059 21.0483 24.8883 20.8176C24.7918 20.6245 23.0679 16.4641 22.8847 16.027C22.4439 14.9723 21.8616 14.4812 21.0523 14.4812C20.9772 14.4812 21.0523 14.4812 20.7374 14.4945C20.3539 14.5107 18.2658 14.7856 17.3425 15.3676C16.3634 15.9848 14.707 17.9525 14.707 21.4128C14.707 24.5272 16.6834 27.4677 17.5319 28.5861C17.553 28.6143 17.5918 28.6716 17.6479 28.7537C20.8976 33.4996 24.9487 37.0167 29.0554 38.6572C33.009 40.2365 34.8812 40.419 35.9456 40.419C35.9458 40.419 35.9458 40.419 35.9458 40.419C36.393 40.419 36.751 40.3839 37.0669 40.3528L37.2672 40.3337C38.633 40.2127 41.6345 38.6574 42.3172 36.7601C42.855 35.2657 42.9969 33.633 42.639 33.0405C42.394 32.6376 41.9716 32.4348 41.4369 32.1781Z" fill="#0BB1EF"/>
<path d="M28.497 0C13.3305 0 0.991594 12.2462 0.991594 27.2987C0.991594 32.1673 2.2945 36.9329 4.76269 41.1038L0.0385032 55.0393C-0.0494968 55.2991 0.0159578 55.5864 0.20814 55.782C0.346867 55.9236 0.535049 56 0.727231 56C0.800867 56 0.875049 55.9889 0.947412 55.9658L15.4783 51.3484C19.4547 53.4729 23.9489 54.5944 28.4972 54.5944C43.6623 54.5945 56 42.3496 56 27.2987C56 12.2462 43.6623 0 28.497 0ZM28.497 48.908C24.2172 48.908 20.072 47.6722 16.5087 45.334C16.3889 45.2553 16.2498 45.2149 16.1098 45.2149C16.0358 45.2149 15.9616 45.2262 15.8894 45.2491L8.61032 47.5629L10.9601 40.6304C11.0361 40.406 10.9981 40.1585 10.8581 39.9673C8.14469 36.2596 6.71032 31.8791 6.71032 27.2987C6.71032 15.3818 16.4838 5.68655 28.4969 5.68655C40.5085 5.68655 50.2809 15.3818 50.2809 27.2987C50.2811 39.2142 40.5089 48.908 28.497 48.908Z" fill="#FF3C8A"/>
</g>
<defs>
<clipPath id="clip0_3_47139">
<rect width="56" height="56" fill="white"/>
</clipPath>
</defs>
</svg>
</template>
