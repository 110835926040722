<template lang="pug">
include /mixins.pug
vue-final-modal(
  v-slot='{ close, params }'
)
  +b.modal-backdrop(@click="params?.clickToClose ? close(): ''")
    +b.modal-background
      component(
        :is="componentExstactor"
        :params='params'
        :close='close'
        :component-path='local'
        @hook:created='save(params)'

      )
</template>

<script setup>
import { ref, defineAsyncComponent } from 'vue'

const local = ref(null)
const componentExstactor = defineAsyncComponent(() => import(`@/components/UiElements/Modals/Wrapper`))

const save = ({ component }) => {
  local.value = component
}

</script>
