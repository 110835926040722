<template lang="pug">
include /mixins.pug

+b.modal(v-if="isChecked")
  button.close(type='button' @click="close")
    IClose
  +e.wrapper
    +e.body
      +b.P.ds-caption.--size_3md.--size_lg-xl.--color_main-dark.--semibold.--appearance_center.ec-space_mt--5-till-sm
        | {{ info.isFree ? $t('Подтверждение времени бесплатного урока'): $t('Подтверждение времени урока') }}

      +b.P.ds-caption.--size_4md.--size_lg-xl.--color_main-blue.--semibold.--appearance_center.ec-space_mt--2.--5-sm.ec-space_mb--4.--5-sm {{ currentTime }}

      teacher-info(
        :id="info.idTeacher"
        :is-free="info.isFree"
      )

      +b.P.ds-caption.--size_md.--size_3md-sm.--color_main-dark.--regular.--appearance_center.ec-space_mt--4.--5-sm {{ getLessonDateTime(info.dateTimeTz, info.isFree) }}
      +b.P.ds-caption.--size_md.--size_3md-sm.--color_main-dark.--regular.--appearance_center.ec-space_mb--4.--5-sm ({{ getTimeZone() }})

      +b.ec-space_mt--2
        +b.BUTTON.link-button.--style_main.--color_pink.--hover_blue.--full-size(
          :disabled='isLoad'
          @click.prevent="confirm"
        )
          +b.P.ds-caption.--color_main-white.--size_sm.--size_md-lg.--semibold  {{ $t('Подтвердить') }}

</template>

<script setup>
/* eslint-disable */
import { defineProps, ref, watch, onMounted } from 'vue'
import useTimerMethodth from '@utils/timerMethodth.js'
import useStepsMethodth from './utils.js'
import { useModalOpener } from '@/composables/useModalOpener'
import {
  lessonBuyResource,
  lessonFreeBuyResource,
  lessonMoveResource,
  lessonBookResource,
} from '@services/lesson.service'
import { useErrorsGetter } from '@/components/Forms/FormMixin'
import { getCompleteUrl } from '@utils/helpers.js'
import useGlobal from '@/composables/useGlobal'

const { $t } = useGlobal()

const props = defineProps({
  close: Function,
  info: Object,
})

const {
  getLessonDateTime,
  getTimeZone,  
} = useStepsMethodth()
const { convertTime } = useTimerMethodth()

const { dateSlot, idTeacher, isFree, idMeetLesson } = props.info

const isLoad = ref(null)
const isChecked = ref(false)
const zero = '00:00:00'
const defaultTime = ref(isFree ? window.defaultTimeFree: window.defaultTime)
const currentTime = ref(zero)
const timer = ref(null)

const startTimer = () => {
  timer.value = setInterval(() => {
    defaultTime.value--
    currentTime.value = convertTime((defaultTime.value).toFixed(0))
  }, 1000)
}
startTimer()

watch(() => currentTime.value, () => {
  if (currentTime.value === zero) {
    props.close()
    useModalOpener({
      component: 'UiElements/Modals/UiMessageModal',
      message: {
        title: $t('Time is up'),
        icon: 'i-emoticon',
      },
    })
  }
})

const confirm = async () => {
  try {
    if (!isLoad.value || isLoad.value == null) {
      isLoad.value = true
    }
    
    let method = lessonBuyResource
    let prependedData = {
      dateSlot,
      idTeacher,
    }
    if (isFree && !idMeetLesson) {
      method = lessonFreeBuyResource
    } else if (idMeetLesson) {
      method = lessonMoveResource
      prependedData = {
        dateSlot,
        idMeetLesson,
      }
    }
    await method.execute({}, prependedData)
    props.close()
    useModalOpener({
      component: 'UiElements/Modals/UiMessageModal',
      message: {
        title: $t('Успешная бронь!'),
        text: $t('Спасибо, урок забронирован.'),
        icon: 'i-thumb-up',
      },
      reload: true,
    })
    isLoad.value = false
  } catch (e) {
    isLoad.value = false
    console.error(e, 'Error')
    const { packageRedirect, nonFieldErrors } = await useErrorsGetter(e)
    props.close()
    if (packageRedirect) {
      const setFilters = {
        teacher_id: idTeacher,
        slot: dateSlot,
      }
      const url = getCompleteUrl('/packages/filters/', setFilters)
      window.location.assign(url)
    } else {
      useModalOpener({
        component: 'UiElements/Modals/UiMessageModal',
        message: {
          title: nonFieldErrors[0],
          icon: 'i-emoticon',
        },
      })
    }
  }
}

const checkBooking = async() => {
  try {
    const prependedData = {
      dateSlot,
      idTeacher,
      isFreeLesson: isFree,
    }
    await lessonBookResource.execute({}, prependedData)
    isChecked.value = true
  } catch (e) {
    const { nonFieldErrors } = await useErrorsGetter(e)
    props.close()
    useModalOpener({
      component: 'UiElements/Modals/UiMessageModal',
      message: {
        title: nonFieldErrors[0],
        icon: 'i-emoticon',
      },
    })
  }
}

checkBooking()

</script>
