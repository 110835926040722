<script >
import { ref, onMounted, onBeforeUnmount, defineComponent } from 'vue'

export default defineComponent({
  name: 'UiClickOutside',
  props: {
    do: {
      type: Function,
    },
  },
  setup(props, { slots }) {
    const root = ref(null)

    const listener = e => {
      if (e.target === root.value || root.value.contains(e.target)) {
        return
      }
      props.do()
    }

    onMounted(() => {
      document.addEventListener('click', listener, { passive: true })
    })
    onBeforeUnmount(() => {
      document.removeEventListener('click', listener);
    })
    return () => slots.default({ ref: root })
  },
})

</script>
