<script>
import { ref, defineComponent } from 'vue'

export default defineComponent({
  name: 'SidebarTrigger',
  setup(props, { slots }) {
    const sidebarIsOpen = ref(false)
    const { body } = document

    function setSidebarToggle() {
      sidebarIsOpen.value = !sidebarIsOpen.value
      body.classList.toggle('is-overflow')
    }

    function setSidebarTrigger(trigger) {
      sidebarIsOpen.value = trigger
      body.classList.toggle('is-overflow')
    }

    function closeSidebar() {
      sidebarIsOpen.value = false
      body.classList.remove('is-overflow')
    }

    return () => slots.default({
      sidebarIsOpen: sidebarIsOpen.value,
      sidebarClose: closeSidebar,
      sidebarTrigger: setSidebarTrigger,
      sidebarToggle: setSidebarToggle,
    })
  },
})
</script>
