import { App } from 'vue'
import Controller from './Controller.vue'
import VerticalBar from './VerticalBar.vue'
import HorizontalBar from './HorizontalBar.vue'
import Search from './Search.vue'

const register = (app: App<Element>): void => {
  app
    .component('CatalogController', Controller)
    .component('VerticalBar', VerticalBar)
    .component('HorizontalBar', HorizontalBar)
    .component('CatalogSearch', Search)
}

export default {
  register,
}
