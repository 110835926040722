<template lang="pug">
include /mixins.pug

+b.info-block
  +e.title
    +b.P.ds-caption.--size_2md.--size_4md-sm.--color_main-dark.--medium {{ $t('Смена часового пояса') }}
  +e.content
    +b.g-row
      +b.g-cell.g-cols--12.--6-md.--3-xl
        DController(
          :component="'Multiselect'"
          :name="'timeZone'"
          :options="tzOptions"
          :searchable="true"
          :allow-empty="false"
          v-model="timeZone"
          track-by="slug"
          :labelBy="'label'"
          @update:model-value="updateTimeZone"
          :disabled="isCanNotUpdate"
        )

</template>

<script setup>
import { defineProps, ref, nextTick } from 'vue'
import {
  timeZoneResource,
  timeZoneUpdate,
} from '@services/teacher.service'
import {
  studentTimeZoneResource,
  studentTimeZoneUpdate,
} from '@services/student.service'
import {
  useModalOpener,
  useConfirmWithPromise,
} from '@/composables/useModalOpener'
import useScheduleMethodth from '../../Schedulers/utils'
import useGlobal from '@/composables/useGlobal'

const props = defineProps({
  isCanNotUpdate: Boolean,
  isStudent: Boolean,
})

const { $t } = useGlobal()
const { getTimeZones } = useScheduleMethodth()

const tzOptions = ref([])
const timeZone = ref([])

const getTimeZoneInfo = async() => {
  try {
    const method = props.isStudent ? studentTimeZoneResource : timeZoneResource
    const { data: { item: { timeZone } } } = (await method.execute({}))
    return timeZone || []
  } catch (e) {
    console.error(e, 'time zones error')
  }
}

const setInitialTZ = async() => {
  tzOptions.value = await getTimeZones()
  timeZone.value = await getTimeZoneInfo()
}

setInitialTZ()

const updateTimeZone = async(val) => {
  if (val?.slug) {
    await nextTick()
    timeZone.value = val
    send({ timeZone: val.slug })
  }
}

const send = async sendData => {
  try {
    const message = {
      teacher: $t('При смене часового пояса Ваше расписание станет 24/7. Вы действительно хотите продолжить?'),
      student: $t('Вы уверены что это Ваш часовой пояс?. Вы действительно хотите продолжить?'),
    }
    const info = {
      title: $t('Смена часового пояса'),
      text: props.isStudent ? message.student : message.teacher,
    }

    await useConfirmWithPromise(info)
    const method = props.isStudent ? studentTimeZoneUpdate : timeZoneUpdate
    await method.execute({}, sendData)

    useModalOpener({
      component: 'UiElements/Modals/UiMessageModal',
      message: {
        title: $t('Часовой пояс успешно изменен'),
        icon: 'i-checked',
      },
      reload: true,
    })

  } catch (e) {
    console.error(e, 'error')
    await nextTick()
    timeZone.value = await getTimeZoneInfo()
  }
}

</script>
