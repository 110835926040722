<template lang="pug">
include /mixins.pug
+b.d-control-descriptor(
  :class="{ error: errors[name] || !isValid && 'TelWidget' === component, success: isSuccess }"
)
  InputLabel(
    :class="staticLabel ? 'd-control-input__label--static': 'd-control-input__label--bound'"
    :input-label='staticLabel ? staticLabel: inputLabel'
    :is-required='isRequired'
    :not-show-req="notShowReq"
    v-bind="$attrs"
    :value='inputValue'
  )
  VeeField(
    :as="component"
    :name="name"
    :options="options"
    :type="type"
    :labelBy="labelBy"
    :rules="rules"
    :set-value="setValue"
    :default-country="defaultCountry"
    :slots="$slots"
    v-bind="$attrs"
    @update:model-value='updateValue'
    @update:is-valid='isValid = $event'
  )
  VeeError(
    :name="name"
    v-slot="{ message }"
  )
    +e.errors {{ message }}

</template>

<script setup>
import { ref, defineProps } from 'vue'
/* eslint-disable */

const props = defineProps({
  component: {
    type: String,
    default: 'SimpleInput',
  },
  name: String,
  errors: {
    type: Object,
    default: {},
  },
  isSuccess: Boolean,
  inputLabel: String,
  staticLabel: String,
  options: Array,
  labelBy: String,
  type: String,
  rules: {
    type: String,
    default: '',
  },
  setValue: {},
  defaultCountry: {},
  notShowReq: Boolean,
})

const inputValue = ref(null)
const r = 'required'

const isRequired = props.rules.includes(r)
const isValid = ref(null)

const updateValue = val => {
  // console.log(val, 'updateVal')
  inputValue.value = val
}

</script>
