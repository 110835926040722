<template>
<svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_3_47116)">
<path d="M44.4062 3.39062H31.2812C24.9494 3.39062 19.7969 8.54317 19.7969 14.875C19.7969 20.6509 24.0827 25.4446 29.6406 26.2425V31.2812C29.6406 31.9445 30.0395 32.5438 30.6532 32.7969C31.2584 33.0494 31.9682 32.914 32.4413 32.4412L38.5231 26.3594H44.4062C50.7381 26.3594 56 21.2068 56 14.875C56 8.54317 50.7381 3.39062 44.4062 3.39062ZM31.2812 16.5154C30.375 16.5154 29.6406 15.7808 29.6406 14.8748C29.6406 13.9687 30.375 13.2342 31.2812 13.2342C32.1873 13.2342 32.9219 13.9687 32.9219 14.8748C32.9219 15.7808 32.1873 16.5154 31.2812 16.5154ZM37.8438 16.5154C36.9375 16.5154 36.2031 15.7808 36.2031 14.8748C36.2031 13.9687 36.9375 13.2342 37.8438 13.2342C38.7498 13.2342 39.4844 13.9687 39.4844 14.8748C39.4844 15.7808 38.7498 16.5154 37.8438 16.5154ZM44.4062 16.5154C43.5 16.5154 42.7656 15.7808 42.7656 14.8748C42.7656 13.9687 43.5 13.2342 44.4062 13.2342C45.3123 13.2342 46.0469 13.9687 46.0469 14.8748C46.0469 15.7808 45.3123 16.5154 44.4062 16.5154Z" fill="#0BB1EF"/>
<path d="M36.8594 52.6094C39.5734 52.6094 41.7812 50.4015 41.7812 47.6875V41.125C41.7812 40.4184 41.3294 39.7919 40.6597 39.5693L30.839 36.288C30.3583 36.1262 29.8329 36.1998 29.41 36.4786L25.2347 39.2616C20.8126 37.1532 15.5656 31.906 13.4571 27.484L16.24 23.3087C16.5203 22.8873 16.5909 22.3602 16.4306 21.8796L13.1494 12.0589C12.9268 11.3893 12.3003 10.9375 11.5938 10.9375H4.92188C2.20784 10.9375 0 13.1223 0 15.8363C0 34.7387 17.957 52.6094 36.8594 52.6094Z" fill="#FF3C8A"/>
</g>
<defs>
<clipPath id="clip0_3_47116">
<rect width="56" height="56" fill="white"/>
</clipPath>
</defs>
</svg>
</template>
