import {
  baseResource,
  createReceiver,
} from '@resource/resource'

import {
  friendlyUrlGenerator,
} from '@resource/vue-friendly/main.js'

import {
  prefixLanguage,
} from '@utils/urls'

import {
  prefixAPI,
} from '@resource/api'

const MODEL = 'catalog'

const ATTRIBUTES_URL = prefixAPI('/{category}/', MODEL)
export const attributesResource = createReceiver(ATTRIBUTES_URL)

const order = {
  path: [
    'prefix',
    'category',
    'label',
    'postfix',
    'filters',
  ],
  query: [
    'pagination_offset',
  ],
}

const FILTERS_LIST_URL_PREFIX = prefixLanguage('')
const FILTERS_LIST_URL_POSTFIX = '/ajax/filters/'
export const filesListUrl =
  friendlyUrlGenerator([FILTERS_LIST_URL_PREFIX, FILTERS_LIST_URL_POSTFIX], baseResource, order)
