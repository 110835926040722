<template lang="pug">
include /mixins.pug

+b-context('d-control-input')
  +e.LABEL.label.u-sel-none(
    :class="{'d-control-input__label--active': isValue }"
    :for='id',
  ) {{ inputLabel }}
    span.is-required.u-sel-none(
      v-if='isRequired && !notShowReq'
    ) &#32; &#42;

</template>

<script setup>
import { defineProps, computed } from 'vue'

const props = defineProps({
  isRequired: Boolean,
  inputLabel: String,
  id: String,
  value: [Array, String, Boolean, Object],
  modelValue: {},
  notShowReq: Boolean,
})

// eslint-disable-next-line
const isValue = computed(() => {
  const { modelValue, value } = props
  let result = false
  if (modelValue) {
    result = check(modelValue)
  } else if (value) {
    result = check(value)
  }
  return result
})

const check = val => {
  if (Array.isArray(val)) {
    if (val.length) {
      return true
    }
  } else if (val) {
    return true
  }
  return false
}

</script>
