<template lang="pug">
include /mixins.pug

+b.scheduler(:class="{'is-not-loaded': !scheduleWeek.length}")
  +b.preloader.--white.--full-block(v-if='isLoad')
  nav-arr(
    @scheduler:nav="nav($event)"
    :is-disabled="date.toLocaleDateString('uk-UA') === params.filters.start_date"
    :class="[`scheduler__nav-arr-wrapper--variant_${type}`]"
    v-if="scheduleWeek.length"
  )

  +e.head.--align_center(
    :class="[type === 'card' ? 'scheduler__head--row-lg': 'scheduler__head--variant_1']"
  )
    +b.P.ds-caption.--size_md.--color_main-gray.--medium.--appearance_center-till-lg {{ $t('Выберите время встречи') }}

    nav-btns(
      @scheduler:nav="nav($event)"
      :class="{'is-step-one': type !== 'card'}"
      :week-label="getWeekLabel()"
      :is-disabled="date.toLocaleDateString('uk-UA') === params.filters.start_date"
    )

    +e.tz-wrapper(
      :class="{'is-step-one': type !== 'card'}"
    )
      t-z-select(
        @scheduler:setTZ="getData($event)"
        :no-observe='noObserve'
        :target-ref="`target_${uid}`"
        class="d-control-descriptor--variant_tz-grey"
      )

  scheduler-body(
    :schedule-week="scheduleWeek"
    :uid="uid"
    :is-free="isFree"
    :id-meet-lesson="idMeetLesson"
    :is-small="type !== 'card'"
    :is-today="date.toLocaleDateString('uk-UA')"
    v-if="scheduleWeek.length"
  )

  scheduler-btn(
    @scheduler:hours-down="getData($event)"
    v-if="scheduleWeek.length"
  )

</template>

<script setup>
/* eslint-disable */
import { ref, reactive, onMounted } from 'vue'
import { filesListUrl, lessonFilesListUrl } from '@services/schedulers.service'
import useScheduleMethodth from './utils'

const emit = defineEmits(['scheduler:empty'])

const props = defineProps({
  uid: Number,
  isFree: Boolean,
  idMeetLesson: Number,
  type: {
    type: String,
    default: 'card',
  },
  noObserve: Boolean,
})

const {
  getWeekRange,
  getWeekLabel,
  getNavParams,
} = useScheduleMethodth()

// hours_down=20;start_date=05.05.2022;end_date=11.05.2022;teacher_id=1
const isLoad = ref(null)
const scheduleWeek = ref([])
const date = ref(new Date())

let params = reactive({
  filters: {
    teacher_id: props.uid,
    hours_down: 3,
    start_date: date.value.toLocaleDateString('uk-UA'),
    end_date: new Date(new Date().setDate(date.value.getDate() + 6)).toLocaleDateString('uk-UA'),
  },
})

onMounted(() => {
  getWeekRange(params)
})

const nav = direction => {
  const newParams = getNavParams(direction)
  getData(newParams)
}

const getData = data => {
  params.filters = { ...params.filters, ...data }
  getList(params)
}

const getList = async (params) => {
  try {
    if (!isLoad.value || isLoad.value == null) {
      isLoad.value = true
    }
    const methods = {
      stepOne: props.isFree ? filesListUrl: lessonFilesListUrl,
      card: filesListUrl,
    }
    const { data: { item: { days } } } = (await methods[props.type].execute(params))
    if (days.length) {
      scheduleWeek.value = days
      getWeekRange(params)
    } else {
      emit('scheduler:empty')
    }
    isLoad.value = false
  } catch (e) {
    console.error(e, 'Error')
    isLoad.value = false
  }
}

</script>
