<template lang="pug">
include /mixins.pug

+b.scheduler-config.ec-space_pt--5(v-if="weekScheduler.length")
  +b.P.ds-caption.--size_2md.--size_4md-xl.--color_main-dark.--line-h_1.--bold.--appearance_center-till-lg.ec-space_pb--3.--4-lg {{ $t('Запланировать рабочие дни') }}

  +e.head
    +e.cell
      +e.P.title {{ $t('День недели') }}
    +e.cell
      +e.P.title {{ $t('Время') }}
    +e.cell
    +e.cell
      +e.P.title {{ $t('Перерыв') }}
    +e.cell
    +e.cell
    +e.cell

  +e.body
    ui-slider(
      class="agile--dark-dots"
      :speed="500",
      :dots="true",
      :nav-buttons="false"
      :infinite="false"
      :responsive="sliderOptions"
      :slides-to-show="1"
      :rtl="dir"
    )
      +b-context('scheduler-config')
        +e.row(v-for="{ title, id }, index in weekScheduler")
          +e.cell
            +e.P.title.ec-space_mb--1.is-disable-lg {{ $t('День недели') }}
            +e.fake-input
              +e.P.title {{ title }}
          +e.cell
            +e.P.title.is-disable-lg {{ $t('Время') }}
            DController(
              :class="'d-control-descriptor--h_xl'"
              :component="'Multiselect'"
              :name="'timeStartWork'+index"
              :key="`timeStartWork${index}_${id}`"
              :options="timeOptions"
              :allow-empty="false"
              :preselect-first="true"
              v-model="form['timeStartWork' + index]"
              track-by="slug"
              :labelBy="'label'"
            )
          +e.cell
            DController(
              :class="'d-control-descriptor--h_xl'"
              :component="'Multiselect'"
              :name="'timeEndWork'+index"
              :key="`timeEndWork${index}_${id}`"
              :options="timeOptions"
              :allow-empty="false"
              :preselect-first="true"
              v-model="form['timeEndWork' + index]"
              track-by="slug"
              :labelBy="'label'"
            )
          +e.cell
            +e.P.title.is-disable-lg {{ $t('Перерыв') }}
            DController(
              :class="'d-control-descriptor--h_xl'"
              :component="'Multiselect'"
              :name="'timeStartBreak'+index"
              :key="`timeStartBreak${index}_${id}`"
              :options="timeOptions"
              :allow-empty="false"
              :preselect-first="true"
              v-model="form['timeStartBreak' + index]"
              track-by="slug"
              :labelBy="'label'"
            )
          +e.cell
            DController(
              :class="'d-control-descriptor--h_xl'"
              :component="'Multiselect'"
              :name="'timeEndBreak'+index"
              :key="`timeEndBreak${index}_${id}`"
              :options="timeOptions"
              :allow-empty="false"
              :preselect-first="true"
              v-model="form['timeEndBreak' + index]"
              track-by="slug"
              :labelBy="'label'"
            )
          +e.cell
            +b.rules-wrapper(:dir="dir ? 'rtl': 'ltr'")
              +b.LABEL.d-control-checkbox.--variant_inline
                +e.INPUT.element(
                  v-model="form['isHoliday' + index]"
                  :key="`isHoliday${index}_${id}`"
                  type="checkbox"
                  :value="true"
                )
                +e.SPAN.label
              +b.P.ds-caption.--size_md.--color_main-dark.--regular.u-sel-none {{ $t('Выходной день') }}

          +e.cell
            +b.BUTTON.link-button.--style_main.--color_pink.--hover_blue.--full-size(
              :disabled='isLoad || isCanNotUpdate'
              @click.prevent="setDaySchedule(index, id)"
            )
              +b.P.ds-caption.--color_main-white.--size_sm.--size_md-lg.--semibold {{ $t('Задать') }}

</template>

<script setup>
/* eslint-disable */
import { defineProps, defineEmits, reactive, ref, onMounted } from 'vue'
import {
  schedulerConfigList,
  schedulerConfigUpdate,
} from '@services/teacher.service'
import { useErrorsGetter } from '@/components/Forms/FormMixin'
import { useModalOpener } from '@/composables/useModalOpener'
import useMethods from './utils'
import useGlobal from '@/composables/useGlobal'

const { $t } = useGlobal()
const {
  getTimeOptions,
  weekTitles,
} = useMethods()

const emit = defineEmits(['schedule-update:success'])

const props = defineProps({
  uid: Number,
  isCanNotUpdate: Boolean,
})

const isLoad = ref(null)
const dir = window.isRtl === 'True' ? true: false
const timeOptions = getTimeOptions()
const form = reactive({})
const weekScheduler = ref([])

const sliderOptions = [
  {
    breakpoint: 991,
    settings: {
      unagile: true,
    },
  },
  {
    breakpoint: 767,
    settings: {
      slidesToShow: 3,
    },
  },
  {
    breakpoint: 563,
    settings: {
      slidesToShow: 2,
    },
  },
]

const validateSchedule = async({ timeStartWork, timeEndWork, timeStartBreak, timeEndBreak, isHoliday }) => {
  if (isHoliday) return true
  if (timeStartBreak === timeEndBreak) {
    if (timeStartWork >= timeEndWork) return false
    return true
  }
  if (timeStartWork >= timeEndWork || timeStartBreak > timeEndBreak) return false
  if (timeStartBreak <= timeStartWork || timeStartBreak >= timeEndWork) return false
  if (timeEndBreak <= timeStartWork || timeEndBreak >= timeEndWork) return false
  return true
}

const setDaySchedule = async(index, id) => {
  const sendData = {
    timeStartWork: form['timeStartWork' + index].slug,
    timeEndWork: form['timeEndWork' + index].slug,
    timeStartBreak: form['timeStartBreak' + index].slug,
    timeEndBreak: form['timeEndBreak' + index].slug,
    isHoliday: form['isHoliday' + index],
  }
  const isValid = await validateSchedule(sendData)
  if (!isValid) {
    useModalOpener({
      component: 'UiElements/Modals/UiMessageModal',
      message: {
        title: $t('Error! Check your schedule!'),
        text: $t('The start time must not be greater than or equal to the end time!'),
        icon: 'i-emoticon',
      },
    })
    return
  }
  try {
    if (!isLoad.value || isLoad.value == null) {
      isLoad.value = true
    }
    await schedulerConfigUpdate.execute({ id }, sendData)

    useModalOpener({
      component: 'UiElements/Modals/UiMessageModal',
      message: {
        title: $t('Рассписание на день успешно задано'),
        icon: 'i-checked',
      },
    })

    emit('schedule-update:success')
    isLoad.value = false
  } catch (e) {
    console.error(e, 'Error')
    const { nonFieldErrors } = await useErrorsGetter(e)
    useModalOpener({
      component: 'UiElements/Modals/UiMessageModal',
      message: {
        title: nonFieldErrors[0],
        icon: 'i-emoticon',
      },
    })
    getSchedulerConfig()
    isLoad.value = false
  } 
}

const getSchedulerConfig = async() => {
  try {
    if (!isLoad.value || isLoad.value == null) {
      isLoad.value = true
    }
    const { data: { items } } = (await schedulerConfigList.execute({}))
    items.sort((a, b) => a.dayWeek - b.dayWeek).map(el => el.title = weekTitles(el.dayWeek))
    weekScheduler.value = items
    setInitialConfig(items)
    isLoad.value = false
  } catch (e) {
    console.error(e, 'Error')
    isLoad.value = false
  }  
}

const setInitialConfig = items => {
  items.forEach((el, i) => {
    form['timeStartWork' + i] = getInitialOpt(el.timeStartWork)
    form['timeEndWork' + i] = getInitialOpt(el.timeEndWork)
    form['timeStartBreak' + i] = getInitialOpt(el.timeStartBreak)
    form['timeEndBreak' + i] = getInitialOpt(el.timeEndBreak)
    form['isHoliday' + i] = el.isHoliday
  })
}

const getInitialOpt = name => {
  const initVal = timeOptions.find(opt => opt.slug === name)
  return initVal || { label: '00:00', slug: '00:00:00' }
}

onMounted(() => {
  getSchedulerConfig()
})

</script>
