import {
  createReceiver,
} from '@resource/resource'

import {
  prefixAPI,
} from '@resource/api'

const MODEL = 'blog'
const ATTRIBUTES_URL = prefixAPI('/{url}/', MODEL)
export const attributesResource = createReceiver(ATTRIBUTES_URL)