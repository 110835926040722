<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.689453 39.3145L3.42374 29.396C1.73771 26.487 0.850904 23.1919 0.851526 19.8386C0.851526 9.2951 9.47997 0.706299 20.072 0.706299C25.2165 0.706299 30.0368 2.70243 33.6817 6.31073C35.473 8.08874 36.8923 10.1987 37.8585 12.52C38.8247 14.8413 39.3187 17.3282 39.3124 19.8386C39.3124 30.3832 30.685 38.972 20.0919 38.972C16.8725 38.972 13.7126 38.1648 10.8967 36.6333L0.689453 39.3145V39.3145Z" fill="url(#paint0_linear_2_178)"/>
<path d="M0 40L2.83572 29.7379C1.0923 26.724 0.177687 23.3106 0.183021 19.838C0.162073 8.89095 9.09371 0 20.0717 0C25.398 0 30.4002 2.05612 34.1676 5.80622C37.9349 9.55633 40 14.5357 40 19.838C40 30.7655 31.0695 39.6575 20.0915 39.6575C16.7629 39.6618 13.4877 38.8293 10.5733 37.2381L0 40V40ZM11.0397 33.6691L11.6472 34.0323C14.1956 35.5343 17.1064 36.3283 20.0728 36.3306C29.1863 36.3306 36.6196 28.9308 36.6196 19.8587C36.6196 15.4629 34.8986 11.3103 31.7795 8.20485C28.6582 5.10158 24.4862 3.36724 20.0706 3.36724C10.9361 3.36724 3.50276 10.7671 3.50276 19.8391C3.50276 22.9446 4.37376 25.9889 6.03528 28.609L6.42007 29.2351L4.7387 35.3238L11.0375 33.6702L11.0397 33.6691Z" fill="#F3F3F3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M15.1086 11.5334C14.7448 10.7066 14.339 10.687 13.9951 10.687C13.6908 10.6663 13.3666 10.6663 13.0226 10.6663C12.6985 10.6663 12.1516 10.7873 11.6863 11.2913C11.22 11.7952 9.94434 12.9853 9.94434 15.4253C9.94434 17.8643 11.7271 20.2237 11.9697 20.5455C12.2123 20.8683 15.4129 26.0299 20.4758 28.026C24.6885 29.6786 25.5397 29.3568 26.4515 29.2553C27.3622 29.155 29.3876 28.0664 29.8131 26.896C30.2178 25.7474 30.2178 24.7395 30.0965 24.5377C29.9752 24.3359 29.6301 24.2148 29.1439 23.9531C28.6378 23.7109 26.2078 22.5023 25.7415 22.3398C25.2762 22.1784 24.9521 22.0976 24.6279 22.5819C24.3038 23.0859 23.3523 24.1952 23.048 24.517C22.7646 24.8399 22.4614 24.8802 21.9752 24.6381C21.468 24.397 19.8694 23.8723 17.9642 22.1784C16.4868 20.8683 15.4945 19.2354 15.1902 18.7511C14.9068 18.2472 15.1494 17.9854 15.4129 17.7432C15.6356 17.5218 15.9201 17.1586 16.1626 16.8761C16.4052 16.5936 16.4857 16.3721 16.6687 16.0493C16.8308 15.7275 16.7492 15.4242 16.6279 15.1832C16.5066 14.9607 15.554 12.5217 15.1086 11.5334V11.5334Z" fill="white"/>
<defs>
<linearGradient id="paint0_linear_2_178" x1="19.9971" y1="39.3123" x2="19.9971" y2="0.698662" gradientUnits="userSpaceOnUse">
<stop stop-color="#20B038"/>
<stop offset="1" stop-color="#60D66A"/>
</linearGradient>
</defs>
</svg> 