<template lang="pug">
include /mixins.pug

+b.g-container.--variant_default
  meeting-calendar(
    :uid="uid"
    :first-name="firstName"
    :role="'student'"
  )

  settings-view

  change-time-zone(
    :is-student="true"
  )

  student-balance-view

  student-active-packages

  student-future-meetings

  student-past-meetings

  //- +b.free-lessons-block
  //-   +e.items-wrap
  //-     +e.el.--variant_1
  //-       +b.svg-image.icons.--w_2xl.--center.--cursor_auto
  //-         i-giftbox(class="svg-image__element")
  //-     +e.el.--variant_2
  //-       +b.P.ds-caption.--size_3md.--size_lg-lg.--color_white.--medium {{ $t('Получить бесплатные уроки') }}
  //-   +b.ec-space_mt--3
  //-     +b.P.ds-caption.--size_sm.--size_md-lg.--color_white.--appearance_center-sm {{ $t('Вы можете получить бесплатный урок, за каждого нового ученика, которого вы приведете на наш сайт') }}
  //-   modal-trigger(
  //-     component="Profiles/Modals/InviteToTheSite"
  //-     v-slot="{ open }"
  //-   )
  //-     +b.BUTTON.link-button.--style_main.--color_white.--hover_pink.--full-size-till-sm.ec-space_mt--7.--10-xl(@click.prevent='open()')
  //-       +b.P.ds-caption.--color_main-dark.--size_sm.--size_md-lg.--semibold  {{ $t('Получить') }}

  +b.g-row.--align_center.ec-space_mt--10
    +b.g-cell.g-cols--12.--narrow-sm
      +b.A.link-button.--style_main.--color_pink.--hover_blue.--full-size.ec-space_mb--2(
        :href="staffLink"
        target="_blank"
        rel="noopener noreferrer"
      )
        +b.SPAN.ds-caption.--color_main-white.--size_sm.--size_md-lg.--semibold {{ $t('Связаться руководством сайта') }}

</template>

<script setup>
import { defineProps } from 'vue'
import ChangeTimeZone from '@/components/Profiles/Common/ChangeTimeZone.vue'

const props = defineProps({
  uid: Number,
  firstName: String,
})

const staffLink = window.whatsApp || '#'

</script>
