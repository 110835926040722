<template lang="pug">
include /mixins.pug

+b.info-block
  +e.title
    +b.P.ds-caption.--size_2md.--size_4md-sm.--color_main-dark.--medium {{ $t('Учебные пособия') }}
  +e.content.relative
    +b.preloader.--white.--full-block(v-if='isLoad')
    +b.tutorial-wrap
      ui-slider(
        class="agile--dark-dots"
        :speed="500",
        :dots="false",
        :nav-buttons="true"
        :infinite="true"
        :responsive="sliderOptions"
        :slides-to-show="1"
        :rtl="dir"
        v-if="list.items.length"
      )
        template(
          v-for="item in list.items"
        )
          +b.slide.--variant_1
            +b.tutorial.relative
              +e.btn(@click.prevent="deleteTutorial(item.id)" v-if="!isCanNotUpdate")
                IClose
              +e.P.text.--color_blue {{ item.title }}
              modal-trigger(
                component="Profiles/Modals/AddTutorials"
                :info="item"
                v-slot="{ open }"
              )
                +b.BUTTON.link-button.--style_main.--color_blue.--hover_pink.ec-space_mt--2(@click.prevent='open()' :disabled="isCanNotUpdate")
                  +b.P.ds-caption.--color_main-white.--size_sm.--size_md-lg.--semibold {{ $t('Заменить пособие') }}

              +e.link(
                v-clipboard:copy="item.tutorialFile"
                v-clipboard:success="onCopy"
              ) 
                +b.SPAN {{ $t('Скопировать ссылку') }}
                +e.prompt
                  +b.SPAN {{ $t('Copied') }}

    +b.g-row.--appearance_spaced.ec-space_mt--2
      +b.g-cell.g-cols--12.--6-sm.--4-md.--2_from_10-xl.is-visible-md(
        v-for="item in list.items"
      )
        +b.tutorial.relative
          +e.btn(@click.prevent="deleteTutorial(item.id)" v-if="!isCanNotUpdate")
            IClose
          +e.P.text.--color_blue {{ item.title }}
          modal-trigger(
            component="Profiles/Modals/AddTutorials"
            :info="item"
            v-slot="{ open }"
          )
            +b.BUTTON.link-button.--style_main.--color_blue.--hover_pink.ec-space_mt--2(@click.prevent='open()' :disabled="isCanNotUpdate")
              +b.P.ds-caption.--color_main-white.--size_sm.--size_md-lg.--semibold {{ $t('Заменить пособие') }}

          +e.link(
            v-clipboard:copy="item.tutorialFile"
            v-clipboard:success="onCopy"
          ) 
            +b.SPAN {{ $t('Скопировать ссылку') }}
            +e.prompt
              +b.SPAN {{ $t('Copied') }}

      +b.g-cell.g-cols--12.--6-sm.--4-md.--2_from_10-xl(v-if="!isCanNotUpdate")
        modal-trigger(
          component="Profiles/Modals/AddTutorials"
          v-slot="{ open }"
        )
          +b.tutorial.--hovered.--pointer(@click.prevent="open()")
            +e.P.text.--variant_1 {{ $t('Загрузить свои учебные пособия') }}
            +e.icon
              i-cloud-upload

</template>

<script setup>
/* eslint-disable */
import { defineProps, ref, onMounted } from 'vue'
import {
  tutorialResource,
  tutorialDelete,  
} from '@services/teacher.service'

const props = defineProps({
  isCanNotUpdate: Boolean,
})

const dir = window.isRtl === 'True' ? true: false
const sliderOptions = [
  {
    breakpoint: 767,
    settings: {
      unagile: true,
    },
  },
  {
    breakpoint: 563,
    settings: {
      slidesToShow: 2,
    },
  },
]

const isLoad = ref(null)
const list = ref({
  items: [],
  // pagination: {
  //   total: {},
  //   limit: {},
  //   offset: {},
  // }
})

onMounted(() => {
  getTutorialList()
})

const getTutorialList = async() => {
  try {
    const { data } = (await tutorialResource.execute({}))
    list.value = data
  } catch (e) {
    console.error(e, 'Error')
  }
}

const deleteTutorial = async id => {
  try {
    await tutorialDelete.execute({ id })
    getTutorialList()
  } catch (e) {
    console.error(e, 'Error')
  }
}

const onCopy = ({ trigger }) => {
  trigger.classList.add('is-copy')
  setTimeout(() => {
    trigger.classList.remove('is-copy')
  }, 1500)
}

</script>
