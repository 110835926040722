<template lang="pug">
include /mixins.pug

+b.info-block
  +e.title
    +b.P.ds-caption.--size_2md.--size_4md-sm.--color_main-dark.--medium {{ $t('Мой баланс') }}
  +e.content
    +b.g-row.--appearance_spaced.--space_1xl.--align_center
      +b.g-cell.g-cols--12.--narrow-md.--3-xl
        profile-balance(
          :balance="studentBalance"
        )
      +b.g-cell.g-cols--12.--4-lg
        +b.P.ds-caption.--size_sm.--size_md-md.--color_main-dark.--regular {{ $t('После нажатия кнопки «Пополнить баланс» вы будете перенаправлены на страницу оплаты.') }}
      +b.g-cell.g-cols--12.--6-sm.--narrow-md
        modal-trigger(
          component="Profiles/Modals/Replenishment"
          v-slot="{ open }"
        )
          +b.BUTTON.link-button.--style_main.--color_blue.--hover_pink.--full-size(@click.prevent='open()')
            +b.SPAN.ds-caption.--color_main-white.--size_sm.--size_md-lg.--semibold {{ $t('Пополнить баланс') }}

</template>

<script setup>
import { defineProps, ref } from 'vue'
import { balanceResource } from '@services/student.service'

const props = defineProps({
  isCanNotUpdate: Boolean,
})

const isLoad = ref(false)
const studentBalance = ref('0.00')

const getBalance = async () => {
  isLoad.value = true
  try { 
    const { data: { item: { balance } } } = (await balanceResource.execute({}))
    studentBalance.value = String(balance)
    isLoad.value = false
  } catch (e) {
    isLoad.value = false
    console.error(e)
  }
}

getBalance()

</script>
