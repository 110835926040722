<script>
import { defineComponent } from 'vue'
import { logoutCreate } from '@services/authenticate.service'

export default defineComponent({
  name: 'LogoutTrigger',
  setup(props, { slots }) {
    const logout = () => {
      return logoutCreate.execute().then(() => {
        window.location = window.indexPageUrl
      })
    }

    return () => slots.default({
      logout: () => logout(),
    })
  },
})
</script>
