<template lang="pug">
include /mixins.pug

div(ref='topOfContent')
  +b.preloader(v-if='isLoad')
  slot(
    name='content'
    v-if="!list.data"
  )

  renderer(
    :result='list.data',
    v-if='list.data'
  )

  ui-limit-pagination(
    class="pagination--center-till-sm"
    :pagination='list.pagination'
    @input='handlePaginate'
    v-if="total > limit"
  )

</template>

<script setup>
/* eslint-disable */
import { defineProps, ref, reactive } from 'vue'
import { filesListUrl } from '@services/filters.service'
import { prettifyUrl } from '@utils/filters'

const props = defineProps({
  base: String,
  total: {},
  limit: {},
  offset: {},
})

const topOfContent = ref(null)
const isLoad = ref(null)
const list = ref({
  data: '',
  pagination: {
    total: props.total,
    limit: props.limit,
    offset: props.offset,
  }
})

let params = reactive({
  category: props.base,
  filters: {},
})

const getList = params => {
  if (!isLoad.value || isLoad.value == null) {
    isLoad.value = true
  }
  return filesListUrl.execute(params).then(res => {
    isLoad.value = false
    list.value = { ...res }
    prettifyUrl(params, props.base)
    topOfContent.value.scrollIntoView({ block: 'start', behavior: 'smooth' })
  }).catch(e => {
    isLoad.value = false
    console.error(e, 'error')
  })
}

const handlePaginate = page => {
  getList(
    {
      ...params,
      ...page,
    }
  )
}

</script>
