<template lang="pug">
include /mixins.pug

+b.confirm__element
  +b.P.ds-caption.--size_3md.--size_lg-xl.--color_main-dark.--semibold.--appearance_center {{ $t('You do not have access to view this page') }}

  VeeForm(v-slot="{ errors }", @submit="request")
    +b.g-row.--appearance_spaced.ec-space_mt--5
      +b.g-cell.g-cols--12.ec-space_mt--2
        DController(
          :class="'d-control-descriptor--bg_grey'"
          :component="'TelWidget'"
          :name="'login'"
          :errors='errors'
          :static-label=`$t("Phone")`
          rules="required"
          @update:tel='updatePhone'
          @update:is-valid="hasPhoneErrors = !$event"
        ) 
      +b.g-cell.g-cols--12.ec-space_mt--2
        DController(
          :class="'d-control-descriptor--bg_grey'"
          :name="'password'"
          :errors='errors'
          :static-label=`$t("Пароль")`
          :placeholder=`$t("Введите ваш пароль")`
          :type="'password'"
          rules="required|min:8"
        )

      +b.g-cell.g-cols--12.ec-space_mt--2
        VeeField(v-slot="{ field }", type="checkbox", name="rememberMe",  :value="true" :unchecked-value="false" v-model="rememberMe")
          +b.rules-wrapper
            VeeError(name="rememberMe", v-slot="{ message }"): +b.d-control-descriptor__errors {{ message }}
            +b.LABEL.d-control-checkbox.--variant_inline
              +e.INPUT.element(
                v-bind="field"
                type="checkbox"
                :value="true"
              )
              +e.SPAN.label
            +b.P.ds-caption.--size_sm.--size_md-sm.--color_main-dark.--regular {{ $t('Запомнить меня') }}

      +b.g-cell.g-cols--12
        VeeError(name="nonFieldErrors" v-slot="{ message }"): +b.simple-errors {{ message }}

      +b.g-cell.g-cols--12.ec-space_mt--2
        +b.BUTTON.link-button.--style_main.--color_pink.--hover_blue.--full-size(
          :disabled='isLoad || hasPhoneErrors'
          type='submit'
        )
          +b.P.ds-caption.--color_main-white.--size_sm.--size_md-lg.--semibold  {{ $t('Войти') }}

  +e.item.ec-space_mt--4
    modal-trigger(
      component="Forms/ForgotPassword"
      v-slot="{ open }"
    )
      +b.P.ds-caption.--size_sm.--size_md-sm.--color_main-dark.--regular.--appearance_center(@click.prevent='open(), close()')
        +b.SPAN.ds-caption.--color_main-dark.--appearance_underline.ds-link.--tr.--styling_default.is-hover {{ $t('Забыли пароль?') }}

  +e.item.--br_top.ec-space_pt--3.ec-space_mt--6
    +b.P.ds-caption.--size_sm.--size_md-sm.--color_main-dark.--regular.--appearance_center {{ $t('Не зарегистрированы?') }}
      modal-trigger(
        component="Forms/SignUp"
        v-slot="{ open }"
      )
        +b.SPAN.ds-caption.--color_main-dark.--appearance_underline.ds-link.--tr.--styling_default.is-hover.ec-space_ml--2.ec-space_mr--2(@click.prevent='open(), close()') {{ $t('Регистрация') }}


</template>

<script setup>
import { ref } from 'vue'
import { authenticateCreate } from '@services/authenticate.service'
import { useSubmit } from './FormMixin'

/* eslint-disable */
const isLoad = ref(false)

const phoneWithCode = ref(null)
const hasPhoneErrors = ref(false)
const rememberMe = ref(false)

const request = (sendData, control) => {
  isLoad.value = true
  useSubmit(send(sendData), control)
}

const send = async data => {
  const sendData = {
    ...data,
    login: phoneWithCode.value,
  }
  try { 
    const { data: { item: { redirect } } } = (await authenticateCreate.execute({}, sendData))
    console.log(redirect, 'res')
    // window.location = redirect
    isLoad.value = false
    window.location.reload()
  } catch (e) {
    isLoad.value = false
    return Promise.reject(e)
  }
}

const updatePhone = val => {
  // console.log(val, 'val updatePhone')
  if (val) {
    phoneWithCode.value = val.replace(/^./g, "")
  }
}

</script>
