import { createApp, App } from 'vue'


import 'maz-ui/css/main.css'
import './styles/index.sass'
import Components from './components'
import Modules from './modules'
import Directives from './directives'

import { scroller } from '@/utils/scroller'

const app: App = createApp({
  delimiters: ['[[', ']]'],
  data() {
    return {
      date: new Date(),
      True: true,
      False: false,
      None: undefined,
    }
  },
})

app.use(Modules)

// for scroll in firefox
const userAgent = navigator.userAgent.toLowerCase()
const mozila = /firefox/.test(userAgent)
if (mozila) {
  window.addEventListener('hashchange', () => scroller())
}
// for scroll at init in iOS
document.addEventListener("DOMContentLoaded", () => scroller())

// eslint-disable-next-line
const { setCookie } = require('@utils/cookies')

app.config.globalProperties.$MULTI_SWITCHER = function multiSwitcher(url: string, code: string): void {
  if (url) {
    setCookie('CHOSEN_LANGUAGE', code)
    window.location.assign(url)    
  } else {
    setCookie('CHOSEN_CURRENCY', code)
    let path = window.location.pathname
    const zero = 0
    if (path.indexOf('filters') > zero) {
      if (!path.includes('teacher_id')) {
        path = path.split('filters/')[0]
      }
    }
    window.location.assign(path)
  }
}

Components.installComponents(app)
Directives.registerDirective(app)

window.addEventListener('resize', () => {
  const vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
})

if(window.language === 'he') {
  document.addEventListener("DOMContentLoaded", () => {
    setTimeout(async () => {
      const w = document.body.scrollWidth
      window.scrollTo({left: w, behavior: 'auto'})
    }, 100)
  })  
}

const { $t } = app.config.globalProperties
const requiredErrMessage = $t('This field is required')
const emailErrMessage = $t('This field must be a valid email')
const confirmedErrMessage = $t('Passwords must match')
const minErrMessage = $t('Min')
const maxErrMessage = $t('Max')
const alphaErrMessage = $t('Only alpha characters')
const whatsAppErrMessage = $t('This field must be a valid whatsapp link')
const onlyEnglishErrMessage = $t('Only english characters')
const isYouTubeLinkErrMessage = $t('Link format must match YouTube')
const minValErrMessage = $t('Min value')

export {
  emailErrMessage,
  requiredErrMessage,
  confirmedErrMessage,
  minErrMessage,
  maxErrMessage,
  alphaErrMessage,
  whatsAppErrMessage,
  onlyEnglishErrMessage,
  isYouTubeLinkErrMessage,
  minValErrMessage,
}

app.mount('#app')