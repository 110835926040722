<template lang="pug">
include /mixins.pug

+b.scheduler__body(v-if="scheduleWeek.length" :class="[isProfile ? `is-${role}-profile`: 'is-simple']")
  +e.col(v-for="{ dateUtc, dateTz, slots } in scheduleWeek")
    +e.cell.--head(:class="{ 'is-small': isSmall }")
      +e.P.day-number(:class="{ 'is-blue': checkIsToday(dateTz) }") {{ getDateView(dateTz) }}
      +e.P.day {{ getWeekDay(dateTz, isSmall) }}
    +e.cell(
      v-for="{ dateTimeTz, dateTimeTzValue, dateTimeUtc, status } in slots"
      :class="isProfile ? statusesLegend[status] : publickStatusesLegend[status]"
      @click.prevent="handleAction(dateTimeUtc, status, dateTimeTz)"
    )
      +e.P {{ dateTimeTzValue }}
      +e.info-icon(v-if="detailAccess.includes(status) && isProfile")
        i-info-secondary(class="svg-image__element")

</template>

<script setup>
import { defineProps, defineEmits } from 'vue'
import lessonMethods from '../lessonMethods'
import useScheduleMethodth from '../utils'

const emit = defineEmits(['scheduler:toggle-cell'])

const props = defineProps({
  scheduleWeek: Array,
  uid: Number,
  role: String,
  isProfile: Boolean,
  isFree: Boolean,
  idMeetLesson: Number,
  isSmall: Boolean,
  isToday: String,
})

const {
  statusesLegend,
  publickStatusesLegend,
  getWeekDay,
  getDateView,
} = useScheduleMethodth()

const {
  getLessonDetail,
  toggleStatus,
  timeConfirmation,
} = lessonMethods()

const detailAccess = ['future_lesson', 'past_lesson']
const toggleAccess = ['inaccessible', 'free']
const bookingPermission = ['free']

const checkIsToday = date => props.isToday === new Date(date).toLocaleDateString('uk-UA')

const handleAction = async(dateTimeUtc, status, dateTimeTz) => {
  const { isProfile, role, uid, isFree, idMeetLesson } = props
  if (isProfile) {
    if (detailAccess.includes(status)) {
      await getLessonDetail(dateTimeUtc, role)
    } else if ('teacher' === role && toggleAccess.includes(status)) {
      await toggleStatus(dateTimeUtc)
      emit('scheduler:toggle-cell')
    }
  } else {
    if (bookingPermission.includes(status)) {
      timeConfirmation(uid, dateTimeUtc, dateTimeTz, isFree, idMeetLesson)
    }
  }
}

</script>
