import { App } from 'vue'
import { tableWrapper } from './table'
import { access } from './access'
import { declension } from './declension'

const registerDirective = (app: App<Element>): void => {
  app
    .directive('table-wrapper', tableWrapper)
    .directive('access', access)
    .directive('declension', declension)
}

export default {
  registerDirective,
}
