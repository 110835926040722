<template>
<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_3_47182)">
<path d="M29.3758 8.13385C29.2059 7.52788 28.875 6.97928 28.4183 6.54634C27.9486 6.1001 27.373 5.78089 26.7458 5.61884C24.3983 5.00009 14.9933 5.0001 14.9933 5.0001C11.0724 4.95549 7.15256 5.15169 3.25577 5.58759C2.62851 5.76161 2.05398 6.08795 1.58327 6.5376C1.12077 6.9826 0.785771 7.53135 0.610771 8.1326C0.190388 10.3973 -0.0138635 12.6968 0.000771479 15.0001C-0.0142285 17.3013 0.189521 19.6001 0.610771 21.8676C0.782021 22.4663 1.11577 23.0126 1.57952 23.4538C2.04327 23.8951 2.62077 24.2138 3.25577 24.3826C5.63452 25.0001 14.9933 25.0001 14.9933 25.0001C18.9191 25.0448 22.844 24.8486 26.7458 24.4126C27.373 24.2506 27.9486 23.9313 28.4183 23.4851C28.8808 23.0438 29.2095 22.4951 29.3745 21.8976C29.8059 19.6338 30.0156 17.3333 30.0008 15.0288C30.0332 12.7146 29.8237 10.4033 29.3758 8.1326V8.13385ZM12.0033 19.2801V10.7213L19.8283 15.0013L12.0033 19.2801Z" fill="#FF3C8A"/>
</g>
<defs>
<clipPath id="clip0_3_47182">
<rect width="30" height="30" fill="white"/>
</clipPath>
</defs>
</svg>
</template>
