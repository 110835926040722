/* eslint-disable */
import { $vfm } from 'vue-final-modal'
import { useModalOpener } from '@/composables/useModalOpener'
import { lessonDetail, lessonStudentDetail, lessonTurn } from '@services/schedulers.service'

export default function () {
  const getLessonDetail = async (date, role) => {
    try {
      const methods = {
        student: lessonStudentDetail,
        teacher: lessonDetail,
      }
      const { data: { item } } = (await methods[role].execute({}, { dateTimeUtc: date }))
      useModalOpener({
        component: 'Schedulers/Elements/LessonInfoModal',
        info: item,
        role,
        clickToClose: true,
      })
    } catch (e) {
      console.error(e, 'lesson detail error')
    }
  }

  const toggleStatus = async date => {
    try {
      await lessonTurn.execute({}, { dateTimeUtc: date })
    } catch (e) {
      console.error(e, 'lesson toggle error')
    }
  }

  const timeConfirmation = (idTeacher, dateSlot, dateTimeTz, isFree, idMeetLesson) => {
    if(window.isAuthenticated) {
      $vfm.hideAll().then(() => {
        useModalOpener({
          component: 'Steps/StepTwo',
          info: { idTeacher, dateSlot, dateTimeTz, isFree, idMeetLesson },
        })
      })      
    } else {
      useModalOpener({ component: 'Forms/SignIn' })
    }
  }

  return {
    getLessonDetail,
    toggleStatus,
    timeConfirmation,
  }
}
