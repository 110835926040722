<template lang="pug">
include /mixins.pug

+b.d-control-input.--appearance_bordered
  +e.VUE-MULTISELECT.element(
    v-bind="{...defaultOptions, ...$attrs}"
    :options="options"
    :label="labelBy"
    :track-by="labelBy"
    v-model="model"
  )
    template(
      v-slot:[name]="binded"
      v-for="(name, key) in eSlots"
    )
      //- TODO: add slots from template
      //- slot(
      //-   v-bind="binded"
      //-   :name="key"
      //- )
      +b.multiselect__single.--clamp(v-if="'selection' === name && binded.values.length")
        +b.SPAN {{ binded.values.map(el => el.label).join(', ') }}

      +b.SPAN(v-if="'noResult' === name") {{ $t('Nothing found.') }}
      +b.SPAN(v-if="'noOptions' === name") {{ $t('No options') }}

</template>

<script setup>
/* eslint-disable */
import VueMultiselect from "vue-multiselect"
import { ref, defineProps, computed, watch } from 'vue'

const props = defineProps({
  options: {
    type: Array,
    default: () => [],
  },
  labelBy: {
    type: String,
  },
  value: [Array, Object, String],
  slots: Object,
})

const model = ref(props.value)

const eSlotsResolver = (acc, key) => {
  acc[key] = key
  return acc
}

const getESlots = keys => keys
  .reduce(eSlotsResolver, {})

const eSlots = computed(() => {
  return getESlots([...Object.keys(props.slots)])
})

const defaultOptions = computed(() => {
  return {
    openDirection: 'bottom',
    tagPlaceholder: '',
    placeholder: '',
    showLabels: false,
    closeOnSelect: true,
    searchable: false,
  }
})

watch(() => props.value, () => model.value = props.value)

</script>
