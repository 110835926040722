<template lang="pug">
include /mixins.pug

div(ref='topOfContent')
  +b.info-block(v-if="list.items.length" )
    +e.title
      +b.P.ds-caption.--size_2md.--size_4md-sm.--color_main-dark.--medium {{ $t('Мои прошлые встречи') }}
    +e.content.relative
      +b.preloader.--white.--full-block(v-if='isLoad')
      +b.g-row.--appearance_spaced.--space_1xl-xl
        +b.g-cell.g-cols--12.--6-sm.--3-lg(
          v-for="{ firstName, lastName, avatar, dateSlot, whatsappLink, existsReview, specializations, teacherId } in list.items"
        )
          +b.meeting-card.--variant_past
            +b.inline.--ai_center.ec-space_mb--2
              +e.icon.svg-image.icons.--w_md.--center.--color_grey.--cursor_auto
                i-clock(class="svg-image__element")
              +b.P.ds-caption.--size_2xs.--size_sm-sm.--color_light-gray.-regular {{ getDateLabel(dateSlot) }}
            +e.img-wrapper.--small.ec-space_mb--2
              +b.sized-image
                +e.IMG.img(
                  :src="avatar ? avatar: '/static/app/default.png'"
                )
            +e.elem.--variant_2
              +b.P.ds-caption.--size_sm.--size_2md-md.--color_main-dark.--semibold.--appearance_center {{ firstName }} {{ lastName }}

            +b.A.link-button.--style_main.--color_blue.--hover_pink.--full-size.ec-space_mt--4(
              :href="whatsappLink"
              target="_blank"
              rel="noopener noreferrer"
            )
              +b.SPAN.ds-caption.--color_main-white.--size_sm.--size_md-lg.--semibold  {{ $t('Связать с преподавателем') }}

            modal-trigger(
              component="Steps/StepOne"
              :info="{ id: teacherId }"
              v-slot="{ open }"
            )
              +b.BUTTON.link-button.--style_main.--color_pink.--hover_blue.--full-size.ec-space_mt--4(@click.prevent='open()')
                +b.P.ds-caption.--color_main-white.--size_sm.--size_md-lg.--semibold.--appearance_center {{ $t('Заказать дополнительные уроки') }}

            modal-trigger(
              component="Profiles/Modals/AddReview"
              :info="{ specializations, teacherId }"
              v-slot="{ open }"
            )
              +b.BUTTON.link-button.--style_main.--br_pink.--hover_pink.--full-size.ec-space_mt--5(
                @click.prevent='open()'
                :class="{ 'is-disabled': existsReview }"
              )
                +b.SPAN.ds-caption.--color_inherit.--size_sm.--size_md-lg.--semibold {{ $t('Оценить урок') }}

            +b.ec-space_mt--4
              +b.P.ds-caption--size_sm.--color_main-dark.--regular
                +b.A.ds-link.--tr.--styling_default.is-hover(
                  :href="staffLink"
                  target="_blank"
                  rel="noopener noreferrer"
                )
                  +b.SPAN.ds-caption.--color_inherit.--appearance_underline.--appearance_center {{ $t('Сообщить руководству о проблеме') }}

      ui-limit-pagination(
        :pagination='list.pagination'
        @input='handlePaginate'
        v-if="list.pagination.total > list.pagination.limit"
      )

</template>

<script setup>
/* eslint-disable */
import { ref, onMounted } from 'vue'
import { pastMeetingResource } from '@services/student.service'
import useMethods from '../Common/utils'

const { getDateLabel } = useMethods()

const isLoad = ref(null)
const topOfContent = ref(null)
const paginate = ref(false)
const staffLink = window.whatsApp || '#'

const list = ref({
  items: [],
  pagination: {
    total: {},
    limit: {},
    offset: {},
  }
})

onMounted(() => {
  getList({})
})

const getList = async (params) => {
  try {
    if (!isLoad.value || isLoad.value == null) {
      isLoad.value = true
    }
    const { data } = (await pastMeetingResource.execute(params))
    if (paginate.value) {
      topOfContent.value.scrollIntoView({ block: 'start', behavior: 'smooth' })
    }
    list.value = data
    isLoad.value = false
    paginate.value = false
  } catch (e) {
    console.error(e, 'Error')
    isLoad.value = false
    paginate.value = false
  }
}

const handlePaginate = page => {
  paginate.value = true
  getList(page)
}

</script>
