<template lang="pug">
include /mixins.pug

+b.info-block
  +e.title
    +b.P.ds-caption.--size_2md.--size_4md-sm.--color_main-dark.--medium {{ $t('Дополнительные специализации') }}
  +e.content
    +b.g-row.--appearance_spaced.--space_1xl-xl
      +b.g-cell.g-cols--12.--auto-md
        +b.info-block__tag(v-for="{ label } in specializations.teacher") {{ label }}

      +b.g-cell.g-cols--12.--narrow-md
        modal-trigger(
          component="Profiles/Modals/AddSpecializations"
          :info="specializations"
          v-slot="{ open }"
        )
          +b.BUTTON.link-button.--style_main.--color_blue.--hover_pink(@click.prevent='open()' :disabled="isCanNotUpdate")
            +b.P.ds-caption.--color_main-white.--size_sm.--size_md-lg.--semibold {{ $t('Добавить специализацию') }}

</template>

<script setup>
/* eslint-disable */
import { defineProps, ref, onMounted } from 'vue'
import { specializationsResource } from '@services/teacher.service'

const props = defineProps({
  isCanNotUpdate: Boolean,
})

const specializations = ref([])

onMounted(() => {
  getSpecInfo()
})

const getSpecInfo = async() => {
  try {
    const { data: { item } } = (await specializationsResource.execute({}))
    specializations.value = item
  } catch (e) {
    console.error(e, 'Error')
  }
}

</script>
