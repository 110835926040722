<template lang="pug">
include /mixins.pug

+b.filters-wrap.relative
  +b.preloader--full-block.--white(v-if='isLoad')
  ui-accordion(
    v-slot="{ toggleActive, activeState, collapse }"
  )
    ui-click-outside(
      :do='collapse'
      v-slot="{ ref }"
    )
      +e.fake-search(
        :ref='ref'
      )
        +b.inline--between.full-w(@click.prevent.stop='toggleActive()')
          +b.P.ds-caption.--size_md.--color_inherit.--regular {{ $t('Поиск') }}
          +b.svg-image.icons.--w_md.--center
            i-search(class="svg-image__element")
        transition(name='fade')
          catalog-search(
            class="search--variant_1"
            v-model="formdata.search"
            v-show="activeState"
            :reset-trigger="resetTrigger"
            @update:search="setSearch($event), collapse()"
          )

  +b.filters-bar.--vertical(:class="$attrs.class")
    +e.title
      +b.inline
        +b.P {{ $t('Фильтры') }}
        +b.svg-image.icons.--w_lg.--center(style="cursor: default")
          i-filter(class="svg-image__element")
        +b.filters-bar__reset-btn.--variant_2(@click.prevent="emit('trigger:close', false)")
          +b.SPAN.ds-caption.--color_inherit.--size_sm.--regular {{ $t('Назад') }}

    +e.elem.is-disable-xl
      div(id="teleport-wrap")

    template(v-for="(item, name) in filterAttrs")
      ui-accordion(
        :init='item.isExpand || true'
        v-slot="{ toggleActive, activeState, collapse, icon }"
      )
        +e.elem
          +b.g-row.--align_center.--justify_between(@click.prevent='toggleActive()')
            +b.g-cell.g-cols.--narrow
              +b.P.ds-caption.--size_md.--color_main-dark.--regular {{ name === 'availableTime' ? $t('Доступное время') : item.title }}
            +b.g-cell.g-cols.--narrow
              component(
                :is="activeState ? 'i-acc-minus': 'i-acc-plus'"
              )

          +e.items(v-if="activeState")
            template(v-if="name === 'availableTime'")
              +b.g-row.ec-space_mt--3
                template(v-for="(subitem, key) in item")
                  +b.g-cell.g-cols.--6-xs(v-if="subitem.choices")
                    +b.P.ds-caption.--size_2md.--color_main-dark.--medium {{ subitem.title }}
                    MultiSwitcher(
                      v-model:value="formdata[key]"
                      :options="subitem.choices"
                      :single-mod="true"
                      @update:value="emit('update:filters', formdata)"
                    )

            template(v-else)
              MultiSwitcher(
                class="ui-multiswitch-wrapper--variant_1"
                v-model:value="formdata[name]"
                :options="item.choices"
                @update:value="emit('update:filters', formdata)"
              )

    +e.btn-wrap
      +b.g-row.--align_center
        +b.g-cell.g-cols--narrow
          +b.filters-bar__reset-btn.--variant_1(@click.prevent='resetFilters')
            +b.SPAN.ds-caption.--color_inherit.--size_sm.--regular {{ $t('reset') }}
        +b.g-cell.g-cols--auto
          +b.BUTTON.link-button.--style_main.--color_pink.--hover_blue.--full-size(@click.prevent="emit('trigger:close', false)") 
            +b.SPAN.ds-caption.--color_main-white.--size_sm.--size_md-lg.--semibold {{ $t('OK') }}

</template>

<script setup>
/* eslint-disable */
import MultiSwitcher from '@components/UiElements/Controls/MultiSwitcher.vue'
import { defineProps, reactive, defineEmits, watchEffect, watch } from 'vue'

const emit = defineEmits(['update:filters', 'trigger:close', 'reset:filters'])

const props = defineProps({
  filterUrlAttr: {},
  resetTrigger: Boolean,
  filterAttrs: {
    type: Object,
    default: {},
  },
  isLoad: Boolean,
})

let formdata = reactive({
  week: [],
  workTime: [],
  languages: [],
  teacherCountry: [],
  nativeLanguage: [],
  search: '',
})

const setSearch = quiery => {
  formdata.search = quiery
  emit('update:filters', formdata)
}

const resetFilters = () => {
  Object.keys(formdata).forEach(key => {
    formdata[key] = []
  })
  formdata.search = ''
  emit('reset:filters')
}

watch(() => props.resetTrigger, () => {
  Object.keys(formdata).forEach(key => {
    formdata[key] = []
  })
  formdata.search = ''
})

watchEffect(() => {
  if (props.filterUrlAttr.value) {
    Object.keys(props.filterUrlAttr.value).map(k => {
      if (Object.keys(formdata).includes(k)) {
        return formdata[k] = props.filterUrlAttr.value[k]
      }
    })
    if (!props.filterUrlAttr.value.nativeLanguage) {
      formdata.nativeLanguage = []
    }
  }
})

</script>
