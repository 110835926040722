<template lang="pug">
include /mixins.pug

+b.g-row.--appearance_spaced.--space_1xl-xl.ec-space_mt--8
  +b.g-cell.g-cols--12.--6-sm.--4-xl(
    v-for="{ costLesson, costPackage, costLessonFull, costPackageFull, currency, discountPercent, id, quantityMeet, title } in packages"
  )
    +b.package-card
      +e.icon
        +e.discount(v-if="discountPercent")
          +e.P {{ discountPercent }} %
        i-giftbox
      +e.P.title {{ title }}
      +e.P.desc.ec-space_mb--1.is-disable-xl
        +b.SPAN {{ $t('Стоимость урока') }}
      +e.P.desc.ec-space_mb--4.--3-xl
        +b.SPAN.is-inline-flex-xl {{ $t('Стоимость урока') }}
        +e.SPAN.desc.--blue(v-if="discountPercent") {{ costLessonFull }} {{ currency }} &#32;
        +e.SPAN.desc.--pink(:class="{'package-card__desc--pink_variant_1': !discountPercent}") {{ costLesson }} {{ currency }} 
        +e.SPAN.desc.--time / {{ $t('60 мин') }}
      +e.P.desc.ec-space_mb--1.is-disable-xl
        +b.SPAN {{ $t('Стоимость пакета') }}
      +e.P.desc.is-disable-sm(v-if="discountPercent")
        +e.SPAN.desc.--blue {{ costPackageFull }} {{ currency }} &#32;
      +e.P.desc.ec-space_mb--6
        +b.SPAN.is-inline-flex-xl  {{ $t('Стоимость пакета') }}
        +e.SPAN.desc.--blue.is-inline-flex-sm(v-if="discountPercent") {{ costPackageFull }} {{ currency }} &#32;
        +e.SPAN.desc.--pink(:class="{'package-card__desc--pink_variant_1': !discountPercent}") {{ costPackage }} {{ currency }}
      +e.btn-wrapper
        +b.BUTTON.link-button.--style_main.--color_pink.--hover_blue.--full-size(
          @click.prevent="buyPackage(id)"
        )
          +b.P.ds-caption.--color_main-white.--size_sm.--size_md-lg.--semibold  {{ $t('Выбрать пакет') }}

</template>

<script setup>
/* eslint-disable */
import { ref, reactive } from 'vue'
import { packagesListUrl } from '@services/packages.service'
import { friendlyUrlParser } from '@utils/filters'
import { useModalOpener } from '@/composables/useModalOpener'

const packages = ref([])

let params = reactive({
  filters: {},
})

const getUrlParams = () => {
  params.filters = friendlyUrlParser()
}

const getList = async () => {
  try {
    getUrlParams()
    const { data: { item: { days } } } = (await packagesListUrl.execute(params))
    packages.value = days
  } catch (e) {
    console.error(e, 'Error')
  }
}

getList()

const buyPackage = async id => {
  let info = {
    idTeacher: Number(params.filters.teacher_id[0]),
    idPackage: id,
    type: 'packages',
  }
  if (params.filters?.slot) {
    info = {
      ...info,
      dateSlot: params.filters?.slot[0],
      type: 'packages',
    }
  }
  useModalOpener({
    component: 'Steps/FormPayment',
    info,
  })
}

</script>
