<template lang="pug">
include /mixins.pug

+b.scheduler__nav-wrapper
  +e.nav-btn(@click.prevent="emit('scheduler:nav', 'prev')" :class="{ 'is-disabled': isDisabled }")
    i-slider-left
  +b.P.ds-caption.--size_md.--color_main-gray.--medium {{ weekLabel }}
  +e.nav-btn(@click.prevent="emit('scheduler:nav', 'next')")
    i-slider-right

</template>

<script setup>
import { defineProps, defineEmits } from 'vue'

const emit = defineEmits(['scheduler:nav'])

const props = defineProps({
  isDisabled: Boolean,
  weekLabel: String,
})

</script>
