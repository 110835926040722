<template lang="pug">
include /mixins.pug

div
  +b.P.ds-caption.--size_2md.--size_4md-xl.--line-h_1-lg.--color_main-dark.--bold.ec-space_mt--0.--2-lg {{ $t('Мои реквизиты') }}

  info-label

  VeeForm(v-slot="{ errors }", @submit="request")
    +b.g-row.ec-space_mt--3
      VeeError(name="nonFieldErrors" v-slot="{ message }"): +b.simple-errors {{ message }}
      +b.g-cell.g-cols--12.--6-sm.ec-space_mt--2
        DController(
          :class="'d-control-descriptor--bg_white'"
          :name="'fullName'"
          :errors='errors'
          :static-label=`$t("ФИО")`
          v-model="formdata.fullName"
          rules="required|onlyEnglish"
        )
      +b.g-cell.g-cols--12.--6-sm.ec-space_mt--2
        DController(
          :class="'d-control-descriptor--bg_white'"
          :name="'iban'"
          :errors='errors'
          :static-label=`$t("IBAN")`
          v-model="formdata.iban"
        )
      +b.g-cell.g-cols--12.--6-sm.ec-space_mt--2
        DController(
          :class="'d-control-descriptor--h_xl'"
          :component="'Multiselect'"
          :name="'country'"
          :options="countryOptions"
          :searchable="true"
          :static-label=`$t("Страна")`
          track-by="slug"
          :labelBy="'label'"
          v-model="formdata.country"
          rules="required"
          @update:model-value="updateAfterSearch($event, 'country')"
        )
          template(#noResult)
      +b.g-cell.g-cols--12.--6-sm.ec-space_mt--2
        DController(
          :class="'d-control-descriptor--bg_white'"
          :name="'swift'"
          :errors='errors'
          :static-label=`$t("SWIFT")`
          v-model="formdata.swift"
        )
      +b.g-cell.g-cols--12.--6-sm.ec-space_mt--2
        DController(
          :class="'d-control-descriptor--bg_white'"
          :name="'payPal'"
          :errors='errors'
          :static-label=`$t("PAYPAL")`
          v-model="formdata.payPal"
        )
      +b.g-cell.g-cols--12.--6-sm.ec-space_mt--10.--9-xl
        +b.BUTTON.link-button.--style_main.--br_blue.--hover_blue.--full-size(
          :disabled='isLoad || isCanNotUpdate'
          type='submit'
        )
          +b.P.ds-caption.--color_inherit.--size_sm.--size_md-lg.--semibold  {{ $t('Сохранить') }}

</template>

<script setup>
/* eslint-disable */
import { ref, defineProps, reactive, nextTick } from 'vue'
import { countriesChoices } from '@services/countries.service'
import { requisiteUpdate } from '@services/teacher.service'
import { useModalOpener } from '@/composables/useModalOpener'
import { useSubmit } from '@/components/Forms/FormMixin'
import useMethods from '../../Common/utils'
import useGlobal from '@/composables/useGlobal'

const { $t } = useGlobal()
const { prepareData } = useMethods()

const props = defineProps({
  initial: Object,
  isCanNotUpdate: Boolean,
})

let formdata = reactive({
  fullName: '',
  country: [],
  payPal: '',
  iban: '',
  swift: '',
})

Object.assign(formdata, { ...props.initial })

const isLoad = ref(false)
const countryOptions = ref([])

const request = (sendData, control) => {
  isLoad.value = true
  useSubmit(send(sendData), control)
}

const send = async data => {
  const sendData = prepareData(data)
  try { 
    await requisiteUpdate.execute({}, sendData)
    useModalOpener({
      component: 'UiElements/Modals/UiMessageModal',
      message: {
        title: $t('Ваши реквизиты успешно обновлены!'),
        icon: 'i-checked',
      },
    })
    isLoad.value = false
  } catch (e) {
    isLoad.value = false
    return Promise.reject(e)
  }
}

const updateAfterSearch = async (val, key) => {
  if (val?.slug) {
    await nextTick()
    formdata[key] = val
  } else {
    formdata[key] = []
  }
}

const getCountries = async() => {
  try {
    const { data: { item: { countries } } } = (await countriesChoices.execute({}))
    countryOptions.value = countries
  } catch (e) {
    console.error(e, 'error')
  }
}
getCountries()

</script>