<template lang="pug">
include /mixins.pug

div(:id="targetRef")
  DController(
    v-bind="$attrs"
    :component="'Multiselect'"
    :name="'tz'"
    :options="tzOptions"
    :searchable="true"
    :allow-empty="false"
    v-model="timeZone"
    track-by="slug"
    :labelBy="'label'"
    @update:model-value="updateTimeZone"
  )
    template(#noResult)

</template>

<script setup>
/* eslint-disable */
import { ref, defineEmits, nextTick } from 'vue'
import { getCookie, setCookie } from '@utils/cookies'
import useScheduleMethodth from '../utils'

const { getTimeZones } = useScheduleMethodth()

const emit = defineEmits(['scheduler:setTZ'])

const props = defineProps({
  targetRef: String,
  noObserve: {
    type: Boolean,
    default: false,
  }
})

const tzOptions = ref([])
const timeZone = ref([])
const isLoaded = ref(false)

const observe = () => {
  const target = document.querySelector(`#${props.targetRef}`)
  const observer = new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
      if (entry.isIntersecting && !isLoaded.value) {
        isLoaded.value = true
        setTZ()
      }
    })
  }, { root: null, threshold: 1.0 })

  observer.observe(target)
}

const updateTimeZone = async(val) => {
  if (val?.slug) {
    await nextTick()
    timeZone.value = val
    emit('scheduler:setTZ', { timezone: val.slug })
    setCookie('EXPERTS_TIMEZONE_COOKIE_NAME', val.slug)
  }
}

const setTZ = async() => {
  tzOptions.value = await getTimeZones()
  const cookieZone = getCookie('EXPERTS_TIMEZONE_COOKIE_NAME')
  tzOptions.value.forEach(val => {
    if (`"${val.slug}"` === cookieZone) {
      timeZone.value = val
      emit('scheduler:setTZ', { timezone: val.slug })
    }
  })
}

if (props.noObserve) {
  setTZ()
} else {
  setTimeout(observe, 1500)
}

</script>
