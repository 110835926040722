<template lang="pug">
include /mixins.pug

+b.info-block
  +e.title
    +b.P.ds-caption.--size_2md.--size_4md-sm.--color_main-dark.--medium {{ $t('Владение языками') }}
  +e.content
    +b.g-row.--appearance_spaced.--space_1xl-xl
      +b.g-cell.g-cols--12.--4-lg
        +b.P.ds-caption.--size_md.--color_main-dark.--regular.ec-space_mb--2 {{ $t('Родной язык:') }}
        +b.info-block__tag(v-for="{ label } in langInfo.nativeLanguage.teacher") {{ label }}

      +b.g-cell.g-cols--12.--auto-lg
        +b.P.ds-caption.--size_md.--color_main-dark.--regular.ec-space_mb--2 {{ $t('Дополнительные языки:') }}
        +b.info-block__tag(v-for="{ label } in langInfo.languages.teacher") {{ label }}

      +b.g-cell.g-cols--12.--narrow-lg
        modal-trigger(
          component="Profiles/Modals/AddLanguages"
          :info="langInfo"
          v-slot="{ open }"
        )
          +b.BUTTON.link-button.--style_main.--color_blue.--hover_pink.ec-space_mt--8(@click.prevent='open()' :disabled="isCanNotUpdate")
            +b.P.ds-caption.--color_main-white.--size_sm.--size_md-lg.--semibold {{ $t('Редактировать информацию') }}

</template>

<script setup>
/* eslint-disable */
import { defineProps, ref, onMounted } from 'vue'
import { languagesResource } from '@services/teacher.service'

const props = defineProps({
  isCanNotUpdate: Boolean,
})

const langInfo = ref({
  nativeLanguage: [],
  languages: [],
})

onMounted(() => {
  getLanguagesInfo()
})

const getLanguagesInfo = async() => {
  try {
    const { data: { item } } = (await languagesResource.execute({}))
    langInfo.value = item
  } catch (e) {
    console.error(e, 'Error')
  }
}

</script>
