import { App } from 'vue'
import IFind from './Find.vue'
// import IAbout from './About.vue'
import IReviews from './Reviews.vue'
import IContact from './Contact.vue'
import ILogin from './Login.vue'
// import IDotsLeft from './DotsLeft.vue'
// import IDotsTop from './DotsTop.vue'
// import ILinesMain from './Lines-main.vue'
// import ILinesMainMin from './Lines-main-min.vue'
import IRhombus from './Rhombus.vue'
import ICircle from './Circle.vue'
import IArrowRight from './ArrowRight.vue'
import ISliderLeft from './SliderLeft.vue'
import ISliderRight from './SliderRight.vue'
// import IScreenMobile from './ScreenMobile.vue'
import ILamp from './Lamp.vue'
import IAccOpen from './AccOpen.vue'
import IAccClose from './AccClose.vue'
import IWhatsApp from './WhatsApp.vue'
import IWhatsAppIcon from './WhatsAppIcon.vue'
import IEmailIcon from './EmailIcon.vue'
import IArrowDown from './ArrowDown.vue'
import IArrowUp from './ArrowUp.vue'
import IUser from './User.vue'
import IGlobus from './Globus.vue'
import IOxfordLabel from './OxfordLabel.vue'
import IPlay from './Play.vue'
import IClose from './Close.vue'
import IAccMinus from './AccMinus.vue'
import IAccPlus from './AccPlus.vue'
import ISearch from './Search.vue'
import IEye from './Eye.vue'
import IEyeOff from './EyeOff.vue'
import IContactMail from './ContactMail.vue'
import IContactPhone from './ContactPhone.vue'
import IContactPlace from './ContactPlace.vue'
import IContactWhatsapp from './ContactWhatsapp.vue'
import IIconF from './IconF.vue'
import IIconI from './IconI.vue'
import IIconT from './IconT.vue'
import IIconYT from './IconYT.vue'
import IShareFacebook from './ShareFacebook.vue'
import IShareTwitter from './ShareTwitter.vue'
import IShareVk from './ShareVk.vue'
import IArrLeft from './ArrLeft.vue'
import IShareLink from './ShareLink.vue'
import IStar from './Star.vue'
import ILightning from './Lightning.vue'
import IClock from './Clock.vue'
import ICheckboxCircle from './CheckboxCircle.vue'
import IChevronRight from './ChevronRight.vue'
import ICloudUpload from './CloudUpload.vue'
import ITriangle from './Triangle.vue'
import IMoneyBag from './MoneyBag.vue'
import IInfo from './Info.vue'
import IInfoSecondary from './InfoSecondary.vue'
import IAddRing from './AddRing.vue'
import IGiftbox from './Giftbox.vue'
import IEmoticon from './Emoticon.vue'
import ITrash from './Trash.vue'
import ICancelRing from './CancelRing.vue'
import IThumbUp from './ThumbUp.vue'
import IChecked from './Checked.vue'
import IFilter from './Filter.vue'

const register = (app: App<Element>): void => {
  app
  // .component('IAbout', IAbout)
  .component('IFind', IFind)
  .component('IReviews', IReviews)
  .component('IContact', IContact)
  .component('ILogin', ILogin)
  // .component('IDotsLeft', IDotsLeft)
  // .component('IDotsTop', IDotsTop)
  // .component('ILinesMain', ILinesMain)
  // .component('ILinesMainMin', ILinesMainMin)
  .component('IRhombus', IRhombus)
  .component('ICircle', ICircle)
  .component('IArrowRight', IArrowRight)
  .component('ISliderRight', ISliderRight)
  .component('ISliderLeft', ISliderLeft)
  // .component('IScreenMobile', IScreenMobile)
  .component('ILamp', ILamp)
  .component('IAccOpen', IAccOpen)
  .component('IAccClose', IAccClose)
  .component('IWhatsApp', IWhatsApp)
  .component('IWhatsAppIcon', IWhatsAppIcon)
  .component('IEmailIcon', IEmailIcon)
  .component('IArrowDown', IArrowDown)
  .component('IArrowUp', IArrowUp)
  .component('IUser', IUser)
  .component('IGlobus', IGlobus)
  .component('IOxfordLabel', IOxfordLabel)
  .component('IPlay', IPlay)
  .component('IClose', IClose)
  .component('IAccPlus', IAccPlus)
  .component('IAccMinus', IAccMinus)
  .component('ISearch', ISearch)
  .component('IEye', IEye)
  .component('IEyeOff', IEyeOff)
  .component('IContactMail', IContactMail)
  .component('IContactPhone', IContactPhone)
  .component('IContactPlace', IContactPlace)
  .component('IContactWhatsapp', IContactWhatsapp)
  .component('IIconF', IIconF)
  .component('IIconI', IIconI)
  .component('IIconT', IIconT)
  .component('IIconYT', IIconYT)
  .component('IShareFacebook', IShareFacebook)
  .component('IShareTwitter', IShareTwitter)
  .component('IShareVk', IShareVk)
  .component('IArrLeft', IArrLeft)
  .component('IShareLink', IShareLink)
  .component('IStar', IStar)
  .component('ILightning', ILightning)
  .component('IClock', IClock)
  .component('ICheckboxCircle', ICheckboxCircle)
  .component('IChevronRight', IChevronRight)
  .component('ICloudUpload', ICloudUpload)
  .component('ITriangle', ITriangle)
  .component('IMoneyBag', IMoneyBag)
  .component('IInfo', IInfo)
  .component('IInfoSecondary', IInfoSecondary)
  .component('IAddRing', IAddRing)
  .component('IGiftbox', IGiftbox)
  .component('IEmoticon', IEmoticon)
  .component('ITrash', ITrash)
  .component('ICancelRing', ICancelRing)
  .component('IThumbUp', IThumbUp)
  .component('IChecked', IChecked)
  .component('IFilter', IFilter)
}

export default {
  register,
}
