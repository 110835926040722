<template lang="pug">
include /mixins.pug

+b.scheduler__btn(@click.prevent="isOpen ? emit('scheduler:hours-down', { hours_down: 3 }): emit('scheduler:hours-down', { hours_down: null }), isOpen = !isOpen")
  +b.svg-image.icons.--center.--cursor_auto.ec-space_ml--2.ec-space_mr--2(:class="{ 'icons--rotate': isOpen }")
    i-triangle(class="svg-image__element")
  +b.P.ds-caption.--size_2xs.--size_md-md.--line-h_1.--color_inherit.--regular {{ isOpen ? $t('Скрыть'): $t('Смотреть полностью') }}

</template>

<script setup>
import { ref, defineEmits } from 'vue'

const emit = defineEmits(['scheduler:hours-down'])

const isOpen = ref(false)

</script>
