<template lang="pug">
componentGetter(
  
)
</template>
<script setup>

import {
  computed,
  defineAsyncComponent,
} from 'vue'

const componentGetter = computed(() => defineAsyncComponent(() => import('@/components/Forms/Feedback')))
</script>
