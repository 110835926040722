<template>
<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_3_32896)">
<path d="M36.3413 17.68C36.4693 17.04 36.5333 16.4106 36.5333 15.792C36.5333 9.83998 31.5413 4.99731 25.3973 4.99731C22.1333 4.99731 19.0933 6.37331 16.992 8.74131C10.5173 7.29065 4.53333 11.7066 3.62667 17.6906C1.344 19.5466 0 22.288 0 25.2C0 30.5973 4.49067 34.992 9.99467 34.992V30.9813C6.624 30.9813 3.98933 28.432 3.98933 25.1893C3.98933 23.3013 4.97067 21.52 6.61333 20.432L7.44533 19.8773L7.49867 18.8746C7.75467 14.5013 12.1813 11.184 17.056 12.9013L18.5173 13.424L19.3813 12.1333C20.704 10.16 22.944 8.98665 25.376 8.98665C29.312 8.98665 32.512 12.0373 32.512 15.7813C32.512 16.4213 32.384 17.104 32.128 17.8613L31.5947 19.4186L33.024 20.24C34.88 21.3066 35.9893 23.152 35.9893 25.1786C35.9893 28.3786 33.248 30.9813 29.888 30.9813H21.984V24.976H25.984L20 18.992L13.9947 24.9973H17.9947V35.0026H29.8987C35.4667 35.0026 40 30.608 40 25.2C40 22.2346 38.656 19.5253 36.3413 17.68Z" fill="#BFBFBF"/>
</g>
<defs>
<clipPath id="clip0_3_32896">
<rect width="40" height="40" fill="white"/>
</clipPath>
</defs>
</svg>
</template>
