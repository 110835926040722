import { App } from 'vue'
import SidebarTrigger from './SidebarTrigger.vue'
import ShowMoreTrigger from './ShowMoreTrigger.vue'
import UiAccordion from './UiAccordion.vue'
import UiClickOutside from './UiClickOutside.vue'
import UiModalsContainer from './Modals/Container.vue'
// import UiMessageModal from './Modals/UiMessageModal.vue'
// import UiConfirmModal from './Modals/UiConfirmModal.vue'
import ModalTrigger from './ModalTrigger.vue'
import UiSlider from './UiSlider.vue'
import UiGallery from './UiGallery.vue'
import UiTabs from './UiTabs.vue'
import UiYoutubeView from './YoutubeView.vue'
import LogoutTrigger from './LogoutTrigger.vue'

import Controller from './Controls/ControlDescriptor.vue'
import InputLabel from './Controls/Label.vue'
import InputTextArea from './Controls/TextArea.vue'
import SimpleInput from './Controls/SimpleInput.vue'
import Multiselect from './Controls/Multiselect.vue'
import Switcher from './Controls/Switcher.vue'
import Multiswitcher from './Controls/MultiSwitcher.vue'
import RangeWidget from './Controls/RangeWidget.vue'
import TelWidget from './Controls/TelInput.vue'
import UiCropper from './Controls/Cropper.vue'
import UiUploader from './Controls/Uploader.vue'

import UIPagination from './Pagination/UiLimitPagination.vue'
import Renderer from './Renderer.vue'
import UiMap from './UiMap.vue'
import UiViewsController from './UiViewsController.vue'

// import VideoModal from './Modals/Video.vue'
import AgileSlider from './Agile/Agile.vue'

const register = (app: App<Element>): void => {
  app
    .component('SidebarTrigger', SidebarTrigger)
    .component('ShowMoreTrigger', ShowMoreTrigger)
    .component('UiAccordion', UiAccordion)
    .component('UiClickOutside', UiClickOutside)
    .component('UiModalsContainer', UiModalsContainer)
    // .component('UiMessageModal', UiMessageModal)
    // .component('UiConfirmModal', UiConfirmModal)
    .component('ModalTrigger', ModalTrigger)
    .component('UiSlider', UiSlider)
    .component('UiGallery', UiGallery)
    .component('UiTabs', UiTabs)
    .component('UiYoutubeView', UiYoutubeView)
    .component('LogoutTrigger', LogoutTrigger)

    .component('DController', Controller)
    .component('InputLabel', InputLabel)
    .component('InputTextArea', InputTextArea)
    .component('SimpleInput', SimpleInput)
    .component('Multiselect', Multiselect)
    .component('Multiswitcher', Multiswitcher)
    .component('Switcher', Switcher)
    .component('RangeWidget', RangeWidget)
    .component('TelWidget', TelWidget)
    .component('UiCropper', UiCropper)
    .component('UiUploader', UiUploader)

    .component('UiLimitPagination', UIPagination)
    .component('Renderer', Renderer)
    .component('UiMap', UiMap)
    .component('UiViewsController', UiViewsController)

    // .component('Video', VideoModal)
    .component('AgileSlider', AgileSlider)
}

export default {
  register,
}
