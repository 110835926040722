<template lang="pug">
render
</template>

<script setup>
import { defineProps, ref, h, compile, watch } from 'vue'

const props = defineProps({
  result: {
    type: String,
  },
})

const render = ref('')

watch(() => props.result, nval => {
  if (nval) return render.value = h(compile(nval))
}, { immediate: true })

</script>
