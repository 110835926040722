<template>
<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_228_4554)">
<path d="M8.825 8.82502C8.65334 9.00923 8.44634 9.15699 8.21635 9.25947C7.98635 9.36195 7.73806 9.41705 7.48631 9.42149C7.23455 9.42594 6.98448 9.37962 6.75101 9.28532C6.51754 9.19102 6.30545 9.05066 6.1274 8.87261C5.94936 8.69456 5.809 8.48248 5.71469 8.24901C5.62039 8.01554 5.57408 7.76547 5.57852 7.51371C5.58296 7.26195 5.63807 7.01367 5.74055 6.78367C5.84303 6.55367 5.99078 6.34667 6.175 6.17502M11.2125 11.2125C10.1441 12.0269 8.84319 12.4781 7.5 12.5C3.125 12.5 0.625 7.50002 0.625 7.50002C1.40243 6.0512 2.48071 4.7854 3.7875 3.78752L11.2125 11.2125ZM6.1875 2.65002C6.61771 2.54932 7.05817 2.49898 7.5 2.50002C11.875 2.50002 14.375 7.50002 14.375 7.50002C13.9956 8.20977 13.5432 8.87798 13.025 9.49377L6.1875 2.65002Z" stroke="#BCBCBC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M0.625 0.625L14.375 14.375" stroke="#BCBCBC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_228_4554">
<rect width="15" height="15" fill="white"/>
</clipPath>
</defs>
</svg>

</template>
